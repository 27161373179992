import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { createStructuredSelector } from 'reselect';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
  ButtonBase,
  Divider,
  makeStyles,
  Typography,
  Avatar,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import LanguageBanner from 'components/LanguageBanner';
import {
  makeSelectIsFreshworksLoaded,
  makeSelectShowLanguageBanner,
} from 'containers/Main/selectors';
import useSiteCopySelector from 'components/useSiteCopySelector';
import freshworksUtils from 'utils/freshworksUtils';
import { getUserFirstName } from 'utils/stringUtils';
import { FRONT_FACING_MENU } from './MobileBottomNavBar';
import NavBarContext from '../navBarContext';
import { MyLearningBadge } from './MyLearningBadge';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 60,
    width: 60,
  },
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    padding: '28px 24px 20px',
    backgroundColor: theme.palette.blue100,
  },
  login: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: '1',
  },
  joinBtn: {
    width: 197,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: 2,
    ...theme.typography.button,
  },
  accountText: {
    ...theme.typography.overline,
    marginTop: 10,
    marginBottom: 5,
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
  linksItem: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '14px',
    fontFamily: 'MadaRegular',
    ...theme.typography.overline,
  },
  logout: {
    position: 'absolute',
    top: 20,
    right: '5%',
    color: theme.palette.text.disabled,
    ...theme.typography.overline,
  },
  links: {
    marginTop: '10px',
    display: 'flex',
    gap: '24px',
  },
  profileCard: {
    display: 'flex',
    padding: '21px 0 24px 27px',
    backgroundColor: theme.palette.blue100,
    gap: '27px',
    color: theme.palette.text.primary,
    position: 'sticky',
    top: 0,
  },
  profileName: {
    fontSize: '17px',
    fontFamily: 'PoppinsSemiBold',
  },
  profileEmail: {
    fontSize: '17px',
    fontFamily: 'MadaRegular',
  },
  menuContainer: {
    padding: '21px 24px 27px',
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 10,
    padding: '8px 0',
  },
  menuName: {
    fontSize: '17px',
    color: theme.palette.text.primary,
    fontFamily: 'MadaRegular',
  },
  divider: {
    margin: '16px 0',
  },
  otherMenuContainer: {
    width: '100%',
    flex: '1',
  },
}));

const stateSelector = createStructuredSelector({
  showLanguageBanner: makeSelectShowLanguageBanner(),
});

const UnAuthenticated = ({ goTo, classes, onClick, labels }) => {
  const { join, loginCta, login } = labels;
  return (
    <div className={classes.root}>
      <div className={classes.login}>
        <ButtonBase
          className={classes.joinBtn}
          onClick={() => onClick(`/signup${goTo ? `?redirect=${goTo}` : ''}`)}
        >
          {join}
        </ButtonBase>
        <span className={classes.accountText}>
          {loginCta}{' '}
          <Link
            to={`/login${goTo ? `?redirect=${goTo}` : ''}`}
            onClick={() => onClick('/login')}
          >
            {login}
          </Link>
        </span>
      </div>
    </div>
  );
};

function MobileAccountInfo() {
  const classes = useStyles();
  const goTo = useRef(null);
  const [footerSiteCopy] = useSiteCopySelector(['footer']);
  const isFreshworksLoaded = useSelector(makeSelectIsFreshworksLoaded());

  const { redirect } = qs.parse(window.location.search.slice(1));
  const currentPathname = window.location.pathname;

  if (redirect && redirect !== '/login' && redirect !== '/signup')
    goTo.current = redirect;
  else if (currentPathname !== '/login' && currentPathname !== '/signup')
    goTo.current = currentPathname;

  const { showLanguageBanner } = useSelector(stateSelector);
  const {
    menus,
    getLink,
    logout,
    profile,
    isAuthenticated,
    siteCopy,
    redirect: doRedirect,
    allowSignIn,
  } = useContext(NavBarContext);

  const { avatarUrl, email } = profile;
  const name = getUserFirstName(profile);
  const finaleMenu = menus.filter(
    ({ slug }) => !FRONT_FACING_MENU.includes(slug) && slug !== 'in-crisis',
  );

  const handleSupportClick = () => {
    freshworksUtils.show();
  };

  return (
    <div>
      {allowSignIn && (
        <>
          {isAuthenticated ? (
            <div className={classes.profileCard}>
              <div>
                {avatarUrl ? (
                  <Avatar
                    alt={name}
                    src={avatarUrl}
                    className={classes.avatar}
                  />
                ) : (
                  <AccountCircle className={classes.avatar} />
                )}
              </div>
              <div>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className={classes.profileName}
                >
                  {name}
                </Typography>
                <Typography
                  variant="overline"
                  color="textPrimary"
                  className={classes.profileEmail}
                >
                  {email}
                </Typography>
                <div className={classes.links}>
                  <Link
                    to="/account"
                    onClick={() => doRedirect('/account')}
                    className={classes.linksItem}
                  >
                    {_get(siteCopy, ['pageCopy', 'dialog-menu', 'account'])}
                  </Link>
                  <Link
                    to="/favorites"
                    onClick={() => doRedirect('/favorites')}
                    className={classes.linksItem}
                  >
                    {_get(siteCopy, ['pageCopy', 'dialog-menu', 'favorites'])}
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <UnAuthenticated
              goTo={goTo.current}
              onClick={doRedirect}
              classes={classes}
              showLanguageBanner={showLanguageBanner}
              labels={{
                join: _get(siteCopy, ['pageCopy', 'dialog-menu', 'join']),
                loginCta: _get(siteCopy, [
                  'pageCopy',
                  'dialog-menu',
                  'login-cta',
                ]),
                login: _get(siteCopy, ['pageCopy', 'dialog-menu', 'login']),
              }}
            />
          )}
        </>
      )}
      <div className={classes.menuContainer}>
        <div className={classes.menuList}>
          {finaleMenu.map(menu => (
            <Link key={menu.slug} to={menu.link || getLink(menu.slug)}>
              <div className={classes.menu}>
                <span className={classes.menuName}>{menu.name}</span>
                {menu.slug === 'learning' && (
                  <MyLearningBadge siteCopy={siteCopy} />
                )}
              </div>
            </Link>
          ))}
          {allowSignIn && !profile.isEmpty && (
            <div
              className={classes.menu}
              role="presentation"
              onClick={() => logout()}
            >
              <span className={classes.menuName}>
                {_get(siteCopy, ['pageCopy', 'dialog-menu', 'logout'])}
              </span>
            </div>
          )}
        </div>
        {showLanguageBanner && (
          <>
            <Divider className={classes.divider} />
            <div className={classes.otherMenuContainer}>
              <LanguageBanner
                hasMultipleLanguage={showLanguageBanner}
                hideAbout
                hideInCrisis
                orientation="column"
              />
            </div>
          </>
        )}
        {isFreshworksLoaded && (
          <>
            <Divider className={classes.divider} />
            <div className={classes.otherMenuContainer}>
              <ButtonBase
                className={classes.menu}
                onClick={handleSupportClick}
                disableRipple
                disableTouchRipple
              >
                <span className={classes.menuName}>
                  {_get(footerSiteCopy, 'pageCopy.supportLabel', 'Support')}
                </span>
              </ButtonBase>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default MobileAccountInfo;
