import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { getElementTextLength, getPartialElement } from './utils';

const useStyles = makeStyles(theme => ({
  readMoreBtn: {
    color: props => props.color || theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: '16px',
  },
  moreLessBtn: {
    color: '#06649D',
    fontSize: 17,
    lineHeight: '23px',
  },
}));

const ReadMoreLessWrapper = ({
  children,
  limit,
  color,
  withButton,
  expand,
  buttonHandler,
  readMoreLessHandler,
  hideReadText,
  triggerResetShortMode,
}) => {
  const classes = useStyles({ color });
  const hasLimit = limit !== undefined;
  const [shortMode, setShortMode] = useState(hasLimit);
  const [siteCopy] = useSiteCopySelector(['resource-item-page']);
  const expansionHandler = e => {
    if (e.key === 'Enter' || e.type === 'click') {
      if (readMoreLessHandler) readMoreLessHandler(shortMode);
      setShortMode(!shortMode);
    }
  };

  useEffect(() => {
    if (triggerResetShortMode) {
      setShortMode(hasLimit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerResetShortMode]);

  const shortened = useMemo(
    () =>
      limit
        ? short(
            children,
            limit,
            expansionHandler,
            color,
            hideReadText,
            _get(siteCopy, ['pageCopy', 'readMoreLabel']),
          )
        : null,
    [children, limit, hideReadText, shortMode],
  );
  return (
    <>
      {hasLimit && shortMode ? (
        <>{shortened}</>
      ) : (
        <>
          {children}
          {withButton && (
            <ButtonBase
              onClick={buttonHandler}
              className={classes.moreLessBtn}
              disableRipple
            >
              ...
              {_get(siteCopy, [
                'pageCopy',
                !expand ? 'readMoreLabel' : 'readLessLabel',
              ])}
            </ButtonBase>
          )}
          {hasLimit && !shortMode && !hideReadText && (
            <span
              role="button"
              aria-expanded={!shortMode}
              aria-controls="read-more-content"
              onClick={expansionHandler}
              onKeyDown={expansionHandler}
              tabIndex={0}
              className={classes.readMoreBtn}
            >
              &nbsp;{_get(siteCopy, ['pageCopy', 'readLessLabel'])}
            </span>
          )}
        </>
      )}
    </>
  );
};

function short(arr, maxLen, readMore, color, hideReadText, readMoreLabel) {
  let finalArr;
  const referenceArr = arr.filter(el => el !== null);
  if (
    (referenceArr.length === 2 && typeof referenceArr[1] === 'boolean') ||
    (referenceArr.length === 3 && typeof referenceArr[2] === 'boolean')
  ) {
    [finalArr] = referenceArr;
  } else {
    finalArr = referenceArr;
  }
  let l = 0;
  for (let i = 0; i < finalArr.length; i += 1) {
    const elementLength = getElementTextLength(finalArr[i]);
    if (l + elementLength > maxLen) {
      const res = finalArr.slice(0, i);
      const partial = getPartialElement(
        finalArr[i],
        maxLen - l,
        readMore,
        color,
        hideReadText,
        readMoreLabel,
        i,
      );
      res.push(partial);
      return res;
    }
    l += elementLength;
  }
  return finalArr;
}

ReadMoreLessWrapper.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  limit: PropTypes.number,
  withButton: PropTypes.bool,
  buttonHandler: PropTypes.func,
  readMoreLessHandler: PropTypes.func,
  expand: PropTypes.bool,
  hideReadText: PropTypes.bool,
  triggerResetShortMode: PropTypes.any,
};

export default ReadMoreLessWrapper;
