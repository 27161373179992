import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import LazyLoad from 'react-lazyload';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { getEmbedlyImageUrl } from 'utils/embedlyUtils';
import { fallbackImage } from 'utils/stringUtils';
import { isEmbedded } from 'utils/embedded';

const useStyles = makeStyles({
  image: {
    height: 100,
    maxWidth: '100%',
    objectFit: 'contain',
  },
  personalizedImage: {
    height: '100%',
  },
  appImage: {
    width: 100,
    border: '1px solid #E9ECED',
    borderRadius: 25,
    backgroundColor: '#FFFFFF',
  },
  blogLinkImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  linkImage: {
    maxHeight: '100%',
    height: 'auto',
  },
  personImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  topicImage: {
    height: 103,
    width: 103,
    borderRadius: 12,
    backgroundColor: '#FFFFFF',
  },
  grayscale: {
    filter: 'grayscale(1)',
  },
});

function Image({ file, grayscale, isLazyList, type, personalizedCard, isPdf }) {
  const classes = useStyles();
  const rawImageSrc = _isEmpty(file)
    ? fallbackImage
    : _has(file, 'url')
    ? file.url
    : file;
  let imageSrc = rawImageSrc;
  if (imageSrc.includes('.crediblemind.com')) {
    imageSrc = fallbackImage;
  }
  if (imageSrc !== fallbackImage) {
    imageSrc = getEmbedlyImageUrl(imageSrc);
  }

  const ImageWrapperComponent =
    isLazyList || isPdf || isEmbedded() ? Box : LazyLoad;
  const imageWrapperProps =
    isLazyList || isPdf ? {} : { height: 123, offset: [100, 0], once: true };

  return (
    <ImageWrapperComponent
      {...imageWrapperProps}
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src={imageSrc}
        onError={e => {
          if (e.target.src !== rawImageSrc) {
            e.target.src = rawImageSrc;
          } else {
            e.target.src = fallbackImage;
          }
        }}
        className={ClassNames(classes.image, {
          [classes.grayscale]: grayscale,
          [classes.personalizedImage]: personalizedCard,
          [classes.appImage]: type === 'app',
          [classes.blogLinkImage]: ['blog', 'series', 'link'].includes(type),
          [classes.personImage]: type === 'person',
          [classes.topicImage]: type === 'topic',
          [classes.linkImage]: type === 'link',
        })}
        alt={type}
      />
    </ImageWrapperComponent>
  );
}

Image.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  grayscale: PropTypes.bool,
  isLazyList: PropTypes.bool,
  isPdf: PropTypes.bool,
  type: PropTypes.string,
};

export default Image;
