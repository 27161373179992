import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ModalDialog from 'components/ModalDialog';
import Mixpanel from 'utils/mixpanelService';
import { getSubdomain } from 'utils/stringUtils';
import { getLocalData, setLocalData } from 'utils/localDataStore';
import CustomAutocomplete from 'components/CustomAutocomplete';
import useSiteCopySelector from 'components/useSiteCopySelector';
import LanguageSelector from 'components/LanguageSelector';
import { useSelector } from 'react-redux';
import { makeSelectAnonymousId } from 'containers/Auth/selectors';
import { getFirebase } from 'react-redux-firebase';
import { parseCloudflareIP } from 'utils/networkUtils';
import useIsCustomOnboardingEnabled from '../hooks/useIsCustomOnboardingEnabled';
import { getCustomOnboardingLocalUtils } from './utils';

const pages = {
  redirect: 'redirectModal',
  reporting: 'reportingModal',
};

const useStyles = makeStyles(theme => ({
  title: {
    ...theme.typography.h4,
    textAlign: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  subTitle: {
    textAlign: 'center',
  },
  languageSelectorContainer: {
    marginLeft: theme.spacing(2),
  },
  inputContainer: {
    maxWidth: '400px',
    width: '100%',
    margin: '0 auto',
    height: 40,
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
  },
  footer: {
    textAlign: 'right',
    marginTop: theme.spacing(2),
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
  },
  footerLink: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
const parseFooterText = text => {
  if (!text) return null;

  // Extract links information and their positions
  const links = [];
  const linkPositions = [];
  const linkRegex = /\[(.*?)\]\((.*?)\)/g;
  let match;

  // eslint-disable-next-line no-cond-assign
  while ((match = linkRegex.exec(text)) !== null) {
    links.push({
      text: match[1],
      url: match[2],
    });
    linkPositions.push({
      start: match.index,
      end: match.index + match[0].length,
    });
  }

  // Get the base text parts and check if they're between links
  const baseTextParts = text
    .split(/\[.*?\]\(.*?\)/)
    .map((part, index) => {
      // Skip empty parts
      if (!part.trim()) return null;

      // If this is between two links, check if it's just a connector
      if (index > 0 && index < links.length) {
        const trimmedPart = part.trim().toLowerCase();
        if (trimmedPart === 'and' || trimmedPart === 'or') {
          // Store the connector type for later use
          links[index - 1].connector = trimmedPart;
          return null;
        }
      }
      return part;
    })
    .filter(Boolean);

  return (
    <>
      {baseTextParts.map((part, index) => (
        <span key={`text-part-${index}`}>{part}</span>
      ))}
      {links.map((link, index) => (
        <React.Fragment key={`link-${index}`}>
          <a
            href={link.url}
            className="footerLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.text}
          </a>
          {index < links.length - 1 && <span> {link.connector || 'and'} </span>}
        </React.Fragment>
      ))}
    </>
  );
};

const CustomOnboardingDialog = ({ hasMultipleLanguage }) => {
  const classes = useStyles();
  const history = useHistory();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentConfigIndex, setCurrentConfigIndex] = useState(0);
  const [values, setValues] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const [customOnboardingSiteCopy] = useSiteCopySelector([
    'custom-onboarding-mapping',
  ]);
  const sessionAnonymousId = useSelector(makeSelectAnonymousId());

  const brand = getSubdomain() || getLocalData('brand');

  const config = _get(customOnboardingSiteCopy, 'pageCopy');
  const customOnboardingClientConfig = new Map(
    Object.entries(
      _get(config, ['clientsConfig', brand], _get(config, 'default', {})),
    ),
  );

  const { showRedirect, showReporting } = useIsCustomOnboardingEnabled();
  const isCustomOnboardingEnabled = showRedirect || showReporting;

  if (!showRedirect) customOnboardingClientConfig.delete(pages.redirect);
  if (!showReporting) customOnboardingClientConfig.delete(pages.reporting);

  const onboardingPageNames = [...customOnboardingClientConfig.keys()].sort(
    name => (name === pages.reporting ? -1 : 1),
  );
  const currentPage = onboardingPageNames[currentPageIndex];

  useEffect(() => {
    if (!isCustomOnboardingEnabled || !isOpen) return;
    Mixpanel.track('Custom Onboarding - Viewed', { modal: currentPage });
  }, [isCustomOnboardingEnabled, isOpen, currentPage]);

  // Reset the current config index when the current page index changes
  useEffect(() => {
    setCurrentConfigIndex(0);
  }, [currentPageIndex]);

  if (!isCustomOnboardingEnabled) return null;

  const getMixpanelProperties = (selectedReporting, selectedRedirect) => {
    const redirectKey = selectedRedirect && Object.keys(selectedRedirect)[0];
    const formattedReporting =
      selectedReporting &&
      Object.keys(selectedReporting).reduce((res, key) => {
        res[key] =
          'value' in selectedReporting[key]
            ? selectedReporting[key].value
            : selectedReporting[key];

        return res;
      }, {});

    return {
      ...formattedReporting,
      ...(redirectKey && {
        [redirectKey]: selectedRedirect[redirectKey].value,
      }),
    };
  };
  const fullConfig = customOnboardingClientConfig.get(currentPage);
  const currentPageConfigLength = Array.isArray(fullConfig)
    ? fullConfig.length
    : 1;
  // handle case when array is used, e.g to display many reporting modals
  const currentPageConfig = Array.isArray(fullConfig)
    ? fullConfig[currentConfigIndex]
    : fullConfig;
  const totalNumberOfPages = onboardingPageNames.length;
  const isLastPage =
    currentPageIndex + 1 === totalNumberOfPages &&
    currentConfigIndex + 1 === currentPageConfigLength;
  const title = _get(currentPageConfig, 'title', '');
  const subtitle = _get(currentPageConfig, 'subtitle', '');
  const placeholder = _get(currentPageConfig, 'placeholder', '');
  const noOptionsText = _get(currentPageConfig, 'noOptionsText', '');
  const type = _get(currentPageConfig, 'type', 'select');
  const mixpanelFieldName = _get(
    currentPageConfig,
    'mixpanelFieldName',
    currentConfigIndex,
  );
  const buttons = _get(config, 'buttons', {});
  const footer = _get(currentPageConfig, 'footer', '');
  const isCurrentPageModalRequired = _get(currentPageConfig, 'required', false);
  const currentPageModalInputType = _get(currentPageConfig, 'type');
  const currentPageModalButtonLabel = _get(
    currentPageConfig,
    'completeButtonLabel',
  );

  const currentPageSelectedValue =
    values[currentPage]?.[mixpanelFieldName] ||
    values[currentPage]?.[_get(currentPageConfig, 'firebaseFieldName')];
  const disableMixpanelTracking = _get(
    currentPageConfig,
    'disableMixpanel',
    false,
  );
  const isInputType = currentPageModalInputType === 'input';
  const isDisabled = _isEmpty(currentPageSelectedValue);

  const saveAnonUserData = async label => {
    const userID = getLocalData('userIdTemp') || sessionAnonymousId;
    if (userID) {
      const ip = await parseCloudflareIP();
      getFirebase()
        .firestore()
        .collection('anonymous_users')
        .doc(userID)
        .set({
          [label]: currentPageSelectedValue,
          timestamp: getFirebase().firestore.FieldValue.serverTimestamp(), // Use server timestamp
          ip,
          createdAt: new Date().toISOString(),
          brand,
          destinationUrl: window.location.href,
        });
    }
  };

  const handleCustomBoardingViewed = async () => {
    const selectedReporting = _get(values, pages.reporting);
    const selectedRedirect = _get(values, pages.redirect);

    if (selectedReporting) {
      setLocalData(
        `${brand}_customOnboardingReport`,
        JSON.stringify(selectedReporting),
      );
    }

    if (selectedRedirect) {
      history.push(Object.values(selectedRedirect)[0].value);
    }

    const { set } = getCustomOnboardingLocalUtils();
    if (showReporting) set(pages.reporting, true);
    if (showRedirect) set(pages.redirect, true);

    setIsOpen(false);

    if (!disableMixpanelTracking) {
      const customOnboardingObject = getMixpanelProperties(
        selectedReporting,
        selectedRedirect,
      );
      Mixpanel.track('Custom Onboarding - Completed', customOnboardingObject);
    }
    // if disableMixpanelTracking is true and firebaseFieldName is available, send data to anon firebase collection
    if (disableMixpanelTracking) {
      const firebaseFieldName = _get(currentPageConfig, 'firebaseFieldName');
      if (firebaseFieldName) {
        await saveAnonUserData(firebaseFieldName);
      }
    }
  };

  const handleNavigateToNextPage = async () => {
    if (isLastPage) {
      await handleCustomBoardingViewed();
      return;
    }

    if (fullConfig.length && currentConfigIndex + 1 < fullConfig.length) {
      setCurrentConfigIndex(currentConfigIndex + 1);
    } else {
      setCurrentPageIndex(currentPageIndex + 1);
    }
  };

  const updateNestedState = value => {
    setValues({
      ...values,
      [currentPage]: {
        ...values[currentPage],
        [mixpanelFieldName]: value,
      },
    });
  };

  const onCheckboxChange = e => {
    const { checked, value } = e.target;
    const selectedOptions = values[currentPage]?.[mixpanelFieldName] || [];

    updateNestedState(
      checked
        ? [...selectedOptions, value]
        : selectedOptions.filter(s => s !== value),
    );
  };

  const modalButtons = [
    {
      label: isLastPage
        ? currentPageModalButtonLabel || _get(buttons, 'complete')
        : _get(buttons, 'next'),
      color: 'primary',
      variant: 'contained',
      onClick: handleNavigateToNextPage,
      disabled: isDisabled,
    },
  ];

  // we would like to support both array and object options
  const currentPageConfigOptions = _get(currentPageConfig, 'options', []);
  const options = Array.isArray(currentPageConfigOptions)
    ? currentPageConfigOptions.map(value => ({
        label: value,
        value,
      }))
    : Object.keys(currentPageConfigOptions).map(key => ({
        label: key,
        value: currentPageConfigOptions[key],
      }));

  const subtitleHTML = subtitle?.replace(/(?:\r\n|\r|\n)/g, '<br />');

  const handleClose = () => {
    if (!isCurrentPageModalRequired) {
      Mixpanel.track('Custom Onboarding - Dismissed', { modal: currentPage });
      setIsOpen(false);
    }
  };

  const renderInput = () => {
    if (currentPageModalInputType === 'input') {
      return (
        <TextField
          fullWidth
          variant="outlined"
          value={currentPageSelectedValue}
          onChange={e => updateNestedState(e.target.value)}
          placeholder={placeholder}
          required={isCurrentPageModalRequired}
          className={classes.textField}
          InputProps={{
            style: { textAlign: 'center' },
          }}
        />
      );
    }

    if (type === 'checkbox') {
      return (
        <FormGroup className={classes.group}>
          {options.map(o => (
            <FormControlLabel
              key={o.value}
              label={o.label}
              control={
                <Checkbox
                  color="primary"
                  value={o.value}
                  checked={values[currentPage]?.[mixpanelFieldName]?.includes(
                    o.value,
                  )}
                  onChange={onCheckboxChange}
                />
              }
            />
          ))}
        </FormGroup>
      );
    }

    return (
      <CustomAutocomplete
        dataKey={currentPage}
        value={currentPageSelectedValue}
        onChange={item => updateNestedState(item)}
        placeholder={placeholder}
        options={options}
        noOptionsText={noOptionsText}
      />
    );
  };

  return (
    <ModalDialog
      open={isOpen}
      languageSelector={() =>
        hasMultipleLanguage && (
          <div className={classes.languageSelectorContainer}>
            <LanguageSelector hasMultipleLanguage={hasMultipleLanguage} />
          </div>
        )
      }
      renderHeader={() => (
        <Typography color="primary" className={classes.title}>
          {title}
        </Typography>
      )}
      onClose={handleClose}
      buttons={modalButtons}
    >
      <Box display="flex" justifyContent="center">
        <Box width={isInputType ? '100%' : 'auto'}>
          {subtitle && (
            <Typography variant="body2" className={classes.subTitle}>
              <article dangerouslySetInnerHTML={{ __html: subtitleHTML }} />
            </Typography>
          )}
          <Box className={classes.modalContent}>
            <div className={classes.inputContainer}>{renderInput()}</div>
          </Box>
          {footer && (
            <Typography className={classes.footer}>
              {parseFooterText(footer)}
            </Typography>
          )}
        </Box>
      </Box>
    </ModalDialog>
  );
};

export default CustomOnboardingDialog;
