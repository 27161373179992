import { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getSeriesTakenInSession,
  getTakenSeriesProcessing,
} from 'containers/Series/selectors';
import { debounce } from 'lodash';
import { useSeries } from 'containers/Series/hooks/useSeries';
import { useHistory, useLocation } from 'react-router-dom';
import Mixpanel from 'utils/mixpanelService';
import { useMediaSession } from './useMediaSession';

const stateSelector = createStructuredSelector({
  profile: state => state.firebase.profile,
  takenSeries: getSeriesTakenInSession,
  takenSeriesProcessing: getTakenSeriesProcessing,
});

export const useAudioPlayer = ({
  series,
  podcast,
  playPodcast,
  closePodcast,
  isPlaying,
  isExpanded,
  setIsExpanded,
  setIsPlaying,
  audioSrc,
}) => {
  const audioRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  const pageName = location.pathname.split('/')[1];
  const finalPageName = pageName ? `${pageName}Page` : 'homePage';

  const { profile, takenSeries, takenSeriesProcessing } = useSelector(
    stateSelector,
  );
  const podcastRef = useRef(podcast);

  const { handleCompleteChange, onSeriesComplete } = useSeries(
    series,
    profile,
    takenSeriesProcessing,
  );

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const view = isExpanded ? 'full' : 'bar';
  const progressTime = (currentTime / duration) * 100;
  const resourceProgress = `${parseFloat((progressTime || 0).toFixed(2))}%`;

  const taken = takenSeries?.[series?.sys?.id];
  const completedResourcesIds = taken?.completedResourcesIds;
  const resources = series?.fields?.resources;
  const author = podcast?.fields?.person?.[0]?.fields?.name;
  const imageSrc = podcast?.fields?.imageUrl;
  const title = podcast?.fields?.title;
  const id = podcast?.sys?.id;
  const isPodcastCompleted = completedResourcesIds?.includes(id);

  const basicEventProps = {
    name: title,
    format: 'audio',
    page: finalPageName,
    entryId: id,
    path: 'series',
  };

  useEffect(() => {
    if (series?.fields?.title) {
      setIsExpanded(true);
    }
  }, [series]);

  useEffect(() => {
    setIsPlaying(false);
    setCurrentTime(0);
    setDuration(0);
    setPlaybackRate(1);

    if (audioSrc && audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  }, [audioSrc]);

  useEffect(() => {
    podcastRef.current = podcast;
  }, [podcast]);

  const debouncedSeekEvent = useCallback(
    debounce(() => {
      const currentPodcast = podcastRef.current;
      if (!currentPodcast) return;

      Mixpanel.track('Resource - Seek', {
        ...basicEventProps,
        name: currentPodcast.fields?.title,
        entryId: currentPodcast?.sys?.id,
      });
    }, 1000),
    [podcast, finalPageName, resourceProgress],
  );

  const togglePlay = () => {
    if (!audioRef.current) return;

    if (isPlaying) audioRef.current.pause();
    else audioRef.current.play();

    Mixpanel.track(`Resource - ${isPlaying ? 'Pause' : 'Resume'}`, {
      view,
      resourceProgress,
      ...basicEventProps,
    });

    setIsPlaying(!isPlaying);
  };

  const handleClosePodcast = () => {
    closePodcast();
    Mixpanel.track('Series Bar - Close', {
      timestamp: audioRef.current.currentTime,
      ...basicEventProps,
    });
  };

  const findPodcastIndex = () => resources?.findIndex(r => r?.sys?.id === id);

  const disableNextButton = findPodcastIndex() === resources?.length - 1;
  const disablePrevButton = !findPodcastIndex();

  const playNext = fromLockScreen => {
    const index = findPodcastIndex();
    if (resources?.[index + 1]) {
      playPodcast({ series, podcast: resources[index + 1] }, fromLockScreen);
    }
  };

  const handlePlayNext = fromLockScreen => {
    playNext(fromLockScreen);
    Mixpanel.track('Series - Next - Clicked', {
      slug: series?.fields?.slug,
      name: series?.fields?.title,
      resourceSlug: podcast?.fields?.slug,
      resourceName: podcast?.fields?.title,
      completed: isPodcastCompleted,
      format: 'audio',
      path: 'series',
    });
  };

  const playPrevious = fromLockScreen => {
    const index = findPodcastIndex();
    if (resources?.[index - 1]) {
      playPodcast({ series, podcast: resources[index - 1] }, fromLockScreen);
    }
    Mixpanel.track('Series - Previous - Clicked', {
      slug: series?.fields?.slug,
      name: series?.fields?.title,
      resourceSlug: podcast?.fields?.slug,
      resourceName: podcast?.fields?.title,
      completed: isPodcastCompleted,
      format: 'audio',
      path: 'series',
    });
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    Mixpanel.track(
      isExpanded ? 'Series Full View - Collapse' : 'Series Bar - Expand',
      {
        timestamp: audioRef.current.currentTime,
        ...basicEventProps,
      },
    );
  };

  const goToTheSeries = () => {
    setIsExpanded(!isExpanded);
    history.push({
      pathname: `/series/${series?.fields?.slug}`,
    });
  };

  const goToThePodcast = () => {
    setIsExpanded(!isExpanded);
    history.push({
      pathname: `/podcasts/${podcast?.fields?.slug}`,
    });
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);

      const progressPercentage =
        (audioRef?.current?.currentTime / audioRef?.current?.duration) * 100;

      if (!isPodcastCompleted && progressPercentage >= 90) {
        Mixpanel.track('Resource - Completed', {
          resourceProgress: '90%',
          view,
          ...basicEventProps,
        });
        handleCompleteChange(podcast, true);
      }
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) setDuration(audioRef.current.duration);
  };

  const handleTimeChange = value => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
      setCurrentTime(value);
    }
  };

  const handleMediaPause = () => {
    if (!audioRef.current) return;

    audioRef.current.pause();

    setIsPlaying(false);

    Mixpanel.track('Resource - Pause', {
      view: 'lock screen',
      resourceProgress,
      ...basicEventProps,
    });
  };

  const handleMediaSeek = event => {
    if (audioRef.current) {
      const seekTime = event?.seekTime;

      handleTimeChange(seekTime);
      Mixpanel.track('Resource - Seek', {
        ...basicEventProps,
        view: 'lock screen',
      });
    }
  };

  const handleMediaGoBackward = event => {
    if (audioRef.current) {
      const seekOffset = event?.seekOffset;

      const time = Math.max(audioRef.current.currentTime - seekOffset, 0);
      handleTimeChange(time);
      Mixpanel.track('Play Backward - Clicked', {
        resourceProgress,
        duration: seekOffset,
        view: 'lock screen',
        ...basicEventProps,
      });
    }
  };

  const handleMediaGoForward = event => {
    if (audioRef.current) {
      const seekOffset = event?.seekOffset;

      const time = audioRef.current.currentTime + seekOffset;
      handleTimeChange(time);
      Mixpanel.track('Play Forward - Clicked', {
        resourceProgress,
        duration: seekOffset,
        view: 'lock screen',
        ...basicEventProps,
      });
    }
  };

  const handleMediaPlay = () => {
    if (!audioRef.current) return;

    audioRef.current.play();

    setIsPlaying(true);
  };

  const handleSeek = useCallback((_, value) => {
    handleTimeChange(value);

    debouncedSeekEvent();
  }, []);

  const handleGoBackward = () => {
    if (duration) {
      const time = Math.max(currentTime - 15, 0);
      handleTimeChange(time);
      Mixpanel.track('Play Backward - Clicked', {
        resourceProgress,
        duration: 15,
        ...basicEventProps,
      });
    }
  };

  const handleGoForward = () => {
    if (duration) {
      const time = Math.min(currentTime + 15, duration);
      handleTimeChange(time);
      Mixpanel.track('Play Forward - Clicked', {
        resourceProgress,
        duration: 15,
        ...basicEventProps,
      });
    }
  };

  const handleAudioEnd = () => {
    if (resources?.length === completedResourcesIds?.length) {
      onComplete();
      return;
    }

    if (disableNextButton) {
      setIsPlaying(false);
    } else {
      playNext();
    }
  };

  const handleSpeedChange = () => {
    const newRate = playbackRate === 2 ? 1 : playbackRate + 0.5;
    setPlaybackRate(newRate);
    if (audioRef.current) audioRef.current.playbackRate = newRate;
    Mixpanel.track('Resource Speed - Change', {
      speed: newRate,
      ...basicEventProps,
    });
  };

  const onComplete = () => {
    history.push({
      pathname: `/series/${series?.fields?.slug}`,
    });
    handleClosePodcast();
    onSeriesComplete();
  };

  useMediaSession({
    audioRef,
    title,
    author,
    imageSrc,
    handleMediaPlay,
    handleMediaPause,
    handleMediaGoBackward,
    handleMediaGoForward,
    handleMediaSeek,
  });

  return {
    audioRef,
    isPlaying,
    currentTime,
    duration,
    playbackRate,
    isExpanded,
    togglePlay,
    handlePlayNext,
    goToTheSeries,
    goToThePodcast,
    playPrevious,
    toggleExpand,
    handleTimeUpdate,
    handleLoadedMetadata,
    handleSeek,
    handleAudioEnd,
    handleGoBackward,
    handleGoForward,
    handleSpeedChange,
    handleClosePodcast,
    disableNextButton,
    disablePrevButton,
  };
};
