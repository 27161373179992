import React from 'react';
import loadable from 'utils/loadable';
import LoadingIndicator from 'components/LoadingIndicator';
import HomePageWrapper from 'containers/HomePageWrapper/Loadable';
import Resources from 'containers/Resources/Loadable';
import Topics from 'containers/Topics/Loadable';
import CustomAuthentication from 'containers/CustomAuthentication/Loadable';
import SignUp from 'containers/Signup/Loadable';
import SignUpExtraData from 'containers/SignupExtraData/Loadable';
import SignUpEmailData from 'containers/SignupEmailData/Loadable';
import SignupVerify from 'containers/SignupVerify/Loadable';
import SignUpCM from 'containers/SignupCM/Loadable';
import Login from 'containers/Login/Loadable';
import LoginCM from 'containers/LoginCM/Loadable';
import ForgotPassword from 'containers/ForgotPassword/Loadable';
import Account from 'containers/Account/Loadable';
import Favorites from 'containers/Favorites/Loadable';
import Assessments from 'containers/Assessments/Loadable';
import Assessment from 'containers/Assessment/Loadable';
import AssessmentResult from 'containers/AssessmentResult/Loadable';
import Editor from 'containers/Editor/Loadable';
import Blogs from 'containers/Blogs/Loadable';
import News from 'containers/News/Loadable';
import EditorLists from 'containers/EditorLists/Loadable';
import InCrisis from 'containers/InCrisis/Loadable';
import ClientLanding from 'containers/ClientLanding/Loadable';
import BestResources from 'containers/BestResources/Loadable';
import AdminAssessmentTool from 'containers/Admin/AssessmentTool/Loadable';
import AdminAssessmentResultTestTool from 'containers/Admin/AssessmentResultTestTool/Loadable';
import ClientAdminPortal from 'containers/ClientAdminPortal/Loadable';
import AdminMangeUsers from 'containers/AdminManageUsers/Loadable';
import AssessmentClinicalResultDetail from 'containers/AssessmentClinical/ResultDetail/Loadable';
import AssessmentClinicalResults from 'containers/AssessmentClinical/Results/Loadable';
import ResourceController from 'containers/ResourceController/Loadable';
import SeriesList from 'containers/SeriesList/Loadable';
import LearningLab from 'containers/LearningLab/Loadable';
import TwoFactorAuth from 'containers/TwoFactorAuth/Loadable';

const HowItWorks = loadable(() => import('containers/HowItWorks'), {
  fallback: <LoadingIndicator />,
});
const ClientResources = loadable(() => import('containers/ClientResources'), {
  fallback: <LoadingIndicator />,
});
const Redirect = loadable(() => import('containers/Redirect'), {
  fallback: <LoadingIndicator />,
});
const AdminToolsPage = loadable(() => import('containers/Admin'), {
  fallback: <LoadingIndicator />,
});
const AdminAlgoliaListFilteringTool = loadable(
  () => import('containers/Admin/AlgoliaListFilteringTool'),
  {
    fallback: <LoadingIndicator />,
  },
);
const AdminAssessmentResultsTool = loadable(
  () => import('containers/Admin/AssessmentResultsTool'),
  {
    fallback: <LoadingIndicator />,
  },
);
const AdminAssessmentValidationTool = loadable(
  () => import('containers/Admin/AssessmentValidationTool'),
  {
    fallback: <LoadingIndicator />,
  },
);
const AdminClientsTool = loadable(
  () => import('containers/Admin/ClientsTool'),
  {
    fallback: <LoadingIndicator />,
  },
);
const AdminCreateUserRequest = loadable(
  () => import('containers/AdminCreateUserRequest'),
  {
    fallback: <LoadingIndicator />,
  },
);
const AdminHelpfulDataTool = loadable(
  () => import('containers/Admin/HelpfulDataTool'),
  {
    fallback: <LoadingIndicator />,
  },
);
const AdminRichTextTool = loadable(
  () => import('containers/Admin/RichTextTool'),
  {
    fallback: <LoadingIndicator />,
  },
);
const AdminUserReviewsTool = loadable(
  () => import('containers/Admin/UserReviewsTool'),
  {
    fallback: <LoadingIndicator />,
  },
);
const AdminBestListsTool = loadable(
  () => import('containers/Admin/BestListsTool'),
  {
    fallback: <LoadingIndicator />,
  },
);
const AdminListsTool = loadable(() => import('containers/Admin/ListsTool'), {
  fallback: <LoadingIndicator />,
});
const AdminEditResource = loadable(
  () => import('containers/AdminEditResource'),
  {
    fallback: <LoadingIndicator />,
  },
);

const CompleteUserRequest = loadable(
  () => import('containers/CompleteUserRequest'),
  {
    fallback: <LoadingIndicator />,
  },
);

const PrivacyPolicy = loadable(
  () => import('containers/PrivacyPolicy/PrivacyPolicyRoute'),
  {
    fallback: <LoadingIndicator />,
  },
);

const TermsOfUse = loadable(() => import('containers/TermsOfUse/TermsRoute'), {
  fallback: <LoadingIndicator />,
});

const Feedback = loadable(() => import('containers/Feedback'), {
  fallback: <LoadingIndicator />,
});

const Routes = [
  {
    path: '/2fa',
    name: 'Two Factor Authentication',
    component: TwoFactorAuth,
    exact: true,
    ignoreRequire: true,
  },
  {
    path: '/signup',
    name: 'Sign Up',
    component: SignUp,
    exact: true,
    ignoreRequire: true,
  },
  {
    path: '/signup/data',
    name: 'Signup Extra Data',
    component: SignUpExtraData,
    exact: true,
    authLoadedRequire: true,
  },
  {
    path: '/signup/email',
    name: 'Signup Email Data',
    component: SignUpEmailData,
    exact: true,
    authLoadedRequire: true,
  },
  {
    path: '/signup/verify',
    name: 'Signup Verify',
    component: SignupVerify,
    exact: true,
    authLoadedRequire: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    exact: true,
    ignoreRequire: true,
  },
  {
    path: '/logincm',
    name: 'Login CM',
    component: LoginCM,
    exact: true,
    ignoreRequire: true,
  },
  {
    path: '/signupcm',
    name: 'Sign Up CM',
    component: SignUpCM,
    exact: true,
    ignoreRequire: true,
  },
  {
    path: [
      '/auth',
      '/auth/signup',
      '/auth/login',
      '/auth/eligibility',
      '/auth/sso',
      '/auth/sso/:path/:slug',
    ],
    name: 'Custom Authentication',
    component: CustomAuthentication,
    exact: false,
    ignoreRequire: true,
  },
  {
    path: ['/forgot_password', '/new_password'],
    name: 'Forgot Password',
    component: ForgotPassword,
    exact: true,
    ignoreRequire: true,
  },
  {
    path: ['/', '/onboarding/start'],
    name: 'Home',
    component: HomePageWrapper,
    exact: true,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources,
    exact: true,
  },
  {
    path: '/assessments',
    name: 'Assessments',
    component: Assessments,
    exact: true,
  },
  {
    path: [
      '/assessments/clinicalresults',
      '/assessments/clinicalresults/:code',
    ],
    name: 'Assesments Clinical Results',
    component: AssessmentClinicalResults,
    exact: true,
  },
  {
    path: '/assessments/clinicalresults/:code/:id',
    name: 'Assesments Clinical Results Detail',
    component: AssessmentClinicalResultDetail,
    exact: true,
  },
  {
    path: '/assessments/:slug/results',
    name: 'AssessmentResult',
    component: AssessmentResult,
    exact: true,
  },
  {
    path: ['/assessments/:slug', '/assessments/:slug/:page'],
    name: 'Assessment',
    component: Assessment,
    exact: true,
  },
  {
    path: '/topics',
    name: 'Topics',
    component: Topics,
    exact: true,
  },
  {
    path: '/learning-lab',
    name: 'Learning Lab',
    component: LearningLab,
    exact: true,
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    exact: false,
    authRequire: true,
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites,
    exact: true,
    authRequire: true,
  },
  {
    path: [
      '/topics/:slug',
      '/videos/:slug',
      '/apps/:slug',
      '/podcasts/:slug',
      '/books/:slug',
      '/organizations/:slug',
      '/services/:slug',
      '/articles/:slug',
      '/blogs/:slug',
      '/faqs/:slug',
      '/lists/:slug/:topic',
      '/lists/:slug',
      '/people/:slug',
      '/programs/:slug',
      '/insights/:slug',
      '/series/:slug',
      '/courses/:slug/:path(introduction|dashboard|[a-z0-9-]+-module)?',
    ],
    name: 'Resource Controller',
    component: ResourceController,
    exact: false,
  },
  {
    path: '/editors/:slug',
    name: 'Editor',
    component: Editor,
    exact: true,
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: Blogs,
    exact: true,
  },
  {
    path: '/insights',
    name: 'Blogs',
    component: Blogs,
    exact: true,
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    exact: true,
  },
  {
    path: '/lists',
    name: 'Lists',
    component: EditorLists,
    exact: true,
  },
  // {
  //   path: '/company/sponsors',
  //   name: 'sponsors',
  //   component: Sponsors,
  //   exact: true,
  // },
  // {
  //   path: '/company/sponsors/:slug',
  //   name: 'sponsor',
  //   component: Sponsor,
  //   exact: true,
  // },
  // {
  //   path: '/company/need-help',
  //   name: 'need-help',
  //   component: InCrisis,
  //   exact: true,
  // },
  // {
  //   path: '/company',
  //   name: 'Company',
  //   component: Company,
  //   exact: false,
  // },
  {
    path: '/in-crisis',
    name: 'need-help',
    component: InCrisis,
    exact: true,
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: Feedback,
    exact: true,
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsOfUse,
    exact: true,
  },
  {
    path: '/admin',
    name: 'Admin Tool',
    component: AdminToolsPage,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/algolia-list-filtering-tool',
    name: 'Admin Algolia List Filtering Tool',
    component: AdminAlgoliaListFilteringTool,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/assessments-tool',
    name: 'Admin Assessment Tool',
    component: AdminAssessmentTool,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/assessment-result-test-tool',
    name: 'Admin Assessment Result Test Tool',
    component: AdminAssessmentResultTestTool,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/assessments-validation-tool',
    name: 'Admin Assessment Validation Tool',
    component: AdminAssessmentValidationTool,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/clients-tool',
    name: 'Admin Clients Tool',
    component: AdminClientsTool,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/clients-tool/add-user/:brand',
    name: 'Admin Create User Request',
    component: AdminCreateUserRequest,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/clients-tool/users/:brand',
    name: 'Admin Manage Users',
    component: AdminMangeUsers,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/rich-text-tool',
    name: 'Admin Rich Text Tool',
    component: AdminRichTextTool,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/helpful-tool',
    name: 'Admin Was This Helpful Data Tool',
    component: AdminHelpfulDataTool,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/user-reviews-tool',
    name: 'Admin User Reviews Tool',
    component: AdminUserReviewsTool,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/assessments-results-tool',
    name: 'Admin Assessment Results Tool',
    component: AdminAssessmentResultsTool,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/best-lists-tool',
    name: 'Admin Best Lists Tool',
    component: AdminBestListsTool,
    exact: true,
    authRequire: true,
  },
  {
    path: '/admin/lists-tool',
    name: 'Admin Lists Tool',
    component: AdminListsTool,
    exact: true,
    authRequire: true,
  },
  {
    path: '/landing/:slug',
    name: 'Client Landing Page',
    component: ClientLanding,
    exact: true,
  },
  {
    path: '/bestlists/:topicSlug/:resourceSlug',
    name: 'BestResources',
    component: BestResources,
    exact: true,
  },
  {
    path: '/how-it-works',
    name: 'HowItWorks',
    component: HowItWorks,
    exact: true,
  },
  {
    path: '/client-resources',
    name: 'ClientResources',
    component: ClientResources,
    exact: true,
  },
  {
    path: '/user-resources',
    name: 'ClientResources',
    component: ClientResources,
    exact: true,
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: Redirect,
    exact: true,
    clientCheckPath: 'metadata.showExternalRedirectPage',
  },
  {
    path: '/client-admin',
    name: 'Client Admin Portal',
    component: ClientAdminPortal,
    exact: false,
    authRequire: false,
  },
  {
    path: '/admin/edit/:resourceType/:id',
    name: 'Admin Edit Resource',
    component: AdminEditResource,
    exact: false,
    authRequire: false,
  },
  {
    path: '/client-admin/edit/:resourceType/:id',
    name: 'Client Admin Edit Resource',
    component: AdminEditResource,
    exact: false,
    authRequire: false,
  },
  {
    path: '/complete-user-request',
    name: 'Complete User Request',
    component: CompleteUserRequest,
    exact: true,
    authRequire: false,
  },
  {
    path: '/series',
    name: 'Series List',
    component: SeriesList,
    exact: true,
    authRequire: false,
  },
];

export default Routes;
