/**
 *
 * ResourceLabel
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import { isIE } from 'utils/stringUtils';
import BookmarkIcon from './images/bookmark.svg';
const useStyles = makeStyles(theme => ({
  root: {
    height: 18,
    backgroundColor: '#0862C0',
    borderRadius: 10,
    color: '#fff',
    width: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
  },
  ieRoot: {
    left: '25%',
  },
  videoLabel: {
    position: 'inherit',
  },
  textLabel: {
    color: '#fff',
    ...theme.typography.pxSmall,
  },
  icon: {
    marginRight: 10,
    width: 9,
  },
  tallerBadge: {
    bottom: '-90px',
  },
  bigImageBadge: {
    bottom: '-158px',
  },
  appLabel: {
    borderBottomRightRadius: 24,
    borderBottomLeftRadius: 24,
    border: '1px solid transparent',
  },
  bottomLabel: {
    bottom: 'auto',
  },
}));

/* eslint-disable react/prefer-stateless-function */
function ResourceLabel({ labels, type, isBig, isMobile }) {
  const classes = useStyles();
  const isIEBrowser = isIE();
  if (isEmpty(labels)) return null;
  const bottomLabelTypes = ['podcast'];
  return (
    <div
      className={ClassNames(
        classes.root,
        type === 'app' && isMobile && classes.appLabel,
        bottomLabelTypes.includes(type) && classes.bottomLabel,
        isBig ? classes.bigImageBadge : null,
        isMobile && type === 'video' && classes.videoLabel,
        isIEBrowser ? classes.ieRoot : undefined,
      )}
    >
      <Typography align="center" className={classes.textLabel}>
        <img src={BookmarkIcon} alt="Pick" className={classes.icon} />
        {labels[0]}
      </Typography>
    </div>
  );
}
ResourceLabel.propTypes = {
  labels: PropTypes.array,
  type: PropTypes.string,
  isBig: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default ResourceLabel;
