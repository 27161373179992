import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import useWindowSize from 'components/useWindowSize';
import useModalFocusTrap from 'components/Hooks/useModalFocusTrap';
import NavBarContext from '../../navBarContext';
import AssessmentMainItem from './AssessmentMainItem';
import MostPopularAssessment from './MostPopularAssessment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 30px 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 20px 20px',
    },
  },
  header: {
    marginBottom: 8,
    height: 36,
  },
  lists: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 28,
  },
  image: {
    width: '100%',
    height: 139,
    marginBottom: 6,
    objectFit: 'cover',
  },
  listItem: {
    width: 246,
    marginRight: 21,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

function Assessments({ trackingProps, onClose, onFocusNext }) {
  const classes = useStyles();
  const { assessments, siteCopy, sendMixpanelEvent } = useContext(
    NavBarContext,
  );
  const { width } = useWindowSize();
  const { refCallback } = useModalFocusTrap(onClose, onFocusNext);
  const isMobile = width <= 600;

  const clickHandler = (name, element = 'text') => {
    sendMixpanelEvent('Menu Item - Clicked', {
      type: 'Assessments',
      name,
      element,
    });
  };
  if (!assessments) return null;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={classes.root} ref={refCallback}>
      <div className={classes.rightContent}>
        <Typography
          variant="subtitle1"
          className={classes.header}
          color="textPrimary"
        >
          {_get(siteCopy, ['pageCopy', 'submenu-assessments', 'latest'])}
        </Typography>
        <AssessmentMainItem
          data={assessments.slice(0, isMobile ? 2 : 1)}
          clickHandler={clickHandler}
          trackingProps={trackingProps}
        />
      </div>
      <MostPopularAssessment
        data={assessments.slice(isMobile ? 2 : 1, isMobile ? 6 : 5)}
        clickHandler={clickHandler}
      />
    </div>
  );
}

Assessments.propTypes = {
  trackingProps: PropTypes.object,
};

export default Assessments;
