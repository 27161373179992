import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { makeSelectAuth } from 'containers/Auth/selectors';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import use2FACheck from 'components/Hooks/use2FACheck';
import LoadingOverlay from 'components/LoadingOverlay';

function PrivateRoute({ component: Component, authLoadedRequire, ...rest }) {
  const auth = useSelector(makeSelectAuth());
  const clientDetails = useSelector(makeSelectClientDetails());
  const { isLoading } = use2FACheck(rest.location?.pathname);

  const hasCustomAuthenticationEnabled = _get(
    clientDetails,
    'customAuthentication.enabled',
  );

  const redirectPathname = hasCustomAuthenticationEnabled ? '/auth' : '/login';

  return (
    <Route
      {...rest}
      render={routeProps => {
        if ((!authLoadedRequire && !auth.isLoaded) || auth.isEmpty) {
          return (
            <Redirect
              to={{
                pathname: redirectPathname,
                search: `?redirect=${routeProps.location.pathname}`,
                state: { from: routeProps.location },
              }}
            />
          );
        }

        if (isLoading) {
          return <LoadingOverlay show />;
        }

        return <Component {...routeProps} />;
      }}
    />
  );
}

export default PrivateRoute;
