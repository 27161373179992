import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getFirebase } from 'react-redux-firebase';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import _isNil from 'lodash/isNil';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import MixPanel from 'utils/mixpanelService';
import Config from 'utils/getEnvConfig';
import AlgoliaClient from 'utils/algoliaService';
import MailChimpService from 'utils/mailchimpService';
import { makeSelectProfile } from 'containers/Auth/selectors';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import {
  UPDATE_ACCOUNT,
  CHANGE_PASSWORD,
  TOGGLE_CONTENTFUL_TOOLS,
  GET_DETAILED_USER_HISTORY,
} from './constants';
import {
  processing,
  updateAccountResult,
  changePasswordResult,
  toggleContentfulToolsResult,
  getDetailedUserHistoryResult,
} from './actions';
import { getUserDisplayName } from '../../utils/stringUtils';

const {
  ALGOLIA: { RESOURCES_AVG_RATED_INDEX },
} = Config;

function* updateAccountSaga({ payload }) {
  const {
    data = {},
    subscription = [],
    signUpCompleted = false,
    customConsent,
  } = payload;

  try {
    yield put(processing(true));

    if (_isEmpty(data) && _isEmpty(subscription))
      throw new Error('No data to be updated');

    if (!_isEmpty(data)) {
      const {
        gender,
        genderOther,
        genderLGBTQp,
        birthdayMonthYear,
        stateOfResidence,
        ...fields
      } = data;
      const displayName = getUserDisplayName(data);
      const profileData = {
        displayName,
        ...fields,
      };
      const mixpanelData = {};
      if (gender && gender !== '-1') {
        profileData.gender = gender;
        mixpanelData.gender = gender;
      }
      if (!_isNil(genderOther)) {
        profileData.genderOther = genderOther;
        mixpanelData.genderOther = genderOther;
      }
      if (genderLGBTQp && genderLGBTQp !== '-1') {
        profileData.genderLGBTQp = genderLGBTQp;
        mixpanelData.genderLGBTQp = genderLGBTQp;
      }
      if (stateOfResidence && stateOfResidence !== '-1') {
        profileData.stateOfResidence = stateOfResidence;
        mixpanelData.stateOfResidence = stateOfResidence;
      }
      if (birthdayMonthYear) {
        profileData.birthdayMonthYear = birthdayMonthYear;
        mixpanelData.birthdayMonthYear = birthdayMonthYear;
      }
      if (signUpCompleted) {
        profileData.signUpCompleted = true;
      }
      if (!_isUndefined(customConsent)) {
        profileData.custom = {
          customConsent,
        };
      }
      yield getFirebase().updateProfile(profileData);

      if (!_isEmpty(mixpanelData)) {
        MixPanel.people.set(mixpanelData);
      }
    }
    if (!_isEmpty(subscription)) {
      const clientDetails = yield select(makeSelectClientDetails());
      const profile = yield select(makeSelectProfile());

      const shouldHideSubscription = _get(
        clientDetails,
        'metadata.hideSubscription',
        false,
      );
      if (!shouldHideSubscription && _get(profile, 'email')) {
        const subscriptionResponse = yield call(() =>
          MailChimpService.getUserStatus(_get(profile, 'email')),
        );
        if (subscriptionResponse.status === 204) {
          const user = yield getFirebase().auth().currentUser;
          const providerId = _get(user, 'providerData.0.providerId', '');
          const isSamlProvider = providerId.startsWith('saml');

          yield call(() =>
            MailChimpService.subscribe({
              email: _get(profile, 'email'),
              subscription,
              firstName: data.firstName || profile.firstName,
              lastName: data.lastName || profile.lastName,
              isSamlProvider,
            }),
          );
        }
      }
    }
    if (!_isEmpty(subscription)) {
      MixPanel.track('Complete registration - Successful');
    } else {
      MixPanel.track('Update account - Successful');
    }

    yield put(
      updateAccountResult({
        message: 'Account updated successfuly!',
        error: false,
      }),
    );
  } catch (error) {
    if (!_isEmpty(subscription)) {
      MixPanel.track('Complete registration - Failed');
    } else {
      MixPanel.track('Update account - Failed');
    }

    yield put(
      updateAccountResult({
        message: 'Something went wrong. Please, try again.',
        error: true,
      }),
    );
  } finally {
    yield put(processing(false));
  }
}

function* changePasswordSaga({ payload }) {
  try {
    yield put(processing(true));
    const { email, password, newPassword } = payload;

    try {
      const credential = yield getFirebase().auth.EmailAuthProvider.credential(
        email,
        password,
      );
      yield getFirebase()
        .auth()
        .currentUser.reauthenticateAndRetrieveDataWithCredential(credential);

      yield getFirebase()
        .auth()
        .currentUser.updatePassword(newPassword);

      MixPanel.track('Password Changed - Successful');
      yield put(
        changePasswordResult({
          message: 'Password changed successfuly!',
          error: false,
        }),
      );
    } catch (error) {
      MixPanel.track('Password Changed - Failed');

      const message =
        error.code === 'auth/weak-password' ||
        error.code === 'auth/wrong-password'
          ? error.message
              .replace('Firebase: ', '')
              .replace(/\s+\(auth\/[a-zA-Z0-9-]+\)./, '')
          : 'Something went wrong. Please, try again.';
      yield put(
        changePasswordResult({
          message,
          error: true,
        }),
      );
    }
  } finally {
    yield put(processing(false));
  }
}

function* toggleContentfulToolsSaga({ payload }) {
  try {
    yield put(processing(true));
    const showContentfulTools = payload;
    yield getFirebase().updateProfile({
      showContentfulTools,
    });
    const message = showContentfulTools
      ? 'Contentful Tools enabled'
      : 'Contentful Tools disabled';
    yield put(toggleContentfulToolsResult({ success: true, message }));
  } catch (error) {
    yield put(toggleContentfulToolsResult({ success: false, error }));
  } finally {
    yield put(processing(false));
  }
}

function* getDetailedUserHistorySaga() {
  try {
    yield put(processing(true));
    const userID = yield getFirebase().auth().currentUser.uid;
    const interactionDocs = yield getFirebase()
      .firestore()
      .collection('user_interaction')
      .where('userId', '==', userID)
      .where('type', 'in', ['resource-page-view', 'topic-view'])
      .get();
    const interactions = interactionDocs.docs.map(doc => doc.data());

    const itemIds = _uniq(interactions.map(item => item.resourceID));

    let idsQuery = '';
    if (!_isEmpty(itemIds)) {
      idsQuery += ' AND (';
      _forEach(itemIds, (value, index) => {
        idsQuery += `objectID:'${value}'`;
        if (index !== itemIds.length - 1) {
          idsQuery += ' OR ';
        }
      });
      idsQuery += ')';
    }

    const algolia = AlgoliaClient.initIndex(RESOURCES_AVG_RATED_INDEX);
    const { hits } = yield algolia.search('', {
      filters: `reviewStatus:'Accepted'${idsQuery}`,
      hitsPerPage: 1000,
    });

    const finalData = _uniqBy(
      interactions
        .sort((a, b) => b.timestamp - a.timestamp)
        .map(item => {
          const [resource] = hits.filter(
            hit => hit.objectID === item.resourceID,
          );

          if (resource) {
            return {
              ...item,
              resource,
            };
          }

          return null;
        })
        .filter(el => !!el),
      'resource.slug',
    );

    yield put(getDetailedUserHistoryResult(finalData));
  } catch (error) {
    yield put(getDetailedUserHistoryResult([]));
  } finally {
    yield put(processing(false));
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(UPDATE_ACCOUNT, updateAccountSaga);
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(TOGGLE_CONTENTFUL_TOOLS, toggleContentfulToolsSaga);
  yield takeLatest(GET_DETAILED_USER_HISTORY, getDetailedUserHistorySaga);
}
