import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Pause, PlayArrow } from '@material-ui/icons';
import React from 'react';
import GoForward from '../images/go-forward.svg';
import GoBackward from '../images/go-backward.svg';
import Next from '../images/next.svg';
import Previous from '../images/previous.svg';
import CloseIcon from '../images/close.svg';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 2,
    '& img': {
      scale: '2',
    },
  },
  iconButton: {
    width: props => (props.isExpanded ? 72 : 32),
    height: props => (props.isExpanded ? 72 : 32),
    backgroundColor: props => props.isExpanded && theme.palette.text.white,
    margin: '0 12px',
    color: props => (props.isExpanded ? '#01619B' : theme.palette.text.white),
    '& svg': {
      scale: props => (props.isExpanded ? '1.6' : '1.2'),
    },
    '&:hover': {
      backgroundColor: props => props.isExpanded && theme.palette.text.white,
      color: props => (props.isExpanded ? '#01619B' : theme.palette.text.white),
    },
    transition: 'none',
  },
  foldedButtonsContainer: {
    position: 'absolute',
    bottom: 0,
    right: 13,
    top: 0,
    margin: 'auto',

    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    padding: 0,
    marginLeft: '-6px',
    marginRight: '-8px',
    '& img': {
      scale: '1.2',
    },
  },
  playbackRateContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 16,
  },
  playbackRateText: {
    textTransform: 'lowercase',
    color: theme.palette.text.white,
    fontSize: 14,
  },
  timeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '-8px',
  },
  time: {
    margin: '0 8px',
    minWidth: 'unset',
    padding: 0,
    fontSize: 14,
    color: theme.palette.text.white,
    whiteSpace: 'nowrap',
  },
  button: {
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
}));

export const PlayerButtons = ({
  togglePlay,
  isPlaying,
  disableNextButton,
  disablePrevButton,
  duration,
  handleClosePodcast,
  currentTime,
  handleGoBackward,
  handleGoForward,
  handleSpeedChange,
  playbackRate,
  handlePlayNext,
  handlePlayPrevious,
  isExpanded,
}) => {
  const classes = useStyles({ isExpanded });

  return isExpanded ? (
    <>
      <Box className={classes.timeContainer}>
        <Typography variant="body2" className={classes.time}>
          {Math.floor(currentTime / 60)}:
          {String(Math.floor(currentTime % 60)).padStart(2, '0')}
        </Typography>
        <Typography variant="body2" className={classes.time}>
          {Math.floor(duration / 60)}:
          {String(Math.floor(duration % 60)).padStart(2, '0')}
        </Typography>
      </Box>
      <Box className={classes.playbackRateContainer}>
        <Button onClick={handleSpeedChange}>
          <Typography variant="body2" className={classes.playbackRateText}>
            {playbackRate}x
          </Typography>
        </Button>
      </Box>

      <Box className={classes.container}>
        <IconButton
          disabled={disablePrevButton}
          onClick={() => {
            handlePlayPrevious();
          }}
          className={classes.button}
        >
          <img src={Previous} alt="Previous" />
        </IconButton>
        <IconButton onClick={handleGoBackward}>
          <img src={GoBackward} alt="Go Backward" />
        </IconButton>
        <IconButton onClick={togglePlay} className={classes.iconButton}>
          {isPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>
        <IconButton onClick={handleGoForward}>
          <img src={GoForward} alt="Go Forward" />
        </IconButton>
        <IconButton
          disabled={disableNextButton}
          onClick={() => {
            handlePlayNext();
          }}
          className={classes.button}
        >
          <img src={Next} alt="Next" />
        </IconButton>
      </Box>
    </>
  ) : (
    <Box className={classes.foldedButtonsContainer}>
      <IconButton onClick={togglePlay} className={classes.iconButton}>
        {isPlaying ? <Pause /> : <PlayArrow />}
      </IconButton>

      <IconButton className={classes.closeIcon} onClick={handleClosePodcast}>
        <img src={CloseIcon} alt="Close" />
      </IconButton>
    </Box>
  );
};
