import GoodEvidenceIcon from '../../images/good-evidence.svg';
import SomeEvidenceIcon from '../../images/some-evidence.svg';
import InsufficentIcon from '../../images/insufficient-evidence.svg';
import NoIcon from '../../images/no-evidence.svg';

export const BG_COLORS = {
  'Good Evidence': '#DAEEF0',
  'Some Evidence': '#E6F4F5',
  'Insufficient Evidence': '#EDF7F8',
  'No Evidence': '#F3FAFA',
};

export const EVIDENCE_ICONS = {
  'Good Evidence': GoodEvidenceIcon,
  'Some Evidence': SomeEvidenceIcon,
  'Insufficient Evidence': InsufficentIcon,
  'No Evidence': NoIcon,
};
