import React from 'react';
import { Box, makeStyles, IconButton } from '@material-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _classNames from 'classnames';
import { useQuery } from 'react-query';
import { useMobileAudioPlayer } from './MobileAudioPlayerContext';
import { PlayerSlider } from './components/PlayerSlider';
import { PlayerButtons } from './components/PlayerButtons';
import { PodcastInfo } from './components/PodcastInfo';
import ArrowDownIcon from './images/arrow-down.svg';
import { fetchListenNotesData } from '../../components/AudioCard';
import { useAudioPlayer } from './hooks/useAudioPlayer';

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    bottom: props => (props.isExpanded ? 0 : 74),
    left: 0,
    width: '100%',
    height: props => props.isExpanded && '100%',
    zIndex: props => (props.isExpanded ? 10000 : 998),
  },
  root: {
    position: 'relative',
    background: '#01619B',
    height: props => props.isExpanded && '100%',
    padding: '16px 12px',
  },
  expandedRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  expandIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 16,
  },
  buttons: {
    marginBottom: props => props.isExpanded && 21,
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    animation: '$scrollTitle 10s linear infinite',
  },
  '@keyframes scrollTitle': {
    '0%': { transform: 'translateX(100%)' },
    '100%': { transform: 'translateX(-100%)' },
  },
});

export const MobileAudioPlayer = () => {
  const {
    series,
    podcast,
    closePodcast,
    playPodcast,
    isPlaying,
    isExpanded,
    setIsExpanded,
    setIsPlaying,
  } = useMobileAudioPlayer();
  const isNoData = _isEmpty(series) && _isEmpty(podcast);
  const { data } = useQuery(
    [
      'listenotes',
      {
        finalType: podcast?.fields?.type,
        listenNotesId: podcast?.fields?.listenNotesId,
      },
    ],
    fetchListenNotesData,
    {
      enabled: isNoData,
    },
  );

  const {
    audioRef,
    currentTime,
    duration,
    playbackRate,
    disableNextButton,
    disablePrevButton,
    togglePlay,
    handlePlayNext,
    playPrevious,
    toggleExpand,
    handleTimeUpdate,
    handleLoadedMetadata,
    handleSeek,
    handleGoBackward,
    handleGoForward,
    handleClosePodcast,
    handleAudioEnd,
    handleSpeedChange,
    goToTheSeries,
    goToThePodcast,
  } = useAudioPlayer({
    series,
    podcast,
    playPodcast,
    isPlaying,
    setIsPlaying,
    closePodcast,
    isExpanded,
    setIsExpanded,
    audioSrc: data?.data?.audio,
  });

  const classes = useStyles({ isExpanded });

  const imageSrc = podcast?.fields?.imageUrl;
  const title = podcast?.fields?.title;
  const seriesTitle = series?.fields?.title;
  const src = data?.data?.audio;

  if (isNoData) return null;

  return (
    <Box className={classes.container}>
      <Box
        className={_classNames(classes.root, {
          [classes.expandedRoot]: isExpanded,
        })}
      >
        <audio
          ref={element => {
            audioRef.current = element;
          }}
          onEnded={handleAudioEnd}
          src={src}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
        />
        <PodcastInfo
          title={title}
          imageSrc={imageSrc}
          seriesTitle={seriesTitle}
          isExpanded={isExpanded}
          toggleExpand={toggleExpand}
          goToTheSeries={goToTheSeries}
          goToThePodcast={goToThePodcast}
        />
        <Box className={classes.buttons}>
          {isExpanded && (
            <PlayerSlider
              currentTime={currentTime}
              duration={duration}
              isExpanded={isExpanded}
              handleSeek={handleSeek}
            />
          )}
          <PlayerButtons
            togglePlay={togglePlay}
            isPlaying={isPlaying}
            duration={duration}
            handleGoBackward={handleGoBackward}
            handleGoForward={handleGoForward}
            currentTime={currentTime}
            handleSpeedChange={handleSpeedChange}
            handlePlayNext={handlePlayNext}
            handlePlayPrevious={playPrevious}
            disableNextButton={disableNextButton}
            disablePrevButton={disablePrevButton}
            handleClosePodcast={handleClosePodcast}
            playbackRate={playbackRate}
            isExpanded={isExpanded}
          />
        </Box>
        {isExpanded && (
          <IconButton className={classes.expandIcon} onClick={toggleExpand}>
            <img src={ArrowDownIcon} alt="Collapse" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
