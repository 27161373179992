import Mixpanel from 'utils/mixpanelService';

const freshworksActions = {
  hideLauncher: () => {
    if (window.FreshworksWidget) {
      window.FreshworksWidget('hide', 'launcher');
    }
  },
  isLoaded: () => !!window.FreshworksWidget,
  show: () => {
    const pageName = window.location.pathname.split('/')[1];
    const page = pageName ? `${pageName}Page` : 'homePage';
    Mixpanel.track('Support - Clicked', { page });
    if (window.FreshworksWidget) {
      window.FreshworksWidget('open');
    }
  },
};

export default freshworksActions;
