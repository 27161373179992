import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import _get from 'lodash/get';
import { makeSelectAuth, makeSelectProfile } from 'containers/Auth/selectors';
import { logout } from 'containers/Auth/actions';
import { isEmbedded } from 'utils/embedded';
import { makeSelectClientDetails } from 'containers/Main/selectors';

/**
 * This hook is used to check if Firebase refresh token should be expired based
 * on "sessionTimeout" (in days) field and force logout.
 */
const useTokenTimeout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const profile = useSelector(makeSelectProfile());
  const auth = useSelector(makeSelectAuth());
  const clientDetails = useSelector(makeSelectClientDetails());

  const profileIsLoaded = profile.isLoaded;
  const isAdmin =
    ['admin', 'contentAdmin'].includes(profile.role) && !isEmbedded();
  const clientSessionTimeout = _get(
    clientDetails,
    'metadata.sessionTimeout',
    0,
  );

  const doLogout = () =>
    dispatch(logout({ force: true, action: 'token-timed-out' }));

  const lastLoginAt = _get(auth, 'lastLoginAt');
  useEffect(() => {
    const canLogout =
      profileIsLoaded && !isAdmin && lastLoginAt && clientSessionTimeout > 0;
    if (!canLogout) return;

    const now = Date.now();
    const diff = now - Number(lastLoginAt);

    const sessionInDays = moment.duration(diff, 'milliseconds').asDays();
    if (sessionInDays >= clientSessionTimeout) {
      doLogout();
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname,
    lastLoginAt,
    clientSessionTimeout,
    profileIsLoaded,
    isAdmin,
  ]);
};

export default useTokenTimeout;
