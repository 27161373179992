import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ClassNames from 'classnames';

const styles = () => ({
  resourceName: {
    display: '-webkit-box',
    margin: '0 auto',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 42 /* Fallback for non-webkit */,
  },
  longName: {
    '-webkit-line-clamp': '4',
    height: 80,
    marginBottom: 0,
  },
  link: {
    textDecoration: 'none',
  },
});
/* eslint-disable */
function Title({ classes, title, type }) {
  const leftAlign = ['news', 'card'].includes(type);
  return (
    <Grid item xs={12}>
      <div className={classes.link}>
        <Typography
          variant="h6"
          align={leftAlign ? 'left' : 'center'}
          color="textPrimary"
          className={ClassNames(
            classes.resourceName,
            type === 'news' ? classes.longName : undefined,
          )}
        >
          {title}
        </Typography>
      </div>
    </Grid>
  );
}

Title.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default withStyles(styles)(Title);
