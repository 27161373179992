import { all, take, takeLatest, call, put, select } from 'redux-saga/effects';
import { getFirebase } from 'react-redux-firebase';
import { push, replace } from 'connected-react-router';
import update from 'immutability-helper';
import { maybeContentfulReviewStatus, post } from 'utils/api';
import client from 'utils/contentfulService';
import { getLocalData } from 'utils/localDataStore';
import Config from 'utils/getEnvConfig';
import Mixpanel from 'utils/mixpanelService';
import { getAgeFromBirthday, isBot } from 'utils/stringUtils';
import _chunk from 'lodash/chunk';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _uniqBy from 'lodash/uniqBy';
import _findKey from 'lodash/findKey';
import { getTakenAssessments } from 'containers/Assessments/actions';
import { GET_TAKEN_ASSESSMENTS_RESULT } from 'containers/Assessments/constants';
import { toast } from 'react-toastify';
import { makeSelectTakenAssessments } from 'containers/Assessments/selectors';
import { filterAssessmentByRequiredTags } from 'containers/Assessments/utils';
import { storeItemForSignIn, setAuthModalState } from 'containers/Auth/actions';
import {
  makeSelectAnonymousId,
  makeSelectAuth,
  makeSelectProfile,
} from 'containers/Auth/selectors';
import {
  setNPSVisibility,
  showPersonalEmailModal,
} from 'containers/Main/actions';
import {
  GET_CLIENT_DETAILS_FAIL,
  GET_CLIENT_DETAILS_SUCCESS,
} from 'containers/Main/constants';
import {
  makeSelectAICCUser,
  makeSelectClientDetails,
  makeSelectClientDetailsFetching,
  makeSelectSiteCopy,
  makeSelectFirestoreStatus,
  makeSelectClinicMode,
} from 'containers/Main/selectors';
import {
  getContentfulRatingQuery,
  // getContentfulTopicsQuery,
  // getTopicsIdQuery,
  getContentfulLocaleFilter,
} from 'containers/Main/utils';
import {
  filterClientExclusiveResources,
  filterHandpickedResourceByCutoff,
} from 'containers/Resources/utils';
import { DEFAULT_CONFIG } from 'components/NPSSurvey/constants';
import { getEmbeddedContainerUrl, getSubBrand } from 'utils/embedded';
import { getSeriesAssessmentTrend } from 'containers/Series/utils';
import {
  getEmailVerificationEnabled,
  isEmailVerified,
} from 'components/Hooks/useIsEmailVerified';
import cachedMd5 from 'utils/cachedMd5';
import {
  getAssessment,
  getAssessmentSuccess,
  getAssessmentFail,
  getAnswersSuccess,
  getAnswersFail,
  putAssessmentResult,
  getAssessmentResultSuccess,
  getAssessmentResultFail,
  processing,
  answersProcessing,
  getAssessmentReportResult,
  sendAssessmentResultsByEmailResult,
  setTakenInSession,
  moveAnonymousAssessmentsResult,
  setAnswersSource,
  getSeriesAssessmentResultSuccess,
} from './actions';
import {
  GET_ASSESSMENT,
  GET_ASSESSMENT_SUCCESS,
  GET_ASSESSMENT_FAIL,
  GET_ANSWERS,
  GET_ANSWERS_BY_ACCESS_CODE,
  GET_RESULTS_BY_ADMIN_CODE,
  PUT_ASSESSMENT,
  GET_ASSESSMENT_RESULT,
  GET_ASSESSMENT_REPORT,
  SEND_ASSESSMENT_RESULTS_BY_EMAIL,
  MOVE_ANONYMOUS_ASSESSMENTS,
  GET_ANSWERS_WITH_RESULTS,
} from './constants';
import { makeSelectAssessment, makeSelectTakenInSession } from './selectors';
import { getHashedUserId } from './utils';

const cache = {};

const {
  LAMBDA: { ASSESSMENT, ASSESSMENT_ADMIN_SCORINGS },
} = Config;

const assessmentHeader = {
  headers: {
    'x-api-key': ASSESSMENT.API_KEY,
  },
};
function* getAssessmentSaga({ payload }) {
  try {
    const slug = payload;
    yield put(processing(true));
    const { pathname } = window.location;

    const siteConfig = yield select(state => state.main.siteConfig);
    const { config } =
      siteConfig.find(({ title }) => title === 'Redirect Slugs') || {};
    const redirectId = _findKey(config, ({ oldSlugs }) =>
      oldSlugs.includes(slug),
    );

    const contentfulRatingQuery = yield call(getContentfulRatingQuery);
    const localeFilters = yield call(getContentfulLocaleFilter);
    const response = yield call(() =>
      client.getEntries({
        content_type: 'assessments',
        ...(redirectId ? { 'sys.id': redirectId } : { 'fields.slug': slug }),
        include: 3,
        ...maybeContentfulReviewStatus(),
        ...contentfulRatingQuery,
        ...localeFilters,
      }),
    );
    let filteredItems = yield call(
      filterAssessmentByRequiredTags,
      response.items,
    );
    const clientDetailsFetching = yield select(
      makeSelectClientDetailsFetching(),
    );
    if (clientDetailsFetching) {
      yield take([GET_CLIENT_DETAILS_SUCCESS, GET_CLIENT_DETAILS_FAIL]);
    }
    const clientDetails = yield select(makeSelectClientDetails());
    if (!_isEmpty(clientDetails)) {
      filteredItems = filterClientExclusiveResources({
        resources: filteredItems,
        clientShortName: _get(clientDetails, 'shortName'),
        clientGroupShortName: _get(clientDetails, 'clientGroup.shortName'),
      });
    }

    const translationLinkFields = _get(
      filteredItems,
      '0.fields.translationLink.fields',
      null,
    );
    const updatedItems = translationLinkFields
      ? filteredItems.map(item => ({
          ...item,
          fields: {
            ...item.fields,
            ...translationLinkFields,
          },
        }))
      : filteredItems;

    yield put(
      getAssessmentSuccess({
        ...response,
        items: updatedItems,
      }),
    );
    if (redirectId) {
      const assessmentSlug = _get(response, 'items.0.fields.slug');
      yield put(replace(pathname.replace(slug, assessmentSlug)));
    }
  } catch (error) {
    yield put(getAssessmentFail(error));
  } finally {
    yield put(processing(false));
  }
}

function* getAnswersSaga({ payload }) {
  try {
    yield put(answersProcessing(true));
    yield put(setAnswersSource('account'));

    const { slug } = payload;

    const clientDetails = yield select(makeSelectClientDetails());
    const isAICC = _get(clientDetails, 'aicc', false);

    let userID;
    let collection;
    let isSignedIn = false;
    if (isAICC) {
      const aiccUser = yield select(makeSelectAICCUser());
      userID = _get(aiccUser, 'user_id');
      collection = 'anonymous_assessments';
      isSignedIn = true;
    } else {
      try {
        userID = yield getFirebase().auth().currentUser.uid;
        collection = 'user_resources';
        isSignedIn = true;
      } catch (e) {
        const sessionAnonymousId = yield select(makeSelectAnonymousId());
        userID = getLocalData('userIdTemp') || sessionAnonymousId;
        collection = 'anonymous_assessments';
      }
    }

    const takenInSession = yield select(makeSelectTakenInSession());
    if (!isSignedIn && !takenInSession.includes(slug)) {
      throw new Error();
    }

    const snapshot = yield getFirebase()
      .firestore()
      .collection(collection)
      .doc(userID)
      .collection('taken_assessments')
      .where('assessment.slug', '==', slug)
      .orderBy('timestamp', 'desc')
      .limit(1)
      .get();
    const takenAssessment = !snapshot.empty && snapshot.docs[0].data();

    if (takenAssessment && takenAssessment.result) {
      yield put(getAnswersSuccess(takenAssessment));
    } else {
      yield put(push(`/assessments/${payload.slug}`));
      yield put(getAnswersFail());
    }
  } catch (error) {
    yield put(push(`/assessments/${payload.slug}`));
    yield put(getAnswersFail(error));
  } finally {
    yield put(answersProcessing(false));
  }
}

function* getAnswersByAccessCodeSaga({ payload }) {
  try {
    yield put(answersProcessing(true));

    const { accessCode, url, view } = payload;

    yield put(
      setAnswersSource(!_isNil(view) ? 'homeAccessCode' : 'accessCode'),
    );

    const snapshot = yield getFirebase()
      .firestore()
      .collection('assessment_reports')
      .where('accessCode', '==', accessCode.toUpperCase())
      .orderBy('timestamp', 'desc')
      .limit(1)
      .get();
    const report = !snapshot.empty && snapshot.docs[0].data();
    if (report) {
      const assessment = yield select(makeSelectAssessment());
      if (_get(assessment, 'fields.slug') !== report.assessment.slug) {
        yield put(getAssessment(report.assessment.slug));
        yield take([GET_ASSESSMENT_SUCCESS, GET_ASSESSMENT_FAIL]);
      }
      yield put(getAnswersSuccess(report));
      if (url) {
        Mixpanel.track('Home Page Referral - Correct Code', {
          type: view,
        });
      }

      const { pathname } = window.location;
      if (
        !pathname.startsWith(
          `/assessments/${report.assessment.slug}/results`,
        ) &&
        !pathname.startsWith(`/assessments/clinicalresults`)
      ) {
        if (url === '/results') {
          yield put(push(`/assessments/${report.assessment.slug}${url}`));
        } else if (url === '/clinical/results' || url === '/clinicalresults') {
          yield put(push(`/assessments/clinicalresults/${report.accessCode}`));
        }
      }
    } else {
      if (url) {
        Mixpanel.track('Home Page Referral - Incorrect Code', {
          type: view,
        });
      }
      const splitPathname = window.origin.pathname.split('/');
      const assessmentsIndex = splitPathname.indexOf('assessments');
      const slug = splitPathname[assessmentsIndex + 1];
      if (slug) yield put(push(`/assessments/${slug}`));
      yield put(getAnswersFail(true));
    }
  } catch (error) {
    const splitPathname = window.origin.pathname.split('/');
    const assessmentsIndex = splitPathname.indexOf('assessments');
    const slug = splitPathname[assessmentsIndex + 1];
    if (slug) yield put(push(`/assessments/${slug}`));
    yield put(getAnswersFail(error));
  } finally {
    yield put(answersProcessing(false));
  }
}

function* getResultsByAdminCodeSaga({ payload }) {
  try {
    yield put(processing(true));
    yield put(setAnswersSource('adminCode'));

    const { adminCode } = payload;

    const snapshot = yield getFirebase()
      .firestore()
      .collection('assessment_test_data')
      .where('code', '==', adminCode.toUpperCase())
      .get();
    const data = snapshot.empty && snapshot.docs[0].data();

    if (data) {
      const assessment = yield select(makeSelectAssessment());
      if (_get(assessment, 'items.0.fields.slug') !== data.assessment) {
        yield put(getAssessment(data.assessment));
        yield take([GET_ASSESSMENT_SUCCESS, GET_ASSESSMENT_FAIL]);
      }
      const scoringsPayload = {
        slug: data.assessment,
      };
      if (data.overallScoring) {
        scoringsPayload.overallScoring = data.overallScoring;
      }
      if (data.regularScoring) {
        scoringsPayload.regularScoring = data.regularScoring;
      }
      if (data.customAnswers) {
        scoringsPayload.answers = data.customAnswers;
      }
      const scorings = yield call(
        post,
        ASSESSMENT_ADMIN_SCORINGS.URL,
        {
          slug: data.assessment,
          ...scoringsPayload,
        },
        assessmentHeader,
      );
      const { ids } = getAssessmentResultsLinks(
        _get(scorings, 'data.resultLinks'),
      );
      const requests = _chunk(ids, 8);
      const localeFilters = yield call(getContentfulLocaleFilter);
      const responses = yield all(
        requests.map(chunk =>
          client.getEntries({
            content_type: 'assessmentResults',
            'sys.id[in]': chunk.join(','),
            include: 3,
            ...localeFilters,
          }),
        ),
      );
      const clientDetails = yield select(makeSelectClientDetails());

      const response = parseAssessmentResultsResponse(
        responses,
        clientDetails,
        ids,
      );

      yield put(
        getAssessmentResultSuccess({
          scoringsObject: scorings.data,
          results: response,
          customAnswers: {
            result: data.customAnswers,
            overallScoring: data.overallScoring,
            scoring: data.regularScoring,
            assessment: {
              slug: data.assessment,
            },
          },
        }),
      );
    } else yield put(getAnswersFail(true));
  } catch (error) {
    yield put(getAnswersFail(error));
  } finally {
    yield put(processing(false));
  }
}

const apiCallWithCache = async ({ promise, url, body }) => {
  const cachedUrl = cachedMd5({ url, body });
  const cached = cache[cachedUrl];

  if (cached) {
    return cached;
  }

  // eslint-disable-next-line no-useless-catch
  try {
    const result = await promise();
    cache[cachedUrl] = result;

    return cache[cachedUrl];
  } catch (e) {
    throw e;
  }
};

function* getAssessmentResultSaga(action) {
  try {
    yield put(processing(true));
    if (isBot()) throw new Error('Do not call Algolia for Bot requests');
    const latestAction = yield select(
      state => state.assessment.latestAssessmentResultAction,
    );
    if (JSON.stringify(action) !== latestAction) {
      const {
        data: { scoringsObject, results },
      } = yield call(fetchAssessmentScoring, {
        payload: action.payload,
      });

      yield put(
        getAssessmentResultSuccess({
          scoringsObject,
          results,
          actionPayload: JSON.stringify(action),
        }),
      );
    }
  } catch (error) {
    yield put(getAssessmentResultFail(error));
  } finally {
    yield put(processing(false));
  }
}

function getAssessmentResultsLinks(resultLinks) {
  const ids = [];
  const overallLink = _get(resultLinks, 'overall', null);
  const scoringLinks = _get(resultLinks, 'scoring', []);
  if (!_isEmpty(overallLink)) ids.push(overallLink);
  if (!_isEmpty(scoringLinks)) ids.push(...scoringLinks);

  return {
    ids,
    overallLink,
  };
}

function parseAssessmentResultsResponse(responses, clientDetails, ids) {
  let items = [];
  let total = 0;
  responses.forEach(res => {
    items = items.concat(res.items);
    total += res.total;
  });
  const response = {
    ...responses[0],
    items,
    total,
  };

  const averageRatingCutoff = _get(clientDetails, 'averageRatingCutoff');
  const expertRatingCutoff = _get(clientDetails, 'expertRatingCutoff');
  const userRatingCutoff = _get(clientDetails, 'userRatingCutoff');

  const sortedItems = ids.map(id => {
    const item = response.items.find(el => el.sys.id === id);
    if (item) {
      if (!_isEmpty(_get(item, 'fields.handPickedResources', []))) {
        item.fields.handPickedResources = item.fields.handPickedResources.filter(
          resource =>
            filterHandpickedResourceByCutoff({
              resource,
              averageRatingCutoff,
              userRatingCutoff,
              expertRatingCutoff,
            }),
        );
      }

      if (!_isEmpty(_get(item, 'fields.lists'))) {
        item.fields.lists = item.fields.lists.map(list => {
          if (!_isEmpty(_get(item, 'fields.resources', []))) {
            const filteredResources = list.fields.resources.filter(resource =>
              filterHandpickedResourceByCutoff({
                resource,
                averageRatingCutoff,
                userRatingCutoff,
                expertRatingCutoff,
              }),
            );
            return {
              ...list,
              fields: {
                ...list.fields,
                resources: filteredResources,
              },
            };
          }
          return list;
        });
      }
    }
    return item;
  });

  response.items = sortedItems;
  return response;
}

function* putAssessmentSaga({ payload }) {
  try {
    yield put(processing(true));
    yield put(storeItemForSignIn({}));

    const clientDetails = yield select(makeSelectClientDetails());
    const firestoreStatus = yield select(makeSelectFirestoreStatus());
    const siteConfig = (yield select(state => state.main.siteConfig)).find(
      item => item.title === 'Assessment Results A/B Test',
    );
    const isAICC = _get(clientDetails, 'aicc', false);
    const clinicMode = yield select(makeSelectClinicMode());

    const { isAnonymous, sendEmail } = payload;

    let userID;
    const userIdTemp = getLocalData('userIdTemp');
    if (isAnonymous) {
      if (isAICC) {
        const aiccUser = yield select(makeSelectAICCUser());
        userID = _get(aiccUser, 'user_id');
      } else {
        const sessionAnonymousId = yield select(makeSelectAnonymousId());
        userID = clinicMode
          ? sessionAnonymousId || userIdTemp
          : userIdTemp || sessionAnonymousId;
      }
    } else {
      try {
        userID = yield getFirebase().auth().currentUser.uid;
      } catch (error) {
        yield put(storeItemForSignIn({ type: 'assessmentResult', payload }));
        yield put(putAssessmentResult({ error: true }));
        yield put(
          setAuthModalState({
            show: true,
            type: sendEmail
              ? 'send-assessment-email'
              : 'store-assessment-result',
          }),
        );
        yield put(processing(false));
      }
    }

    const siteCopy = yield select(makeSelectSiteCopy());

    const signUpSiteCopy = siteCopy.find(item => item.slug === 'sign-up');

    const auth = yield select(makeSelectAuth());
    const isEmailVerificationEnabled = getEmailVerificationEnabled(
      _get(
        (yield select(state => state.main.siteConfig)).find(
          item => item.title === 'Features',
        ),
        'config.emailValidation',
        {},
      ),
      _get(clientDetails, 'shortName'),
      _get(clientDetails, 'clientGroup.shortName'),
    );
    const isVerified = isEmailVerified(auth);

    if (
      isEmailVerificationEnabled &&
      !isAnonymous &&
      !isVerified &&
      sendEmail
    ) {
      toast(
        _get(
          signUpSiteCopy,
          'pageCopy.emailValidationAssessmentEmailOnSubmit',
          'Please confirm your email before receiving your results email.',
        ),
        {
          type: 'warning',
        },
      );
    } else {
      const localeFilters = yield call(getContentfulLocaleFilter);
      const scorings = yield call(
        post,
        ASSESSMENT.URL,
        {
          slug: payload.assessment.slug,
          answers: payload.result,
          locale: localeFilters.locale,
        },
        assessmentHeader,
      );
      const {
        resultLinks: resultID,
        overallScoring,
        scoring,
        overallLabel,
        labels,
        contextData: context,
      } = scorings.data;
      const { location, assessmentPath, ...finalPayload } = payload;
      const { retake, ...data } = finalPayload;

      // TODO: Remove when month/year approach spreads
      const {
        assessment, // both objects
        cobrand, // only report
        dob, // both objects
        birthdayMonthYear, // both objects
        industry, // only report
        gender, // both objects
        genderOther, // both objects
        genderLGBTQp, // both objects
        result,
        insurance, // both objects
        randomIdentifier, // only report
        // overallScoring, // both objects
        // scoring, // both objects
        // overallLabel, // both objects
        // labels, // both objects
        // context, // only report
        timestamp, // both objects
        startTimestamp, // only report
        resumed, // only report
        profileSamlData, // only report
      } = data;

      let age = null;
      if (birthdayMonthYear) {
        age = getAgeFromBirthday(birthdayMonthYear);
      }

      // Split data for assessment_reports and user_resources/{user_id}/taken_assessments
      const profile = yield select(makeSelectProfile());
      const hashedId = getHashedUserId(userID);

      const clientData = {
        subBrand: getSubBrand(),
        containerUrl: getEmbeddedContainerUrl(),
        ..._get(profile, 'samlData', {}),
      };

      const reportData = {
        id: hashedId,
        assessment,
        cobrand,
        dob,
        birthdayMonthYear,
        age,
        gender,
        genderOther,
        genderLGBTQp,
        industry,
        insurance,
        isAnonymous,
        isAICC,
        result,
        context,
        startTimestamp,
        timestamp,
        resumed,
        overallScoring,
        scoring,
        overallLabel,
        labels,
        clientData,
        randomIdentifier,
        ...profileSamlData,
      };
      const takenAssessmentData = {
        assessment,
        dob,
        birthdayMonthYear,
        age,
        gender,
        genderOther,
        genderLGBTQp,
        timestamp,
        isAICC,
        overallScoring,
        scoring,
        overallLabel,
        labels,
        result,
        resultID,
      };
      if (
        _get(siteConfig, 'config.slugs', []).includes(_get(assessment, 'slug'))
      ) {
        const variant = Math.random() < 0.5 ? 'variantA' : 'variantB';
        takenAssessmentData.variant = variant;
      }

      const takenInSession = yield select(makeSelectTakenInSession());
      const newTakenInSession = [...takenInSession, payload.assessment.slug];

      if (!firestoreStatus) {
        yield put(setTakenInSession(newTakenInSession));
        yield put(getAnswersSuccess(takenAssessmentData));
        yield put(push(`/assessments/${payload.assessment.slug}/results`));
      } else if (!_isNil(userID)) {
        const collection = isAnonymous
          ? 'anonymous_assessments'
          : 'user_resources';

        if (!isAnonymous) {
          try {
            const profileData = {};
            const mixpanelProfileData = {};
            if (
              birthdayMonthYear &&
              (!profile.birthdayMonthYear ||
                profile.birthdayMonthYear !== birthdayMonthYear)
            ) {
              profileData.birthdayMonthYear = birthdayMonthYear;
              mixpanelProfileData.birthdayMonthYear = birthdayMonthYear;
            }
            if (gender && (!profile.gender || profile.gender !== gender)) {
              profileData.gender = gender;
              mixpanelProfileData.gender = gender;
            }
            if (
              genderOther &&
              (!profile.genderOther || profile.genderOther !== genderOther)
            ) {
              profileData.genderOther = genderOther;
              mixpanelProfileData.genderOther = genderOther;
            }
            if (
              genderLGBTQp &&
              (!profile.genderLGBTQp || profile.genderLGBTQp !== genderLGBTQp)
            ) {
              profileData.genderLGBTQp = genderLGBTQp;
              mixpanelProfileData.genderLGBTQp = genderLGBTQp;
            }
            if (
              industry &&
              (!profile.industry || profile.industry !== industry)
            ) {
              profileData.industry = industry;
              mixpanelProfileData.industry = industry;
            }
            if (
              insurance &&
              (!profile.insurance || profile.insurance !== insurance)
            ) {
              profileData.insurance = insurance;
              mixpanelProfileData.insurance = insurance;
            }

            if (!_isEmpty(profileData)) {
              yield getFirebase().updateProfile(profileData);
              Mixpanel.track('Update account - Successful');
              Mixpanel.people.set(mixpanelProfileData);
            }
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }

        // Begin batch
        const reportDoc = yield getFirebase()
          .firestore()
          .collection('assessment_reports')
          .doc();
        const takenAssessmentDoc = yield getFirebase()
          .firestore()
          .collection(collection)
          .doc(userID)
          .collection('taken_assessments')
          .doc();
        takenAssessmentData.report = reportDoc.id;
        const batch = getFirebase()
          .firestore()
          .batch();
        batch.set(reportDoc, reportData);
        batch.set(takenAssessmentDoc, takenAssessmentData);
        yield batch.commit();
        // End of batch

        yield put(setTakenInSession(newTakenInSession));
        yield put(getTakenAssessments());
        yield take(GET_TAKEN_ASSESSMENTS_RESULT);
        const takenAssessments = yield select(makeSelectTakenAssessments());

        const npsSiteCopy = _get(
          siteCopy.find(el => el.slug === 'nps'),
          'pageCopy',
          {},
        );
        const npsClient = _get(clientDetails, 'metadata.nps', {});
        const finalNPS = update(npsSiteCopy, {
          $merge: npsClient,
        });

        const numTakenAssessments = takenAssessments.length;
        const lastNPS = profile.isEmpty
          ? getLocalData('npsDate') || 0
          : _get(profile, 'npsDate', 0);
        const assessmentCount = _get(
          finalNPS,
          'config.assessmentCount',
          DEFAULT_CONFIG.assessmentCount,
        );
        const daysForRetake = _get(
          finalNPS,
          'config.daysForRetake',
          DEFAULT_CONFIG.daysForRetake,
        );

        if (
          !_get(profile, 'email', '').includes('@crediblemind.com') &&
          numTakenAssessments > assessmentCount &&
          Date.now() > lastNPS + daysForRetake * 24 * 60 * 60 * 1000
        ) {
          yield put(
            setNPSVisibility({
              visibility: true,
              reason: 'assessment-taken',
            }),
          );
        }

        try {
          const firebase = getFirebase();
          const ref = firebase
            .firestore()
            .collection('assessment_temporary_answers')
            .doc(userID);
          yield ref.update({
            [assessment.slug]: firebase.firestore.FieldValue.delete(),
          });
          // eslint-disable-next-line no-empty
        } catch (e) {}

        // yield put(getAssessmentResult(data.resultID));
        const answersSource = window.location.pathname.startsWith(
          '/assessments/',
        )
          ? 'session-assessments'
          : 'session-courses';
        yield put(getAnswersSuccess(takenAssessmentData));
        yield put(setAnswersSource(answersSource));
        yield put(push(`${payload.assessmentPath}/results`));

        // Send Assessment Results
        if (!isAnonymous && sendEmail) {
          const firebase = getFirebase();
          const unsubscribe = firebase
            .firestore()
            .collection('assessment_reports')
            .doc(reportDoc.id)
            .onSnapshot(doc => {
              const { accessCode } = doc.data();
              if (accessCode) {
                const mailPayload = {
                  assessmentId: assessment.assessmentID,
                  email: profile.email,
                  labels,
                  overallLabel,
                  overallScoring,
                  scoring,
                  overallResultId: resultID.overall,
                  resultsIds: resultID.scoring,
                  domain: window.location.hostname,
                  brand: getLocalData('brand') || 'none',
                  accessCode,
                  ...localeFilters,
                };

                post(
                  Config.LAMBDA.MANDRILL_ASSESSMENT_RESULT.URL,
                  mailPayload,
                  {
                    headers: {
                      'x-api-key':
                        Config.LAMBDA.MANDRILL_ASSESSMENT_RESULT.API_KEY,
                    },
                  },
                );
                unsubscribe();
              }
            });
        }
        // End of send Assessment Results
      }
    }
  } catch (error) {
    yield put(processing(false));
    yield put(putAssessmentResult({ error: true, errorMessage: error }));
  } finally {
    yield put(processing(false));
  }
}

function* sendAssessmentResultsByEmailSaga({ payload }) {
  try {
    yield put(storeItemForSignIn({}));
    let userID;
    try {
      userID = yield getFirebase().auth().currentUser.uid;
    } catch (error) {
      yield put(storeItemForSignIn({ type: 'send-assessment-email', payload }));
      yield put(putAssessmentResult({ error: true }));
      yield put(
        setAuthModalState({
          show: true,
          type: 'send-assessment-email',
        }),
      );
    }

    const siteCopy = (yield select(makeSelectSiteCopy())).find(
      item => item.slug === 'sign-up',
    );
    const auth = yield select(makeSelectAuth());
    const isVerified = isEmailVerified(auth);

    if (!_isNil(userID)) {
      if (!isVerified) {
        toast(
          _get(
            siteCopy,
            'pageCopy.emailValidationAssessmentEmail',
            'Please confirm your email before receiving your results email.',
          ),
          {
            type: 'warning',
          },
        );
      } else {
        const profile = yield select(makeSelectProfile());
        const clientDetails = yield select(makeSelectClientDetails());
        const localeFilters = yield call(getContentfulLocaleFilter);

        const shouldUsePersonalEmail = _get(
          clientDetails,
          'metadata.requirePersonalEmail',
          false,
        );

        const profileEmail = shouldUsePersonalEmail
          ? profile.personalEmail
          : profile.email;

        if (profileEmail) {
          yield post(
            Config.LAMBDA.MANDRILL_ASSESSMENT_RESULT.URL,
            {
              ...payload,
              ...localeFilters,
              email: profileEmail,
            },
            {
              headers: {
                'x-api-key': Config.LAMBDA.MANDRILL_ASSESSMENT_RESULT.API_KEY,
              },
            },
          );
          yield put(sendAssessmentResultsByEmailResult({ error: false }));
        } else if (shouldUsePersonalEmail) {
          yield put(showPersonalEmailModal({ type: 'request' }));
        } else {
          yield put(sendAssessmentResultsByEmailResult({ error: true }));
        }
      }
    }
  } catch (error) {
    yield put(
      sendAssessmentResultsByEmailResult({ error: true, errorMessage: error }),
    );
  }
}

function* getAssessmentReportSaga({ payload }) {
  try {
    yield put(processing(true));
    const profile = yield select(makeSelectProfile());
    if (profile.role === 'admin') {
      const reportDoc = yield getFirebase()
        .firestore()
        .collection('assessment_reports')
        .doc(payload)
        .get();
      const data = reportDoc.data();
      yield put(getAssessmentReportResult(data));
      yield put(processing(false));
    } else {
      yield put(processing(false));
    }
  } catch (error) {
    yield put(processing(false));
  }
}

function* moveAnonymousAssessmentsSaga({ payload = false }) {
  try {
    yield put(processing(true));
    yield put(storeItemForSignIn({}));

    let userID;
    try {
      userID = yield getFirebase().auth().currentUser.uid;
    } catch (error) {
      yield put(storeItemForSignIn({ type: 'move-assessments', payload }));
      yield put(moveAnonymousAssessmentsResult({ error: true }));
      yield put(
        setAuthModalState({
          show: true,
          type: 'move-assessments',
          certificate: payload,
        }),
      );
      yield put(processing(false));
    }
    if (!_isNil(userID)) {
      const sessionAnonymousId = yield select(makeSelectAnonymousId());
      const anonymousId = getLocalData('userIdTemp') || sessionAnonymousId;
      const takenInSession = yield select(makeSelectTakenInSession());

      const takenAssessmentsSnapshot = yield getFirebase()
        .firestore()
        .collection('anonymous_assessments')
        .doc(anonymousId)
        .collection('taken_assessments')
        .where('assessment.slug', 'in', takenInSession)
        .orderBy('timestamp', 'desc')
        .get();
      const takenAssessments = _uniqBy(
        takenAssessmentsSnapshot.docs.map(doc => ({
          docId: doc.id,
          ...doc.data(),
        })),
        'assessment.slug',
      );

      const batch = getFirebase()
        .firestore()
        .batch();
      for (let i = 0; i < takenAssessments.length; i += 1) {
        const { docId, ...data } = takenAssessments[i];
        const anonymousAssessmentDoc = yield getFirebase()
          .firestore()
          .collection('anonymous_assessments')
          .doc(anonymousId)
          .collection('taken_assessments')
          .doc(takenAssessments[i].docId);
        const userAssessmentDoc = yield getFirebase()
          .firestore()
          .collection('user_resources')
          .doc(userID)
          .collection('taken_assessments')
          .doc();
        const reportDoc = yield getFirebase()
          .firestore()
          .collection('assessment_reports')
          .doc(takenAssessments[i].report);
        const newReportData = {
          id: getHashedUserId(userID),
          isAnonymous: false,
        };
        batch.delete(anonymousAssessmentDoc);
        batch.set(userAssessmentDoc, {
          ...data,
          context: { ...data.context, moved: true },
        });
        batch.set(reportDoc, newReportData, { merge: true });
      }
      yield batch.commit();

      yield put(getTakenAssessments());
    }
  } catch (error) {
    yield put(processing(false));
    yield put(
      moveAnonymousAssessmentsResult({ error: true, errorMessage: error }),
    );
  }
}

function* fetchAssessmentScoring({ payload }) {
  const localeFilters = yield call(getContentfulLocaleFilter);
  const { answers, slug } = payload;

  const scoringsPayload = {
    slug,
    answers,
    locale: localeFilters.locale,
  };

  const scorings = yield call(apiCallWithCache, {
    promise: () => post(ASSESSMENT.URL, scoringsPayload, assessmentHeader),
    url: ASSESSMENT.URL,
    body: scoringsPayload,
  });

  const { ids, overallLink } = getAssessmentResultsLinks(
    _get(scorings, 'data.resultLinks'),
  );
  const requests = _chunk(ids, 8);
  const responses = yield all(
    requests.map(chunk => {
      const assessmentResultPayload = {
        select:
          'sys.id,fields.description,fields.descriptionVariantB,fields.handPickedResources,fields.lists,fields.audienceType,fields.tags,fields.topics,fields.listPosition,fields.show',
        content_type: 'assessmentResults',
        'sys.id[in]': chunk.join(','),
        include: 3,
        ...localeFilters,
      };

      return apiCallWithCache({
        promise: () => client.getEntries(assessmentResultPayload),
        url: 'contentful',
        body: assessmentResultPayload,
      });
    }),
  );

  const clientDetails = yield select(makeSelectClientDetails());
  const results = parseAssessmentResultsResponse(responses, clientDetails, ids);

  return { data: { results, scoringsObject: scorings.data }, overallLink };
}

function* getAnswersWithResultsSaga({ payload: { accessCodes, item } }) {
  yield put(answersProcessing(true));

  try {
    const reportDocs = yield getFirebase()
      .firestore()
      .collection('assessment_reports')
      .where('accessCode', 'in', accessCodes)
      .orderBy('timestamp', 'desc')
      .limit(2)
      .get();
    const reports = reportDocs.docs.map(doc => doc.data());
    const lastReport = reports[0];

    const data = yield all(
      reports.map(report =>
        call(fetchAssessmentScoring, {
          payload: {
            slug: item.fields.slug,
            answers: _get(report, 'result'),
          },
        }),
      ),
    );

    const assessmentScorings = data.map(scoring => scoring.data);

    const trend = getSeriesAssessmentTrend(assessmentScorings);
    const assessmentResult = assessmentScorings[0];
    if (trend) {
      assessmentResult.scoringsObject.trend = trend;
    }

    yield put(
      getSeriesAssessmentResultSuccess({
        assessmentResult,
        answers: lastReport,
      }),
    );
    yield put(setAnswersSource('session-courses'));
  } catch {
    yield put(answersProcessing(false));
  } finally {
    yield put(answersProcessing(false));
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(GET_ASSESSMENT, getAssessmentSaga);
  yield takeLatest(GET_ANSWERS, getAnswersSaga);
  yield takeLatest(GET_ANSWERS_BY_ACCESS_CODE, getAnswersByAccessCodeSaga);
  yield takeLatest(GET_RESULTS_BY_ADMIN_CODE, getResultsByAdminCodeSaga);
  yield takeLatest(GET_ASSESSMENT_RESULT, getAssessmentResultSaga);
  yield takeLatest(PUT_ASSESSMENT, putAssessmentSaga);
  yield takeLatest(GET_ASSESSMENT_REPORT, getAssessmentReportSaga);
  yield takeLatest(
    SEND_ASSESSMENT_RESULTS_BY_EMAIL,
    sendAssessmentResultsByEmailSaga,
  );
  yield takeLatest(MOVE_ANONYMOUS_ASSESSMENTS, moveAnonymousAssessmentsSaga);
  yield takeLatest(GET_ANSWERS_WITH_RESULTS, getAnswersWithResultsSaga);
}
