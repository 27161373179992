import { withScope, captureMessage, captureException } from '@sentry/react';

export const apiCatch = error => {
  captureMessage(`API Error | ${error}`);
};

export const logError = (error, context = {}) => {
  withScope(scope => {
    scope.setExtras(context);
    captureException(error);
  });
};
