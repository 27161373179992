import {
  call,
  put,
  take,
  takeEvery,
  takeLatest,
  all,
  takeLeading,
  select,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { getFirebase } from 'react-redux-firebase';
import { getKeyFromTitle } from 'containers/EditorList/utils';
import { getResourcesIndex } from 'containers/Resources/utils';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import AlgoliaClient from 'utils/algoliaService';
import { post } from 'utils/api';
import client from 'utils/contentfulService';
import Config from 'utils/getEnvConfig';
import GqlClient from 'utils/graphqlService';
import MailChimpService from 'utils/mailchimpService';
import { setBrandSector, setClientGroup } from 'utils/mixpanelService';
import { isBot } from 'utils/stringUtils';
import { getLocalData } from 'utils/localDataStore';
import { storeItemForSignIn, setAuthModalState } from 'containers/Auth/actions';
import GetAudienceTagsRelations from './services/getAudienceTagsRelations.gql';
import GetClientDetails from './services/getClientDetails.gql';
import GetClientGroup from './services/getClientGroup.gql';
import GetReferrals from './services/getReferrals.gql';
import GetSiteConfig from './services/getSiteConfig.gql';
import GetSiteCopy from './services/getSiteCopy.gql';
import GetThemesQuery from './services/getThemes.gql';
import {
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_SUCCESS,
  SEND_MAIL,
  GET_SITE_COPY,
  GET_SITE_CONFIG,
  GET_PROGRAMS_BY_TOPIC,
  GET_THEMES,
  GET_RESOURCES_COUNT_BY_TOPIC,
  GET_CLIENT_RESOURCES,
  UPDATE_BRAND,
  GET_AUDIENCE_TAGS_RELATIONS,
  SAVE_REMIND_ME_LATER_RESOURCE,
  GET_REMIND_ME_LATER_RESOURCE,
  GET_PAGE_INTROS,
  GET_REFERRALS,
  GET_LOCALES,
  GET_AICC_USER,
  GET_CONTENT_BLOCKS,
  GET_HOME_REFERRALS,
} from './constants';
import {
  processing,
  sendMailProcessing,
  getClientDetailsSuccess,
  getClientDetailsFail,
  sendMailSuccess,
  getSiteConfigSuccess,
  getSiteCopySuccess,
  getProgramsByTopicSuccess,
  getThemesSuccess,
  getResourcesCountByTopicSuccess,
  getClientResourcesSuccess,
  clientDetailsFetching,
  setAudienceTagsRelations,
  saveRemindMeLaterResourceResult,
  getRemindMeLaterResourceResult,
  getPageIntrosResult,
  getReferralsResult,
  setLocales,
  setAICCUser,
  setContentBlocks,
  getHomeReferralsResult,
} from './actions';
import {
  makeSelectClientDetails,
  makeSelectLanguage,
  makeSelectSiteCopy,
} from './selectors';
import {
  getContentfulLocaleFilter,
  parseClientDetails,
  isReferenceAssessmentAccepted,
} from './utils';

function* getClientDetailsSaga({ payload }) {
  try {
    yield put(clientDetailsFetching(true));
    const localeFilters = yield call(getContentfulLocaleFilter);
    const language = yield select(makeSelectLanguage());
    const { data: clientCollectionData } = yield call(() =>
      GqlClient.query({
        query: GetClientDetails,
        variables: {
          shortName: payload.toLowerCase(),
          ...localeFilters,
        },
      }),
    );
    const clientData = _get(clientCollectionData, 'clientCollection.items.0');
    const clientGroupId = _get(clientData, 'clientGroup.sys.id');
    const { data: clientGroupData } = yield call(() =>
      clientGroupId
        ? GqlClient.query({
            query: GetClientGroup,
            variables: {
              id: clientGroupId,
              ...localeFilters,
            },
          })
        : { data: null },
    );

    if (!_isEmpty(clientData)) {
      const clientWithClientGroup = {
        ...clientData,
        ...clientGroupData,
      };
      const clientDetails = parseClientDetails(clientWithClientGroup, language);
      if (_get(clientDetails, 'clientGroup.formalShortName')) {
        setClientGroup(_get(clientDetails, 'clientGroup.formalShortName'));
      }
      setBrandSector(_get(clientDetails, 'sector'));

      if (Config.ENV === 'development') {
        Sentry.setContext('client', {
          name: _get(clientDetails, 'shortName') || 'none',
        });
      }

      yield put(getClientDetailsSuccess(clientDetails));
    } else {
      yield put(getClientDetailsFail());
    }
  } catch (error) {
    throw new Error(error);
  } finally {
    yield put(clientDetailsFetching(false));
  }
}

function* sendMailSaga({ payload }) {
  try {
    const { type = 'feedback', ...data } = payload;
    const endpoint =
      type === 'contact'
        ? Config.LAMBDA.MANDRILL_CONTACT
        : Config.LAMBDA.MANDRILL_FEEDBACK;
    yield put(sendMailProcessing(true));
    yield call(
      post,
      endpoint.URL,
      {
        ...data,
      },
      {
        headers: {
          'x-api-key': endpoint.API_KEY,
        },
      },
    );
    yield put(sendMailSuccess());
  } catch (error) {
    throw new Error(error);
  } finally {
    yield put(sendMailProcessing(false));
  }
}

function* getSiteCopySaga() {
  const localeFilter = yield call(getContentfulLocaleFilter);
  const language = yield select(makeSelectLanguage());
  try {
    yield put(processing(true));

    const otherLanguageLocale =
      _get(localeFilter, 'locale') === 'en-US' ? 'es' : 'en-US';

    const [{ data }, response] = yield all([
      GqlClient.query({
        query: GetSiteCopy,
        variables: {
          ...localeFilter,
        },
      }),
      client.getEntries({
        content_type: 'siteCopy',
        'fields.slug[in]': 'resource-item-page,search',
        include: 2,
        locale: otherLanguageLocale,
      }),
    ]);
    const items = data ? _get(data, 'siteCopyCollection.items') : [];

    const [otherLanguageResourceItemPage] = response.items.filter(
      item => _get(item, 'fields.slug') === 'resource-item-page',
    );
    const [otherLanguageSearch] = response.items.filter(
      item => _get(item, 'fields.slug') === 'search',
    );
    yield put(
      getSiteCopySuccess({
        items,
        language,
        otherLanguageResourceItemPage: _get(
          otherLanguageResourceItemPage,
          'fields',
        ),
        otherLanguageSearch: _get(otherLanguageSearch, 'fields'),
      }),
    );
  } finally {
    yield put(processing(false));
  }
}

function* getSiteConfigSaga() {
  const localeFilter = yield call(getContentfulLocaleFilter);
  try {
    yield put(processing(true));
    const { data } = yield call(() =>
      GqlClient.query({
        query: GetSiteConfig,
        variables: {
          ...localeFilter,
        },
      }),
    );
    const items = data ? _get(data, 'siteConfigCollection.items') : [];
    const featuresConfig = items.find(item => item.title === 'Features')
      ?.config;
    yield put(getSiteConfigSuccess({ items, featuresConfig }));
  } finally {
    yield put(processing(false));
  }
}

function* getProgramsByTopic({ payload }) {
  try {
    yield put(processing(true));
    const localeFilters = yield call(getContentfulLocaleFilter);
    const ctfPayload = {
      content_type: 'onlineProgram',
      'fields.reviewStatus': 'Accepted',
      ...payload,
      ...localeFilters,
    };
    const response = yield call(() => client.getEntries(ctfPayload));
    yield put(getProgramsByTopicSuccess(response));
  } catch (error) {
    throw new Error(error);
  }
}

function* getThemesSaga({ payload }) {
  try {
    const clientDetails = yield select(makeSelectClientDetails());
    const localeFilters = yield call(getContentfulLocaleFilter);

    const {
      data: {
        themeCollection: { items },
      },
    } = yield call(() =>
      GqlClient.query({
        query: GetThemesQuery,
        variables: {
          title: payload,
          ...localeFilters,
        },
        skip: !payload,
      }),
    );

    const finalItems = items.filter(item => {
      if (_isEmpty(_get(item, 'clientCollection.items'))) return true;

      return !_isEmpty(
        _get(item, 'clientCollection.items', []).filter(
          el => el.slug === clientDetails.slug,
        ),
      );
    });
    yield put(getThemesSuccess(finalItems));
  } catch (error) {
    throw new Error(error);
  }
}

function* getResourcesByTopicSaga({ payload }) {
  try {
    if (isBot()) throw new Error('Do not call Algolia for Bot requests');
    const clientDetails = yield select(makeSelectClientDetails());
    const siteCopy = yield select(makeSelectSiteCopy()).find(
      item => item.slug === 'filters-translations',
    );
    const resourcesWeightings = _get(
      clientDetails,
      'metadata.algoliaResourcesWeight',
    );

    const algoliaIndex = AlgoliaClient.initIndex(
      getResourcesIndex(resourcesWeightings),
    );
    const topicFilters = `AND allTopics.slug:'${getKeyFromTitle(
      siteCopy,
      'topics',
      payload,
    )}'`;
    const response = yield call(() =>
      algoliaIndex.searchForFacetValues('type', '', {
        maxFacetHits: 100,
        filters: `reviewStatus:'Accepted' AND NOT type:'Topics' AND NOT type:'Assessments' AND NOT type:'People' ${topicFilters}`,
      }),
    );
    yield put(getResourcesCountByTopicSuccess(response.facetHits));
  } catch (error) {
    throw new Error(error);
  }
}

function* getClientResourcesSaga({ payload }) {
  const { finalSlug, isLandingPage } = payload;
  try {
    yield put(processing(true));
    const localeFilters = yield call(getContentfulLocaleFilter);
    let ctfPayload = {
      content_type: 'client',
      'fields.shortName': finalSlug,
      select: 'fields.clientResources',
      include: 2,
      ...localeFilters,
    };
    if (isLandingPage) {
      ctfPayload = {
        content_type: 'landing',
        'fields.slug': finalSlug,
        select: 'fields.clientResources',
        include: 1,
        ...localeFilters,
      };
    }
    const response = yield call(() => client.getEntries(ctfPayload));
    yield put(
      getClientResourcesSuccess(
        _get(response, 'items.0.fields.clientResources'),
      ),
    );
  } catch (error) {
    throw new Error(error);
  }
}

function* updateBrandSaga({ payload }) {
  const { brand, email } = payload;
  try {
    yield all([
      getFirebase().updateProfile({ brand }),
      call(() => MailChimpService.patchSubscriptionBrand(email)),
    ]);
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

function* getAudienceTagsRelationsSaga() {
  try {
    const audienceTagsRelations = {};
    const localeFilter = yield call(getContentfulLocaleFilter);

    const clientShortName = getLocalData('brand');
    const hasClientShortName =
      !_isEmpty(clientShortName) && clientShortName !== 'none';
    let clientDetails;
    if (hasClientShortName) {
      clientDetails = yield select(makeSelectClientDetails());
      if (_isEmpty(clientDetails)) {
        yield take(GET_CLIENT_DETAILS_SUCCESS);
        clientDetails = yield select(makeSelectClientDetails());
      }
    }

    const clientFilter = {};
    if (_isEmpty(clientDetails) && !hasClientShortName) return;
    if (!_isEmpty(clientDetails)) {
      clientFilter.shortName_in = [
        clientDetails.shortName,
        _get(clientDetails, 'clientGroup.shortName'),
      ].filter(Boolean);
    }
    const { data } = yield call(() =>
      GqlClient.query({
        query: GetAudienceTagsRelations,
        variables: {
          ...localeFilter,
          ...clientFilter,
        },
      }),
    );
    data.audienceCollection.items.forEach(item => {
      if (audienceTagsRelations[item.name]) {
        audienceTagsRelations[item.name].push({
          id: item.sys.id,
          name: item.name,
          exclusive: item.exclusive,
          exclude: _get(item, 'excludeCollection.items', []).map(el => ({
            id: _get(el, 'sys.id', ''),
            name: _get(el, 'name', ''),
          })),
          type: 'audience',
        });
      } else {
        audienceTagsRelations[item.name] = [
          {
            id: item.sys.id,
            name: item.name,
            exclusive: item.exclusive,
            exclude: _get(item, 'excludeCollection.items', []).map(el => ({
              id: _get(el, 'sys.id', ''),
              name: _get(el, 'name', ''),
            })),
            type: 'audience',
          },
        ];
      }
    });
    data.tagsCollection.items.forEach(item => {
      if (audienceTagsRelations[item.title]) {
        audienceTagsRelations[item.title].push({
          id: item.sys.id,
          title: item.title,
          exclusive: false,
          exclude: _get(item, 'excludeCollection.items', []).map(el => ({
            id: _get(el, 'sys.id', ''),
            title: _get(el, 'title', ''),
          })),
          type: 'tags',
        });
      } else {
        audienceTagsRelations[item.title] = [
          {
            id: item.sys.id,
            title: item.title,
            exclusive: false,
            exclude: _get(item, 'excludeCollection.items', []).map(el => ({
              id: _get(el, 'sys.id', ''),
              title: _get(el, 'title', ''),
            })),
            type: 'tags',
          },
        ];
      }
    });
    data.clientTagsCollection.items.forEach(item => {
      if (audienceTagsRelations[item.title]) {
        audienceTagsRelations[item.title].push({
          id: item.sys.id,
          title: item.title,
          exclusive: false,
          exclude: [],
          type: 'tags',
        });
      } else {
        audienceTagsRelations[item.title] = [
          {
            id: item.sys.id,
            title: item.title,
            exclusive: false,
            exclude: [],
            type: 'tags',
          },
        ];
      }
    });
    yield put(setAudienceTagsRelations(audienceTagsRelations));
  } catch (error) {
    throw new Error(error);
  }
}

function* getRemindMeLaterResourceSaga({ payload }) {
  try {
    const userID = yield getFirebase().auth().currentUser.uid;
    const resourceDoc = yield getFirebase()
      .firestore()
      .collection('user_resources')
      .doc(userID)
      .collection('remind_me')
      .get();

    const resource = resourceDoc.docs.filter(
      doc => doc.data().resourceId === payload.resourceId,
    )[0];
    if (resource) {
      yield put(
        getRemindMeLaterResourceResult({
          added: true,
        }),
      );
    } else {
      yield put(
        getRemindMeLaterResourceResult({
          added: false,
        }),
      );
    }
  } catch (error) {
    yield put(
      getRemindMeLaterResourceResult({
        added: false,
      }),
    );
  } finally {
    yield put(processing(false));
  }
}

function* saveRemindMeLaterResourceSaga({ payload = {} }) {
  try {
    let userID;
    yield put(saveRemindMeLaterResourceResult({}));
    const {
      sys: { id, contentType },
      fields: { name, type, slug, title },
      answer,
    } = payload;
    try {
      userID = yield getFirebase().auth().currentUser.uid;
    } catch (error) {
      if (answer !== 'no') {
        yield put(storeItemForSignIn({ type: 'remind-me', payload }));
        yield put(setAuthModalState({ show: true, type: 'remind-me' }));
      } else {
        yield put(
          saveRemindMeLaterResourceResult({
            added: true,
            error: false,
          }),
        );
      }
    }
    if (userID != null) {
      const resourceDoc = yield getFirebase()
        .firestore()
        .collection('user_resources')
        .doc(userID)
        .collection('remind_me')
        .doc(id);

      yield resourceDoc.set({
        name: name || title,
        type: _get(contentType, 'sys.id'),
        subType: type || null,
        slug,
        resourceId: id,
        answer,
        shouldSend: answer === 'yes',
        timestamp: Date.now(),
      });
      yield put(
        saveRemindMeLaterResourceResult({
          added: true,
          error: false,
          fromAuth: payload.fromAuth,
        }),
      );
      yield put(
        getRemindMeLaterResourceResult({
          added: true,
          showOverlay: payload.showOverlay,
        }),
      );
    }
  } catch (error) {
    yield put(
      saveRemindMeLaterResourceResult({
        added: false,
        error: true,
      }),
    );
  }
}

function* getPageIntrosSaga() {
  try {
    const localeFilters = yield call(getContentfulLocaleFilter);

    const clientShortName = getLocalData('brand');
    const hasClientShortName =
      !_isEmpty(clientShortName) && clientShortName !== 'none';
    let clientDetails;
    if (hasClientShortName) {
      clientDetails = yield select(makeSelectClientDetails());
      if (_isEmpty(clientDetails)) {
        yield take(GET_CLIENT_DETAILS_SUCCESS);
        clientDetails = yield select(makeSelectClientDetails());
      }
    }

    const excludeClientFilter = {};
    if (!_isEmpty(clientDetails)) {
      excludeClientFilter['fields.excludeClient.sys.id[ne]'] =
        clientDetails.sys.id;
    }

    const response = yield call(() =>
      client.getEntries({
        content_type: 'intros',
        select:
          'fields.referencePages,fields.client,fields.excludeClient,fields.commonPages,fields.description,fields.url,fields.linkName,fields.style,fields.image,fields.multipleImages,fields.tags',
        'fields.reviewStatus': 'Accepted',
        ...excludeClientFilter,
        limit: 1000,
        ...localeFilters,
      }),
    );
    yield put(getPageIntrosResult(response.items));
  } catch (error) {
    throw new Error(error);
  }
}

function* getReferralsSaga() {
  try {
    const localeFilter = yield call(getContentfulLocaleFilter);
    const { data } = yield call(() =>
      GqlClient.query({
        query: GetReferrals,
        variables: {
          ...localeFilter,
          where: {
            style_not: 'Home Hero',
            reviewStatus: 'Accepted',
          },
        },
      }),
    );
    const items = data
      ? [..._get(data, 'referralCollection.items', [])].sort(
          (a, b) => _get(a, 'context.order', 0) - _get(b, 'context.order', 0),
        )
      : [];
    yield put(getReferralsResult(items));
  } catch (error) {
    throw new Error(error);
  }
}

function* getHomeReferralsSaga() {
  try {
    const localeFilter = yield call(getContentfulLocaleFilter);

    const { data } = yield call(() =>
      GqlClient.query({
        query: GetReferrals,
        variables: {
          ...localeFilter,
          where: {
            style: 'Home Hero',
            reviewStatus: 'Accepted',
          },
        },
      }),
    );

    const items = data
      ? [..._get(data, 'referralCollection.items', [])].sort(
          (a, b) => _get(a, 'context.order', 0) - _get(b, 'context.order', 0),
        )
      : [];
    yield put(getHomeReferralsResult(items));
  } catch (error) {
    throw new Error(error);
  }
}

function* getLocalesSaga() {
  try {
    const space = yield client.getSpace();
    const locales = _get(space, 'locales');
    yield put(setLocales(locales));
  } catch (error) {
    throw new Error(error);
  }
}

function* getAICCUserSaga({ payload }) {
  try {
    const id = _get(payload, 'user_id');
    if (!_isEmpty(id)) {
      const docRef = getFirebase()
        .firestore()
        .collection('users_aicc')
        .doc(id);
      const doc = yield docRef.get();

      // if (doc.exists) {
      //   yield put(setAICCUser(doc.data()));
      // } else {
      //   docRef.set(payload);

      //   yield put(setAICCUser(payload));
      // }
      if (!doc.exists) {
        docRef.set(payload);
      }
      yield put(setAICCUser(payload));
    }
  } catch (error) {
    throw new Error(error);
  }
}

function* getContentBlocksSaga() {
  try {
    const localeFilters = yield call(getContentfulLocaleFilter);
    const clientShortName = getLocalData('brand');
    const hasClientShortName =
      !_isEmpty(clientShortName) && clientShortName !== 'none';

    let clientDetails;
    if (hasClientShortName) {
      clientDetails = yield select(makeSelectClientDetails());
      if (_isEmpty(clientDetails)) {
        yield take(GET_CLIENT_DETAILS_SUCCESS);
        clientDetails = yield select(makeSelectClientDetails());
      }
    }

    const response = yield call(() =>
      client.getEntries({
        content_type: 'contentBlock',
        select:
          'sys,fields.type,fields.internalName,fields.commonPages,fields.dismissOptions,fields.pagePosition,fields.visibilityDefault,fields.header,fields.bodyContent,fields.backgroundColor,fields.sortOrder,fields.startDatetime,fields.endDatetime,fields.configurations,fields.associatedPages,fields.visibilityExceptions,fields.reference',
        'fields.reviewStatus': 'Accepted',
        order: '-sys.updatedAt',
        limit: 200,
        ...localeFilters,
      }),
    );

    const items = _get(response, 'items', []);
    const filteredItems = items
      .map(item => ({
        sys: item.sys,
        ...item.fields,
      }))
      .sort(
        (a, b) =>
          _get(a, 'sortOrder', Number.MAX_SAFE_INTEGER) -
          _get(b, 'sortOrder', Number.MAX_SAFE_INTEGER),
      )
      .filter(item => {
        if (item.startDatetime && item.endDatetime)
          return (
            Date.now() > new Date(`${item.startDatetime}T00:00:00`).getTime() &&
            Date.now() < new Date(`${item.endDatetime}T23:59:59`).getTime()
          );
        if (item.startDatetime)
          return (
            Date.now() > new Date(`${item.startDatetime}T00:00:00`).getTime()
          );
        if (item.endDatetime)
          return (
            Date.now() < new Date(`${item.endDatetime}T23:59:59`).getTime()
          );
        return true;
      })
      .filter(item => {
        const visibilityExceptions = _get(item, 'visibilityExceptions', []).map(
          clientItem => clientItem.sys.id,
        );
        const hasClientDetails = !_isEmpty(clientDetails);
        const clientGroupId = _get(clientDetails, 'clientGroup.sys.id');
        const clientId = _get(clientDetails, 'sys.id');

        const isReferenceAccepted = isReferenceAssessmentAccepted({
          reference: _get(item, 'reference'),
          clientDetails,
        });

        // Return early if reference is not accepted
        if (!isReferenceAccepted) return false;

        const exceptionsIncludeClientGroup = visibilityExceptions.includes(
          clientGroupId,
        );
        const exceptionsIncludeClient = visibilityExceptions.includes(clientId);
        if (
          !item.visibilityDefault ||
          item.visibilityDefault === 'Show for All'
        ) {
          return (
            !hasClientDetails ||
            (!exceptionsIncludeClientGroup && !exceptionsIncludeClient)
          );
        }

        return (
          hasClientDetails &&
          (exceptionsIncludeClientGroup || exceptionsIncludeClient)
        );
      });
    yield put(setContentBlocks(filteredItems));
  } catch (error) {
    throw new Error(error);
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(GET_CLIENT_DETAILS, getClientDetailsSaga);
  yield takeEvery(SEND_MAIL, sendMailSaga);
  yield takeLatest(GET_SITE_COPY, getSiteCopySaga);
  yield takeLatest(GET_SITE_CONFIG, getSiteConfigSaga);
  yield takeLatest(GET_AUDIENCE_TAGS_RELATIONS, getAudienceTagsRelationsSaga);
  yield takeLatest(GET_PROGRAMS_BY_TOPIC, getProgramsByTopic);
  yield takeLatest(GET_THEMES, getThemesSaga);
  yield takeLatest(GET_RESOURCES_COUNT_BY_TOPIC, getResourcesByTopicSaga);
  yield takeLatest(GET_CLIENT_RESOURCES, getClientResourcesSaga);
  yield takeLeading(UPDATE_BRAND, updateBrandSaga);
  yield takeLatest(
    SAVE_REMIND_ME_LATER_RESOURCE,
    saveRemindMeLaterResourceSaga,
  );
  yield takeLatest(GET_REMIND_ME_LATER_RESOURCE, getRemindMeLaterResourceSaga);
  yield takeLatest(GET_REFERRALS, getReferralsSaga);
  yield takeLatest(GET_HOME_REFERRALS, getHomeReferralsSaga);
  yield takeLatest(GET_PAGE_INTROS, getPageIntrosSaga);
  yield takeLatest(GET_LOCALES, getLocalesSaga);
  yield takeLatest(GET_AICC_USER, getAICCUserSaga);
  yield takeLatest(GET_CONTENT_BLOCKS, getContentBlocksSaga);
}
