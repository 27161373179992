import { useRef, useEffect } from 'react';

const useZoomLevel = () => {
  const zoomRef = useRef(false);

  const checkZoomLevel = () => {
    const zoomLevel = window.devicePixelRatio;
    zoomRef.current = ((zoomLevel || 1) - 1) * 100;
  };

  useEffect(() => {
    checkZoomLevel();

    const handleResize = () => checkZoomLevel();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { zoom: zoomRef.current };
};

export default useZoomLevel;
