import React from 'react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import BigLogo from './images/big-logo.svg';

export const getTextWidth = (text, font) => {
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};

export const getLandingSlug = (clientDetails, profileFilters) => {
  const items = _get(clientDetails, 'landingLinksCollection.items', [])
    .filter(item => !_isEmpty(item))
    .filter(({ showMenu }) => showMenu === 'yes' || !showMenu);
  if (!items.length) return null;

  const shouldFilterByTags = !!_get(
    clientDetails,
    'metadata.variableLandings',
    undefined,
  );
  const insuranceTags = _get(profileFilters, 'insuranceTags') || [];

  if (shouldFilterByTags && insuranceTags.length) {
    const landingByTag = items.find(item =>
      item.tagsCollection.items.some(t =>
        insuranceTags.some(tag => tag.id === t.sys.id),
      ),
    );
    return landingByTag
      ? landingByTag.slug
      : items.sort((a, b) => a.menuOrder - b.menuOrder)[0].slug;
  }

  return items.sort((a, b) => a.menuOrder - b.menuOrder)[0].slug;
};
export const getClientUrl = clientDetails => _get(clientDetails, 'url');

export const shouldPathShowSubscription = pathname =>
  pathname.startsWith('/topics') ||
  pathname.startsWith('/assessments') ||
  pathname.startsWith('/blogs') ||
  pathname.startsWith('/apps') ||
  pathname.startsWith('/books') ||
  pathname.startsWith('/podcasts') ||
  pathname.startsWith('/videos') ||
  pathname.startsWith('/articles') ||
  pathname.startsWith('/people') ||
  pathname.startsWith('/organizations') ||
  pathname.startsWith('/insights');

export const getClientLogo = clientDetails =>
  _get(clientDetails, ['logoReverse', 'url']);

export const getCMClientLogo = clientDetails =>
  _get(clientDetails, ['crediblemindLogo', 'url']);

export const getLogos = (
  clientDetails,
  clientLanding,
  isAdminPage,
  isAuthenticationImage,
) => {
  const clientUrl = getClientUrl(clientDetails);

  if (isAdminPage) {
    return {
      logos: [BigLogo],
      logosUrl: ['/'],
      clientUrl,
      hasLogosSet: false,
    };
  }

  const logos = [];
  const logosUrl = [];
  const hasLogosSet = !_isEmpty(
    _get(clientDetails, 'logosCollection.items', []),
  );
  const hasLogosUrlSet = !_isEmpty(
    _get(clientDetails, 'metadata.logoUrls', []),
  );
  if (hasLogosSet) {
    logos.push(
      ..._get(clientDetails, 'logosCollection.items', []).map(logo => logo.url),
    );
  } else {
    const clientLogo = _get(clientDetails, 'logoReverse.url');
    const landingLogo = _get(clientLanding, 'client.crediblemindLogo.url');
    const clientCMLogo = _get(clientDetails, 'crediblemindLogo.url', '');
    const finalClientCMLogo = clientCMLogo || landingLogo || BigLogo;

    logos.push(finalClientCMLogo, clientLogo);
  }
  if (hasLogosUrlSet) {
    logosUrl.push(..._get(clientDetails, 'metadata.logoUrls', []));
  } else if (_get(clientDetails, 'metadata.separateLogoLayout')) {
    logosUrl.push(clientUrl || '/', '/');
  } else {
    logosUrl.push('/', clientUrl || '/');
  }

  const authenticationPagesLogosIndex = _get(
    clientDetails,
    'metadata.authenticationPagesLogosIndex',
    [],
  );
  const filteredLogos = logos.filter(
    (_, index) =>
      !isAuthenticationImage ||
      authenticationPagesLogosIndex.length === 0 ||
      authenticationPagesLogosIndex.includes(index),
  );
  const filteredLogosUrl = logosUrl.filter(
    (_, index) =>
      !isAuthenticationImage ||
      authenticationPagesLogosIndex.length === 0 ||
      authenticationPagesLogosIndex.includes(index),
  );

  return {
    logos: filteredLogos.filter(logo => !!logo),
    logosUrl: filteredLogosUrl,
    clientUrl,
    hasLogosSet,
  };
};

export const getLogoComponent = ({
  url,
  image,
  onClick,
  shouldRedirectOnClientUrl,
  className = '',
}) => {
  let LogoComponent;
  if (url && !url.startsWith('/')) {
    const anchorProps = {
      href: url,
      className,
    };
    if (onClick) {
      anchorProps.onClick = onClick;
    }
    if (!shouldRedirectOnClientUrl) {
      anchorProps.target = '_blank';
      anchorProps.rel = 'noopener';
    }
    LogoComponent = <a {...anchorProps}>{image}</a>;
  } else if (url) {
    LogoComponent = (
      <Link to={url} className={className}>
        {image}
      </Link>
    );
  }
  return LogoComponent;
};

export const getActiveMonthsNumber = profile =>
  _get(profile, 'activeMonths', []).length;
