import { useRef } from 'react';

const useModalFocusTrap = (onClose, handleFocusNext) => {
  const modalRef = useRef(null);
  const refCallback = element => {
    if (!element) {
      document.removeEventListener('keydown', handleKeyDown);
      return;
    }
    modalRef.current = element;

    document.addEventListener('keydown', handleKeyDown);
  };

  const handleKeyDown = event => {
    if (!modalRef.current) return;

    const focusableElements = modalRef.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );

    if (focusableElements.length === 0) return;

    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    if (!modalRef.current.contains(document.activeElement)) {
      focusableElements[0].focus();
      event.preventDefault();
      return;
    }

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        if (document.activeElement === firstElement) {
          event.preventDefault();
          lastElement.focus();
        }
      } else if (document.activeElement === lastElement) {
        event.preventDefault();
        firstElement.focus();
      }
    }

    if (event.key === 'Escape') {
      if (handleFocusNext) handleFocusNext();
      onClose();
    }
  };
  return { handleKeyDown, refCallback };
};

export default useModalFocusTrap;
