import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import useUserId from 'components/Hooks/useUserId';
import Mixpanel from 'utils/mixpanelService';
import { useDispatch, useSelector } from 'react-redux';
import useClientExcludedResourceType from 'components/Hooks/useClientExcludedResourceType';
import useExcludedArticleTypes from 'components/Hooks/useExcludedArticleTypes';
import { windowScrollTo } from 'utils/embedded';
import {
  findNextIncompleteResource,
  findNextResourceIndex,
  getInitialActiveIdx,
  getSeriesAnswers,
  showConfetti,
  trackSeriesCompleted,
  trackSeriesNextClick,
  trackSeriesResourceClicked,
  trackSeriesResourceCompleted,
  trackSeriesResourceCompletedClicked,
  trackSeriesResourceViewed,
} from '../utils';
import {
  markSeriesCompleted,
  saveLastViewedResource,
  saveSeriesCompletedResources,
  saveResourceProgress,
  saveSeriesCustomQuestion,
} from '../actions';
import { getSeriesTakenInSession } from '../selectors';
import { SERIES_SIDEBAR_TABS } from '../constants';
import { useSeriesProgress } from './useSeriesProgress';
import { useMobileAudioPlayer } from '../../MobileAudioPlayer/MobileAudioPlayerContext';

export const useSeries = (item, profile, takenSeriesProcessing) => {
  const userId = useUserId();
  const history = useHistory();
  const dispatch = useDispatch();
  const takenSeries = useSelector(getSeriesTakenInSession);
  const seriesAnswers = getSeriesAnswers(takenSeries, _get(item, 'sys.id'));
  const itemFields = _get(item, 'fields', {});
  const itemResources = _get(item, 'fields.resources', []);
  const isAudioOnlySeries = _get(itemFields, 'audioOnly');
  const format = isAudioOnlySeries ? 'audio' : 'mixed';
  const { isResourceTypeExcluded } = useClientExcludedResourceType();
  const { isArticleTypesExcluded } = useExcludedArticleTypes();

  const { playPodcast } = useMobileAudioPlayer();
  const resources = itemResources.filter(
    resource =>
      !isResourceTypeExcluded(_get(resource, 'sys.contentType.sys.id')) &&
      !_isEmpty(isArticleTypesExcluded(resource)),
  );

  const [activeResourceIdx, setActiveResourceIdx] = useState(null);
  const [completedResourcesIds, setCompletedResourcesIds] = useState([]);
  const [isSeriesComplete, setIsSeriesComplete] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [autoPlayNext, setAutoPlayNext] = useState(false);

  const isAuthenticated = profile.isLoaded && !profile.isEmpty;
  const collection = isAuthenticated ? 'user_resources' : 'anonymous_series';
  const trackingPath = 'series';
  const historyTrackingProps = _get(history.location, 'state.trackingProps');

  // useLayoutEffect is required to sync update the state
  // in order to fix the UI flickering
  useLayoutEffect(
    () => () => {
      setIsSeriesComplete(false);
      setActiveResourceIdx(null);
      setCompletedResourcesIds([]);
      setShowAlert(false);
      setActiveTab(null);
    },
    [item],
  );

  useEffect(() => {
    if (activeResourceIdx !== null && item) {
      const resource = resources[activeResourceIdx];
      trackSeriesResourceViewed({ item, resource, trackingPath });
    }
  }, [item, activeResourceIdx]);

  useEffect(() => {
    if (!item || takenSeriesProcessing) {
      return;
    }

    const taken = takenSeries[_get(item, 'sys.id')];
    const completedIds = _get(taken, 'completedResourcesIds');
    if (!completedIds) {
      setActiveTab(SERIES_SIDEBAR_TABS.INTRODUCTION);
      Mixpanel.track('Series - Loaded and Viewed', {
        slug: _get(item, 'fields.slug'),
        name: _get(item, 'fields.title'),
        ...historyTrackingProps,
      });
      return;
    }

    const isComplete = !!taken.completed;
    const idx = getInitialActiveIdx(resources, taken);
    setCompletedResourcesIds(completedIds);
    setActiveResourceIdx(idx);
    setActiveTab(
      isComplete ? SERIES_SIDEBAR_TABS.RESULTS : SERIES_SIDEBAR_TABS.RESOURCE,
    );
    setIsSeriesComplete(isComplete);

    if (isComplete) {
      Mixpanel.track('Completed Series - Viewed', {
        slug: _get(item, 'fields.slug'),
        name: _get(item, 'fields.title'),
        ...historyTrackingProps,
      });
    }
  }, [item, takenSeriesProcessing]);

  const updateResourceProgress = useCallback(
    (seriesId, resourceId, seconds) => {
      dispatch(
        saveResourceProgress({
          seriesId,
          userId,
          resourceId,
          collection,
          seconds,
        }),
      );
    },
    [item, collection, userId],
  );

  const completeResource = (resource, completed) => {
    updateCompletedResources(resource, completed);
    trackSeriesResourceCompleted({ item, resource, trackingPath });
  };

  const { onProgressUpdate } = useSeriesProgress({
    completedResourcesIds,
    resources,
    completeResource,
    updateResourceProgress,
  });

  const onSeriesComplete = () => {
    const resourcesIds = resources.map(r => r.sys.id);
    setActiveResourceIdx(null);
    setCompletedResourcesIds(resourcesIds);
    setIsSeriesComplete(true);
    setActiveTab(SERIES_SIDEBAR_TABS.RESULTS);
    setShowAlert(true);
    dispatch(
      markSeriesCompleted({
        userId,
        seriesId: item.sys.id,
        collection,
        completedResourcesIds: resourcesIds,
      }),
    );

    windowScrollTo(0, 0);

    showConfetti();
    trackSeriesCompleted({ item, trackingPath });
  };

  const updateCompletedResources = (resource, completed) => {
    const nextCompletedIds = completed
      ? [resource.sys.id, ...completedResourcesIds]
      : completedResourcesIds.filter(id => id !== resource.sys.id);

    if (completed && nextCompletedIds.length === 1) {
      Mixpanel.track('Started Series', {
        slug: _get(item, 'fields.slug'),
        name: _get(item, 'fields.title'),
      });
    }

    setCompletedResourcesIds(nextCompletedIds);
    setIsSeriesComplete(false);

    dispatch(
      saveSeriesCompletedResources({
        userId,
        resourceId: _get(resource, 'sys.id'),
        seriesId: _get(item, 'sys.id'),
        collection,
        completedResourcesIds: nextCompletedIds,
        completed,
      }),
    );
  };

  const handleCompleteChange = (resource, completed) => {
    updateCompletedResources(resource, completed);
    trackSeriesResourceCompletedClicked({
      item,
      resource,
      completed,
      trackingPath,
    });
  };

  const handleLastViewedResource = (resource, completed) => {
    dispatch(
      saveLastViewedResource({
        userId,
        seriesId: _get(item, 'sys.id'),
        resourceId: _get(resource, 'sys.id'),
        collection,
        completed,
      }),
    );
  };

  const handleNext = (idx, completed, nextItemIdx) => {
    const nextIdx =
      nextItemIdx ||
      findNextIncompleteResource(idx + 1, resources, completedResourcesIds);
    setActiveResourceIdx(nextIdx);
    handleLastViewedResource(resources[nextIdx], completed);

    windowScrollTo(0, 0);
    trackSeriesNextClick({
      item,
      resource: resources[idx],
      trackingPath,
      completed,
    });
  };

  const handleAutoPlay = () => {
    // This feature is currently for podcast Series only
    const nextItemIdx = findNextResourceIndex(
      activeResourceIdx + 1,
      resources,
      'podcast',
    );
    if (!isAudioOnlySeries || !nextItemIdx) return;

    const activeResource = resources[activeResourceIdx];
    const completed = completedResourcesIds.includes(
      _get(activeResource, 'sys.id'),
    );

    setAutoPlayNext(true);
    handleNext(activeResourceIdx, completed, nextItemIdx);
  };

  const onStartSeries = () => {
    const shouldTrack = !isSeriesComplete;
    if (shouldTrack) {
      const eventName = completedResourcesIds.length
        ? 'Series - Continue - Clicked'
        : 'Series - Get Started - Clicked';
      Mixpanel.track(eventName, {
        format,
        slug: _get(item, 'fields.slug'),
        name: _get(item, 'fields.title'),
        entryId: _get(item, 'sys.id'),
        path: 'series',
      });
    }

    const nextIdx = findNextIncompleteResource(
      0,
      resources,
      completedResourcesIds,
    );
    setActiveTab(SERIES_SIDEBAR_TABS.RESOURCE);
    setActiveResourceIdx(nextIdx === -1 ? 0 : nextIdx);
  };

  const onStartMobileAudioSeries = () => {
    const nextIdx = findNextIncompleteResource(
      0,
      resources,
      completedResourcesIds,
    );

    Mixpanel.track(
      `${
        completedResourcesIds?.length ? 'Series - Continue' : 'Start - Series'
      } - Clicked`,
      {
        format,
        slug: _get(item, 'fields.slug'),
        name: _get(item, 'fields.title'),
        entryId: _get(item, 'sys.id'),
        path: 'series',
      },
    );

    playPodcast({
      series: item,
      podcast: resources[nextIdx === -1 ? 0 : nextIdx],
    });
  };

  const onResourceClick = (idx, completed) => {
    setActiveTab(SERIES_SIDEBAR_TABS.RESOURCE);
    setActiveResourceIdx(idx);
    handleLastViewedResource(resources[idx], completed);

    trackSeriesResourceClicked({
      item,
      resource: resources[idx],
      trackingPath,
    });
  };

  const onTabChange = tab => {
    setActiveTab(tab);
    setActiveResourceIdx(null);
    windowScrollTo(0, 0);
  };

  const saveAnswer = question => {
    dispatch(
      saveSeriesCustomQuestion({
        userId,
        seriesId: item.sys.id,
        collection,
        question,
      }),
    );
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const resourcesProgress =
    item &&
    takenSeries &&
    _get(takenSeries, [_get(item, 'sys.id'), 'resourcesProgress']);

  return {
    onProgressUpdate,
    handleCompleteChange,
    completeResource,
    handleNext,
    handleAutoPlay,
    activeResourceIdx,
    completedResourcesIds,
    onResourceClick,
    onSeriesComplete,
    isSeriesComplete,
    resources,
    resourcesProgress,
    onTabChange,
    activeTab,
    onStartSeries,
    saveAnswer,
    seriesAnswers,
    showAlert,
    onAlertClose,
    autoPlayNext,
    setAutoPlayNext,
    onStartMobileAudioSeries,
  };
};
