import _get from 'lodash/get';
import GqlClient from 'utils/graphqlService';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import { getContentfulLocaleFilter } from 'containers/Main/utils';
import { filterItemsByClientAccess } from 'utils/clientSettings';
import GetOnboardingModalsGql from './services/getOnboardingModals.gql';
import { GET_ONBOARDING_MODALS } from './constants';
import { getOnboardingModalsFail, getOnboardingModalsSuccess } from './actions';
import { transformOnboardingModalItems } from './utils';

function* getOnboardingModalsSaga() {
  const localeFilters = yield call(getContentfulLocaleFilter);
  const clientDetails = yield select(state => state.main.clientDetails);

  const ITEMS_PER_PAGE = 250;

  let allItems = [];
  let currentPage = 0;
  let hasMore = true;

  try {
    while (hasMore) {
      const { data } = yield call(GqlClient.query, {
        query: GetOnboardingModalsGql,
        variables: {
          ...localeFilters,
          skip: currentPage * ITEMS_PER_PAGE,
          limit: ITEMS_PER_PAGE,
        },
      });

      const collection = _get(data, 'onboardingModalsCollection');
      const items = _get(collection, 'items') || [];

      // Exit early if there are no items
      if (currentPage === 0 && !_get(items, 'length')) {
        hasMore = false;
        break;
      }

      allItems = [...allItems, ...items];

      hasMore =
        _get(collection, 'total', 0) >
        _get(collection, 'skip', 0) + _get(collection, 'items.length', 0);

      currentPage += 1;
    }

    const items = allItems ? [...allItems] : [];

    const filteredItems = filterItemsByClientAccess({
      items,
      clientDetails,
    });
    const transformedItems = transformOnboardingModalItems(filteredItems);

    yield put(getOnboardingModalsSuccess(transformedItems));
  } catch (error) {
    yield put(getOnboardingModalsFail(error));
  }
}

export default function* customOnboardingDialogSaga() {
  yield takeLatest(GET_ONBOARDING_MODALS, getOnboardingModalsSaga);
}
