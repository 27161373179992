import { useEffect } from 'react';

export const useMediaSession = ({
  audioRef,
  title,
  author,
  imageSrc,
  handleMediaPlay,
  handleMediaPause,
  handleMediaGoBackward,
  handleMediaGoForward,
  handleMediaSeek,
}) => {
  useEffect(() => {
    if (audioRef.current && 'mediaSession' in navigator) {
      const metadata = new window.MediaMetadata({
        title: title || 'Audio Series',
        artist: author || 'Unknown Author',
        artwork: [
          {
            src: imageSrc,
            sizes: '96x96',
            type: 'image/jpeg',
          },
        ],
      });

      navigator.mediaSession.metadata = metadata;

      navigator.mediaSession.setActionHandler('play', handleMediaPlay);
      navigator.mediaSession.setActionHandler('pause', handleMediaPause);
      navigator.mediaSession.setActionHandler(
        'seekbackward',
        handleMediaGoBackward,
      );
      navigator.mediaSession.setActionHandler(
        'seekforward',
        handleMediaGoForward,
      );
      navigator.mediaSession.setActionHandler('seekto', handleMediaSeek);
    }
  }, [audioRef, imageSrc, title, author]);
};
