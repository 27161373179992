import { Box, makeStyles, Slider } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  thumb: {
    display: 'none',
  },
  track: {
    border: 'none',
    color: theme.palette.text.white,
    borderRadius: 100,
    height: 6,
  },
  rail: {
    backgroundColor: 'rgba(186,205,255,0.49)',
    borderRadius: 100,
    height: 6,
  },
  root: {
    height: 6,
  },
}));

export const PlayerSlider = ({ currentTime, duration, handleSeek }) => {
  const classes = useStyles();
  const sliderValue = duration ? currentTime : 0;

  return (
    <Box className={classes.container}>
      <Slider
        value={sliderValue}
        max={duration || 1}
        onChange={handleSeek}
        classes={{
          root: classes.root,
          thumb: classes.thumb,
          track: classes.track,
          rail: classes.rail,
        }}
      />
    </Box>
  );
};
