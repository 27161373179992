import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: props => props.isExpanded && 'center',
    flexDirection: props => props.isExpanded && 'column',
    gap: props => (props.isExpanded ? 24 : 12),
    height: props => props.isExpanded && '100%',
    justifyContent: props => props.isExpanded && 'center',
    transition: 'all 0.3s ease',
  },
  image: {
    width: props => (props.isExpanded ? 300 : 42),
    height: props => (props.isExpanded ? 300 : 42),
    marginTop: props => (props.isExpanded ? 30 : 0),
    borderRadius: props => (props.isExpanded ? 12 : 4),
  },
  info: {
    display: 'flex',
    flexDirection: 'column',

    width: props => (props.isExpanded ? '100%' : '60%'),
    transition: 'width 0.3s ease',
  },
  seriesTitle: {
    color: theme.palette.text.white,
    fontSize: props => (props.isExpanded ? 14 : 12),
    transition: 'font-size 0.3s ease',
  },
  title: {
    color: theme.palette.text.white,
    fontSize: props => (props.isExpanded ? 24 : '1.063rem'),
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: props => props.isExpanded && 8,
    transition: ' font-size 0.3s ease',
  },
}));

export const PodcastInfo = ({
  title,
  imageSrc,
  seriesTitle,
  toggleExpand,
  goToTheSeries,
  goToThePodcast,
  isExpanded,
}) => {
  const classes = useStyles({ isExpanded });
  return (
    <Box className={classes.container} onClick={!isExpanded && toggleExpand}>
      <img alt="" src={imageSrc} className={classes.image} />
      <Box className={classes.info}>
        <Typography
          variant="body2"
          align={isExpanded ? 'center' : 'left'}
          className={classes.seriesTitle}
          onClick={isExpanded && goToTheSeries}
        >
          {seriesTitle}
        </Typography>
        <Typography
          variant="subtitle1"
          align={isExpanded ? 'center' : 'left'}
          className={classes.title}
          onClick={isExpanded && goToThePodcast}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
