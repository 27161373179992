/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import useZoomLevel from 'components/Hooks/useZoomLevel';
import NavBarContext from '../../navBarContext';
import { Home, More, Topics, Assessments, Exclusive } from './images';

export const FRONT_FACING_MENU = [
  'home',
  'topics',
  'assessments',
  'client-exclusive',
];

const ICONS = {
  home: <Home />,
  topics: <Topics />,
  assessments: <Assessments />,
  'client-exclusive': <Exclusive />,
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  listsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  zoomed: {
    '& li': {
      scale: '0.55',
      padding: 0,
    },
  },
  name: {
    fontSize: '10px',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  },
  list: {
    flex: '1',
    width: '100%',
    height: '100%',
    padding: '16px 10px',
    fontFamily: 'MadaRegular',
    color: '#4B4B4B',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  selected: {
    color: '#01619B',
  },
  icon: {
    marginBottom: '8px',
  },
}));

const MobileBottomNavBar = ({ menus }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const {
    siteCopy,
    openDialog,
    setMenus,
    dialogMenuOpen,
    getLink,
  } = useContext(NavBarContext);

  const { zoom } = useZoomLevel();

  useEffect(() => {
    setMenus(menus);
  }, []);

  const handleOnClick = path => {
    history.push(path);
  };

  const pathCheck = path => {
    if (path === '/client-resources') return pathname.includes('resources');
    if (path === '/') return path === pathname;

    return pathname.includes(path);
  };

  const finalMobileMenus = _uniqBy(
    [{ slug: 'home', name: 'Home' }].concat(menus),
    'slug',
  );
  return (
    <nav aria-label="Main navigation" className={classes.root}>
      <ul
        id="mobile-bottom-nav"
        className={cn(classes.listsContainer, { [classes.zoomed]: zoom > 200 })}
      >
        {finalMobileMenus
          .filter(({ slug }) => FRONT_FACING_MENU.includes(slug))
          .map(({ name, slug, link }) => (
            <li
              key={slug}
              className={cn(classes.list, {
                [classes.selected]:
                  !dialogMenuOpen && pathCheck(link || getLink(slug)),
              })}
              onClick={() => handleOnClick(link || getLink(slug))}
            >
              <div className={classes.icon}>{ICONS[slug]}</div>
              <span className={classes.name}>{name}</span>
            </li>
          ))}
        <li
          className={cn(classes.list, {
            [classes.selected]: dialogMenuOpen,
          })}
          onClick={() => {
            openDialog(!dialogMenuOpen);
          }}
        >
          <div className={classes.icon}>
            <More />
          </div>
          <span className={classes.name}>
            {_get(siteCopy, ['pageCopy', 'submenu-labels', 'more'])}
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default MobileBottomNavBar;
