import React from 'react';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';

const MenuItemTooltip = ({
  popperState,
  anchorEl,
  classes,
  getHandleMouseEnter,
  handleMouseLeave,
  content,
}) => (
  <Popper
    open={popperState.open}
    anchorEl={anchorEl}
    className={classes.popover}
    placement={popperState.placement}
    onMouseEnter={getHandleMouseEnter({
      openSubmenu: false,
    })}
    onMouseLeave={handleMouseLeave}
    disablePortal
    modifiers={{
      flip: {
        enabled: false,
      },
      preventOverflow: {
        enabled: true,
        boundariesElement: 'window',
      },
    }}
    transition
  >
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={400}>
        <div className={classes.paper}>{content(popperState.slug)}</div>
      </Fade>
    )}
  </Popper>
);

export default MenuItemTooltip;
