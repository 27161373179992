import _get from 'lodash/get';

export const hasEmbeddedControls = () => _get(window, 'xprops.controls', false);

export const isEmbedded = () => _get(window, 'xprops.embed', false);

export const isBareEmbedded = () =>
  isEmbedded() && _get(window, 'xprops.type') === 'bare';

export const hideHeroLanding = () =>
  isEmbedded() &&
  hasEmbeddedControls() &&
  _get(window, 'xprops.controls.hideHeroLanding', false);

export const hidePageHeader = () =>
  isEmbedded() &&
  hasEmbeddedControls() &&
  _get(window, 'xprops.controls.hideHeader', false);

export const hidePageSidebar = () =>
  isEmbedded() &&
  hasEmbeddedControls() &&
  _get(window, 'xprops.controls.hideSidebar', false);

export const embeddedStyles = () => _get(window, 'xprops.styles', {});

export const isValidPadding = paddingString => {
  // This regex checks for patterns like "10px", "10%", "10em", "10rem", "10vw", "10vh", "auto", etc.
  const unitPattern =
    '(\\d*\\.?\\d+(px|em|rem|%|vh|vw|ch|ex|cm|mm|in|pt|pc|vmin|vmax)|auto)';

  // Build the full pattern.
  const pattern = new RegExp(`^${unitPattern}(\\s+${unitPattern}){0,3}$`);

  return pattern.test(paddingString);
};

export const hasDynamicIframeHeight = () =>
  isEmbedded() &&
  typeof _get(window, 'xprops.setIframeHeight', '') === 'function';

export const getEmbeddedContainerUrl = () => {
  if (!isEmbedded()) return null;
  return _get(window, 'xprops.parentUrl', null);
};

export const getSubBrand = () => {
  if (!isEmbedded()) return null;
  const subBrand = _get(
    window,
    'xprops.subBrand',
    _get(window, 'xprops.subbrand', null),
  );
  return subBrand;
};

export const getParentPosition = async () => {
  if (!isEmbedded()) return null;
  const hasOnModalOpenProps =
    typeof _get(window, 'xprops.onModalOpen', '') === 'function';
  const parentPosition = hasOnModalOpenProps
    ? await _get(window, 'xprops.onModalOpen', '')()
    : null;
  return parentPosition;
};

export const hasOnScrollToProp = () => {
  if (!isEmbedded()) return false;
  return typeof _get(window, 'xprops.onScrollTo', '') === 'function';
};

export const windowScrollTo = (x, y = null) => {
  if (hasOnScrollToProp()) {
    window.xprops.onScrollTo(x);
  } else if (y !== null) {
    window.scrollTo(0, y);
  } else {
    window.scrollTo({
      top: x,
      behavior: 'smooth',
    });
  }
};

export const isWebView = () =>
  _get(window, 'isWebView', false) ||
  _get(window, 'xprops.isWebView', false) ||
  isRNWebView();

// Check if running in React Native WebView
export const isRNWebView = () => {
  // Check for React Native specific objects
  const isReactNative =
    typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

  // Check for mobile WebView indicators
  const isMobileWebView = () => {
    if (typeof window === 'undefined' || !window.navigator) {
      return false;
    }

    const userAgent = navigator.userAgent.toLowerCase();
    return (
      userAgent.includes('react-native') ||
      (userAgent.includes('mobile') &&
        (userAgent.includes('wv') || // Android WebView
          (userAgent.includes('safari') && navigator.standalone !== undefined))) // iOS WebView
    );
  };

  // Check for WebView bridge
  const hasWebViewBridge =
    typeof window !== 'undefined' &&
    (window.webkit?.messageHandlers?.ReactNativeWebView !== undefined || // iOS
      window.ReactNativeWebView?.postMessage !== undefined); // Android

  return isReactNative || (isMobileWebView() && hasWebViewBridge);
};
