import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { getContentfulEntryUrl } from 'utils/contentfulService';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  btn: {
    height: 39,
    width: 39,
    border: '1px solid #E3E3E3',
    borderRadius: 5,
    marginBottom: 10,
  },
}));

const AdminBtn = ({
  id,
  href,
  tooltipProps,
  target = '_blank',
  tooltip = 'See this entry on Contentful',
  btnClassName,
  linkClassName,
  icon = <SettingsIcon color="primary" />,
  searchString = '',
  tooltipId = 'contentful',
}) => {
  const classes = useStyles();

  const pathname = href || getContentfulEntryUrl(id);
  const isExternalLink = /^https?:\/\//.test(pathname);

  const LinkComponent = isExternalLink
    ? ({ children, ...props }) => (
        <a href={pathname} target="_blank" rel="noopener noreferrer" {...props}>
          {children}
        </a>
      )
    : Link;

  const to = {
    pathname,
    search: searchString,
  };
  return (
    <>
      <ReactTooltip
        id={tooltipId}
        place="top"
        type="light"
        border
        {...tooltipProps}
      >
        {tooltip}
      </ReactTooltip>
      <LinkComponent
        className={linkClassName}
        to={to}
        target={target}
        tabIndex={-1}
        rel="noopener"
        style={{ display: 'inline-flex' }}
        data-tip
        data-for={tooltipId}
      >
        <ButtonBase
          disableRipple
          className={classNames(classes.btn, btnClassName)}
          aria-label="Admin Setting"
        >
          {icon}
        </ButtonBase>
      </LinkComponent>
    </>
  );
};

AdminBtn.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string,
  tooltip: PropTypes.string,
  icon: PropTypes.node,
  searchString: PropTypes.string,
  tooltipId: PropTypes.string,
};

export default AdminBtn;
