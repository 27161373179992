import { gql } from '@apollo/client';
import { ReferralClientFragment } from 'containers/Main/services/fragments';

export default gql`
  ${ReferralClientFragment}
  query OnboardingModals($locale: String!, $skip: Int!, $limit: Int!) {
    onboardingModalsCollection(
      limit: $limit
      locale: $locale
      skip: $skip
      where: { reviewStatus: "Accepted" }
    ) {
      total
      skip
      limit
      items {
        sys {
          id
        }
        title
        modalTitle
        placeholder
        subtitle
        slug
        type
        order
        required
        optionsLayout
        context
        trigger
        footer {
          json
        }
        clientIncludeCollection(limit: 20) {
          items {
            ...ReferralClientFields
            ...ReferralClientGroupFields
          }
        }
        clientExcludeCollection(limit: 20) {
          items {
            ...ReferralClientFields
            ...ReferralClientGroupFields
          }
        }
      }
    }
  }
`;
