import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
  getOnboardingModals,
  getOnboardingModalsSuccess,
  getOnboardingModalsFail,
} from './actions';

export const initialState = {
  onboardingModals: [],
  onboardingModalsLoading: false,
  onboardingModalsError: null,
};

const onboardingModalsReducer = handleActions(
  {
    [getOnboardingModals]: state =>
      update(state, {
        onboardingModalsLoading: { $set: true },
        onboardingModalsError: { $set: null },
      }),

    [getOnboardingModalsSuccess]: (state, { payload }) =>
      update(state, {
        onboardingModalsLoading: { $set: false },
        onboardingModals: { $set: payload },
      }),

    [getOnboardingModalsFail]: (state, { payload }) =>
      update(state, {
        onboardingModalsLoading: { $set: false },
        onboardingModalsError: { $set: payload },
      }),
  },
  initialState,
);

export default onboardingModalsReducer;
