import { useCallback } from 'react';
import _throttle from 'lodash/throttle';

const PROGRESS_THRESHOLD = 95;

export const useSeriesProgress = ({
  completedResourcesIds,
  resources,
  completeResource,
  updateResourceProgress,
  forceAutocomplete = false,
}) => {
  const throttledSaveProgress = useCallback(
    _throttle(updateResourceProgress, 10_000),
    [updateResourceProgress],
  );

  const onProgressUpdate = (series, resource, completed) => (
    seconds,
    progress,
  ) => {
    if (completed) {
      throttledSaveProgress.cancel();
      return;
    }

    if (progress >= PROGRESS_THRESHOLD) {
      throttledSaveProgress.cancel();
      const isLast = completedResourcesIds.length + 1 === resources.length;
      if (forceAutocomplete || !isLast) {
        completeResource(resource, true);
      }
      return;
    }

    if (seconds) {
      throttledSaveProgress(series.sys.id, resource.sys.id, seconds);
    }
  };

  return {
    onProgressUpdate,
  };
};
