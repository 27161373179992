import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { getCustomOnboardingLocalUtils, getCanDisplayModal } from '../utils';
import { makeSelectOnboardingModals } from '../selectors';

const pages = {
  redirect: 'redirectModal',
  reporting: 'reportingModal',
};

const useIsOnboardingEnabled = () => {
  const location = useLocation();
  const onboardingModals = useSelector(makeSelectOnboardingModals);

  const reportingConfig = _get(onboardingModals, pages.reporting);
  const redirectConfig = _get(onboardingModals, pages.redirect);

  const canDisplayReporting =
    !_isEmpty(reportingConfig) &&
    getCanDisplayModal({
      trigger: Array.isArray(reportingConfig)
        ? reportingConfig[0].trigger
        : _get(reportingConfig, 'trigger'),
      pathname: location.pathname,
    });

  const canDisplayRedirect =
    !_isEmpty(redirectConfig) &&
    getCanDisplayModal({
      trigger: _get(redirectConfig, 'trigger'),
      pathname: location.pathname,
    });

  const { get } = getCustomOnboardingLocalUtils();
  const isReportingViewed = get(pages.reporting);
  const isRedirectViewed = get(pages.redirect);

  const shouldDisplayRedirect = !isRedirectViewed && canDisplayRedirect;
  const shouldDisplayReporting = !isReportingViewed && canDisplayReporting;

  return {
    showRedirect: shouldDisplayRedirect,
    showReporting: shouldDisplayReporting,
  };
};

export default useIsOnboardingEnabled;
