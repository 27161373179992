import qs from 'qs';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _memoize from 'lodash/memoize';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import {
  getIsAutomatic,
  getKeyFromTitle,
  partitionClientAutomaticResources,
  partitionClientHandpickedResources,
  shouldFilterHandpickedResource,
} from 'containers/EditorList/utils';
import { getTypeUrl } from 'components/ResourceItemSelector/utils';
import {
  filterHandpickedResourceByCutoff,
  filterClientExclusiveResources,
} from 'containers/Resources/utils';
import { getImageFile } from 'utils/stringUtils';
import { BG_COLORS } from 'containers/TopicCenter/components/LearnMore/constants';
import _orderBy from 'lodash/orderBy';
import GoodEvidenceIcon from './images/good-evidence.svg';
import SomeEvidenceIcon from './images/some-evidence.svg';
import InsufficentIcon from './images/insufficient-evidence.svg';
import NoIcon from './images/no-evidence.svg';

const createURL = filters => `?${qs.stringify(filters)}`;
export const urlToSearchState = location => {
  const state = qs.parse(location.search.slice(1), { arrayLimit: 200 });
  return state;
};

export const searchStateToUrl = (location, filters) =>
  filters
    ? `${location.pathname}${createURL({
        ...qs.parse(location.search.slice(1)),
        ...filters,
      })}`
    : '';

export const evidenceSort = data => {
  const goodEvidence = [];
  const someEvidence = [];
  const insufficientEvidence = [];
  const noEvidence = [];

  const harm = [];
  data.forEach(element => {
    const evidence = _get(
      element,
      'data.0.fields.approachEvidence.0.fields.evidence',
    );
    switch (evidence) {
      case 'Good Evidence':
        goodEvidence.push(element);
        break;
      case 'Some Evidence':
        someEvidence.push(element);
        break;
      case 'Insufficient Evidence':
        insufficientEvidence.push(element);
        break;
      case 'No Evidence':
        noEvidence.push(element);
        break;
      default:
        harm.push(element);
        break;
    }
  });

  return [
    ...sortSource(goodEvidence),
    ...sortSource(someEvidence),
    ...sortSource(insufficientEvidence),
    ...sortSource(harm),
  ];
};

export const getFinalClientExclusive = (
  clientExclusive,
  topicThemes,
  topicId,
) => {
  if ((_isEmpty(topicThemes) && !topicId) || _isEmpty(clientExclusive))
    return [];
  const filteredClientExclusive = [];
  clientExclusive.forEach(el => {
    if (
      !_isEmpty(_get(el, 'fields.theme')) &&
      _get(el, 'fields.theme').some(r =>
        topicThemes.includes(_get(r, 'fields.title')),
      )
    ) {
      filteredClientExclusive.push(el);
    }
    if (
      topicId &&
      !_isEmpty(_get(el, 'fields.topics')) &&
      _get(el, 'fields.topics').some(s =>
        topicId.split(',').includes(_get(s, 'sys.id')),
      )
    ) {
      filteredClientExclusive.push(el);
    }
  });
  return _uniqBy(filteredClientExclusive, 'sys.id');
};

export const sortClientExcusives = (data, sortFields, orders) =>
  _orderBy(data, sortFields, orders);

export const getSubMenu = (data, parent) => {
  if (_isEmpty(data)) return [];
  return data.map(({ fields: { title, slug } }) => ({
    name: title,
    slug,
    show: true,
    parent,
  }));
};

export const getEvidenceMenu = (data, icon, iconColor) => {
  const finalMenu = [];
  data.forEach(({ fields, sys: { id } }) => {
    if (!_isEmpty(_get(fields, 'approach.fields.title'))) {
      const {
        approach: {
          fields: { title, slug, description },
        },
      } = fields;
      finalMenu.push({
        name: title,
        slug,
        show: true,
        parent: 'what-helps',
        description,
        icon,
        iconColor,
        image: getImageFile(fields.approach.fields),
        evidenceData: fields,
        id,
      });
    } else if (!_isEmpty(_get(fields, 'title'))) {
      const { title, slug, description } = fields;
      finalMenu.push({
        name: title,
        slug,
        show: true,
        parent: 'what-helps',
        description,
        icon,
        iconColor,
        image: getImageFile(fields),
        evidenceData: fields,
        id,
      });
    }
  });
  return finalMenu;
};

export const sortSource = _memoize(sources =>
  _sortBy(sources, source => source.title),
);

export const sortEvidence = _memoize(evidences =>
  _sortBy(
    evidences,
    evidence =>
      !_isEmpty(_get(evidence, 'fields.approach.fields.title')) &&
      evidence.fields.approach.fields.title,
  ),
);

const sortNoEvidence = _memoize(evidences =>
  _sortBy(evidences, evidence => evidence.fields.title),
);

export const getEvidenceBaseApproach = ({
  topics,
  evidence = [],
  types = [],
  excludedTopicSlugs = [],
}) => {
  const noEvidenceApproach = [];
  const goodEvidenceData = [];
  const someEvidenceData = [];
  const noEvidenceData = [];
  const insufficientEvidenceData = [];
  const typesSlug = types.map(type => _get(type, 'fields.slug'));
  const evidenceApproaches = evidence.map(item =>
    _get(item, 'fields.approach'),
  );
  // eslint-disable-next-line no-unused-expressions
  !_isEmpty(topics) &&
    topics.forEach(topic => {
      if (
        !_find(evidenceApproaches, { sys: { id: topic.sys.id } }) &&
        !typesSlug.includes(_get(topic, 'fields.slug', '')) &&
        !excludedTopicSlugs.includes(_get(topic, 'fields.slug', ''))
      ) {
        noEvidenceApproach.push(topic);
      }
    });

  if (!_isEmpty(evidence)) {
    const evidenceApproach = evidence;
    evidenceApproach.forEach(item => {
      const approachSlug = _get(item, 'fields.approach.fields.slug', '');
      if (
        typesSlug.includes(approachSlug) ||
        excludedTopicSlugs.includes(approachSlug) ||
        _get(item, 'fields.approach.fields.reviewStatus') !== 'Accepted'
      ) {
        return;
      }
      if (_get(item, 'fields.evidence') === 'Good Evidence') {
        goodEvidenceData.push(item);
      } else if (_get(item, 'fields.evidence') === 'Some Evidence') {
        someEvidenceData.push(item);
      } else if (_get(item, 'fields.evidence') === 'Insufficient Evidence') {
        insufficientEvidenceData.push(item);
      } else if (
        _get(item, 'fields.evidence') === 'No Evidence' ||
        _isNil(_get(item, 'fields.evidence'))
      ) {
        noEvidenceData.push(item);
      }
    });
  }

  const goodEvidence = getEvidenceMenu(
    sortEvidence(goodEvidenceData),
    GoodEvidenceIcon,
    BG_COLORS['Good Evidence'],
  );
  const someEvidence = getEvidenceMenu(
    sortEvidence(someEvidenceData),
    SomeEvidenceIcon,
    BG_COLORS['Some Evidence'],
  );
  const insufficientEvidence = getEvidenceMenu(
    sortEvidence(insufficientEvidenceData),
    InsufficentIcon,
    BG_COLORS['Insufficient Evidence'],
  );
  const noEvidence = getEvidenceMenu(
    [...sortNoEvidence(noEvidenceData), ...noEvidenceApproach],
    NoIcon,
    BG_COLORS['No Evidence'],
  );
  return {
    all: _uniqBy([...goodEvidence, ...someEvidence, ...noEvidence], 'slug'),
    goodEvidence,
    someEvidence,
    insufficientEvidence,
    noEvidence,
  };
};

export const defaultTypes = [
  'Assessments',
  'Apps',
  'Articles',
  'Books',
  'FAQ',
  'News',
  'Online Programs',
  'Organizations',
  'Podcasts',
  'Videos',
];

export const getClientDetailsExcludedTopics = clientDetails => {
  const excludedTopics =
    _get(clientDetails, 'excludeTopicCollection.items') || [];
  const excludedSlugs = excludedTopics.map(({ slug }) => slug);
  return excludedSlugs;
};

const sortMixedClientResources = ({
  resources,
  handpickedClientField,
  automaticClientField,
  handpickedClientShortNameField,
  automaticClientShortNameField,
  clientShortName,
}) => {
  const sortedResources = [...resources].sort((a, b) => {
    const isAHandpicked = !!_get(a, handpickedClientField);
    const isBHandpicked = !!_get(a, handpickedClientField);

    const isAClientExclusive = _get(
      a,
      isAHandpicked ? handpickedClientField : automaticClientField,
      [],
    )
      .map(
        resourceClient =>
          _get(
            resourceClient,
            isAHandpicked
              ? handpickedClientShortNameField
              : automaticClientShortNameField,
          ),
        [],
      )
      .includes(clientShortName);
    const isBClientExclusive = _get(
      b,
      isBHandpicked ? handpickedClientField : automaticClientField,
      [],
    )
      .map(
        resourceClient =>
          _get(
            resourceClient,
            isBHandpicked
              ? handpickedClientShortNameField
              : automaticClientShortNameField,
          ),
        [],
      )
      .includes(clientShortName);

    if (isAClientExclusive && !isBClientExclusive) return -1;
    if (!isAClientExclusive && isBClientExclusive) return 1;
    return 0;
  });

  return sortedResources;
};

export const getListsWithResources = ({
  lists,
  resources,
  query,
  currentFilters = {},
  profileFilters = {},
  clientShortName,
  clientGroupShortName,
  clientExcludedTopics = [],
  clientExcludedAssessments = [],
  clientExcludedResourceTypes = [],
  clientExcludedCost = [],
  averageRatingCutoff,
  userRatingCutoff,
  expertRatingCutoff,
  prioritizeClientResources = false,
  featuresConfig,
  shouldFilterShortArticles,
  clientDetails,
  filtersTranslationsSiteCopy,
}) => {
  const excludedTopicsSlugs = clientExcludedTopics.map(item => item.slug);
  const excludedAssessmentsSlugs = clientExcludedAssessments.map(
    item => item.slug,
  );
  const typesFilters = _get(currentFilters, 'types', []);
  const audiencesFilters = _get(currentFilters, 'audience', []).map(item =>
    getKeyFromTitle(filtersTranslationsSiteCopy, 'audience', item),
  );
  const tagsFilters = _get(currentFilters, 'tags', []).map(item =>
    getKeyFromTitle(filtersTranslationsSiteCopy, 'tags', item),
  );
  const lengthFilters = _get(currentFilters, 'length', []);
  const languageFilters = _get(currentFilters, 'language', []);

  const {
    excludedAudiences = [],
    excludedTags = [],
    insuranceTags = [],
    state,
  } = profileFilters;

  const dataWithResources = [];
  lists.forEach((item, index) => {
    if (
      filterHandpickedResourceByCutoff({
        resource: item,
        averageRatingCutoff,
        userRatingCutoff,
        expertRatingCutoff,
      })
    ) {
      const isAutomatic = getIsAutomatic(item);

      const handpicked = _get(item, 'fields.resources', []).filter(resource => {
        const isSeriesResource =
          _get(resource, 'sys.contentType.sys.id') === 'series';
        if (!isSeriesResource) return true;

        const isCourseContent =
          _get(resource, 'fields.courseContent') === 'Yes';
        if (isCourseContent) return false;

        return true;
      });

      const handpickedResources = handpicked.filter(resource =>
        shouldFilterHandpickedResource({
          resource,
          averageRatingCutoff,
          userRatingCutoff,
          expertRatingCutoff,
          excludedAssessmentsSlugs,
          excludedTopicsSlugs,
          clientExcludedCost,
          clientExcludedResourceTypes,
          typesFilters,
          languageFilters,
          audiencesFilters,
          excludedAudiences,
          tagsFilters,
          excludedTags,
          insuranceTags,
          lengthFilters,
          query,
          state,
          shouldFilterShortArticles,
          featuresConfig,
          clientDetails,
        }),
      );

      let listResources;
      let [
        clientHandpickedResources,
        noClientHandpickedResources,
      ] = partitionClientHandpickedResources({
        handpickedResources,
        clientShortName,
        clientGroupShortName,
        prioritizeClientResources,
      });
      if (isAutomatic) {
        if (!query || !_get(item, 'fields.context.searchTerm')) {
          const [
            clientAutomaticResources,
            noClientAutomaticResources,
          ] = partitionClientAutomaticResources({
            automaticResources: _get(resources, [index, 'hits'], []),
            clientShortName,
            clientGroupShortName,
            prioritizeClientResources,
          });
          listResources = [
            ...sortMixedClientResources({
              resources: clientHandpickedResources.concat(
                clientAutomaticResources,
              ),
              handpickedClientField: 'fields.client',
              automaticClientField: 'client',
              handpickedClientShortNameField: 'fields.shortName',
              automaticClientShortNameField: 'shortName',
              clientShortName,
            }),
            ...noClientHandpickedResources,
            ...noClientAutomaticResources,
          ];
        } else {
          listResources = [
            ...clientHandpickedResources,
            ...noClientHandpickedResources,
          ];
        }
      } else {
        if (!_isEmpty(lengthFilters)) {
          clientHandpickedResources = clientHandpickedResources.sort((a, b) => {
            const hasMinutesA =
              _get(a, 'sys.contentType.sys.id') === 'video' ||
              _get(a, 'sys.contentType.sys.id') === 'podcast';
            const hasMinutesB =
              _get(b, 'sys.contentType.sys.id') === 'video' ||
              _get(b, 'sys.contentType.sys.id') === 'podcast';

            if (hasMinutesA && !hasMinutesB) {
              return -1;
            }
            if (!hasMinutesA && hasMinutesB) {
              return 1;
            }
            return 0;
          });
          noClientHandpickedResources = noClientHandpickedResources.sort(
            (a, b) => {
              const hasMinutesA =
                _get(a, 'sys.contentType.sys.id') === 'video' ||
                _get(a, 'sys.contentType.sys.id') === 'podcast';
              const hasMinutesB =
                _get(b, 'sys.contentType.sys.id') === 'video' ||
                _get(b, 'sys.contentType.sys.id') === 'podcast';

              if (hasMinutesA && !hasMinutesB) {
                return -1;
              }
              if (!hasMinutesA && hasMinutesB) {
                return 1;
              }
              return 0;
            },
          );
        }
        listResources = [
          ...clientHandpickedResources,
          ...noClientHandpickedResources,
        ];
      }
      listResources = filterClientExclusiveResources({
        resources: listResources,
        clientShortName,
        clientGroupShortName,
      });

      dataWithResources.push({
        item,
        resources: listResources,
      });
    }
  });

  return dataWithResources;
};

export const addSelectedFiltersWhenNotAvailable = (
  currentFilters,
  filterOptions,
  type,
) => {
  currentFilters.forEach(filter => {
    const options = filterOptions.filter(
      item => _get(item, 'values', []).includes(filter) || item.default,
    );

    options
      .slice(0, options.length > 1 ? options.length - 1 : undefined)
      .forEach(option => {
        if (option.filters.map(el => el.label).includes(filter)) return;

        option.filters.push({
          label: filter,
          type,
          count: 0,
          rawCount: 0,
          isSelected: true,
        });
      });
  });
};

export const addSelectedFiltersWhenNotAvailableSummary = (
  currentFilters,
  filters,
  filtersLabels,
  type,
) => {
  currentFilters.forEach(filter => {
    if (!filtersLabels.includes(filter))
      filters.push({
        label: filter,
        type,
        count: 1,
        rawCount: 1,
        isSelected: true,
      });
  });
};

export const filterSelfReferencingResources = resources => {
  const { pathname } = window.location;
  const typeUrl = pathname.split('/')[1];
  const slug = pathname.split('/')[2];

  const finalList = resources.filter(
    item =>
      !getTypeUrl(
        _get(item, 'sys.contentType.sys.id', _get(item, 'expandedType')),
      ).includes(typeUrl) ||
      _get(item, 'fields.slug', _get(item, 'slug')) !== slug,
  );
  return finalList;
};

export const getTopicListResourcesAttributesToRetrieve = prefix =>
  _uniq([
    'slug',
    'isPodcastSeries',
    'underFiveMinutes',
    'minutes',
    'mappedCost',
    'podcastType',
    'listenNotesId',
    'cost',
    'url',
    'allTopics',
    'imageUrl',
    'image',
    'altImageUrl',
    'time',
    'blog',
    'clientExclude',
    'client',
    'calculatedUserRating',
    'calculatedExpertRating',
    'avgRating',
    'avgRating7525',
    'avgRating9010',
    'cutoffUserRating',
    'cutoffExpertRating',
    'cutoffAvgRating',
    'cutoffAvgRating7525',
    'cutoffAvgRating9010',
    'type',
    'expandedType',
    'reviewStatus',
    `audience`,
    `expandedAudience`,
    `tags`,
    `expandedTags`,
    `filterTopics`,
    `${prefix}name`,
    `${prefix}type`,
    `${prefix}expandedType`,
    `${prefix}title`,
    `${prefix}description`,
    `${prefix}article`,
    `${prefix}subtitle`,
    `${prefix}shortDescription`,
    `${prefix}costDescription`,
    `${prefix}duration`,
  ]);
