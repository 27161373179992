import { useLocation } from 'react-router-dom';
import _some from 'lodash/some';

const authenticationPaths = [
  '/login',
  '/logincm',
  '/signup',
  '/signupcm',
  '/signup/data',
  '/signup/email',
  '/signup/verify',
  '/forgot_password',
  '/2fa',
  '/new_password',
  '/auth',
  '/auth/signup',
  '/auth/login',
  '/auth/eligibility',
  {
    value: '/auth/sso',
    startsWith: true,
  },
];

export const isAuthPath = pathname =>
  _some(authenticationPaths, path => {
    if (typeof path === 'string') {
      return path === pathname;
    }
    if (typeof path === 'object') {
      if (path.startsWith === true) {
        return pathname.startsWith(path.value);
      }
    }
    return false;
  });

const useIsAuthPath = () => {
  const location = useLocation();
  return isAuthPath(location.pathname);
};

export default useIsAuthPath;
