import client from 'utils/contentfulService';
import _flatten from 'lodash/flatten';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _some from 'lodash/some';

const filterBasedOnTopicAndThemes = (items, topicsIds = [], themesIds = []) =>
  items.filter(item => {
    const hasTopics = !_isEmpty(_get(item, 'fields.topics') || []);
    const matchesTopics = _some(
      (_get(item, 'fields.topics') || []).map(topic => _get(topic, 'sys.id')),
      id => topicsIds.includes(id),
    );

    if (hasTopics) {
      return matchesTopics;
    }

    const matchesThemes = _some(
      (_get(item, 'fields.themes') || []).map(theme => _get(theme, 'sys.id')),
      id => themesIds.includes(id),
    );

    return matchesThemes;
  });

export const filterBasedOnRequiredTags = (
  items,
  insuranceTags,
  insuranceOptions,
  profileTags,
  requiredTagsOptions,
) => {
  const insuranceTagsOptionsIds = _flatten(
    insuranceOptions.map(option => option.tags),
  );
  const insuranceTagsIds = insuranceTags.map(tag => tag.id);
  const requiredTagsOptionsIds = _flatten(
    requiredTagsOptions.map(option => option.tags),
  );
  const requiredTagsIds = profileTags.map(tag => tag.id);
  const filteredItems = items.filter(item => {
    if (
      (!_isEmpty(insuranceTagsIds) || !_isEmpty(requiredTagsIds)) &&
      (!_isEmpty(insuranceTagsOptionsIds) || !_isEmpty(requiredTagsOptionsIds))
    ) {
      const tagsIds = (_get(item, 'fields.requiredTags') || [])
        .filter(
          tag =>
            insuranceTagsOptionsIds.includes(tag.sys.id) ||
            requiredTagsOptionsIds.includes(tag.sys.id),
        )
        .map(tag => _get(tag, 'sys.id'));
      return (
        _isEmpty(tagsIds) ||
        _some(
          tagsIds,
          id => insuranceTagsIds.includes(id) || requiredTagsIds.includes(id),
        )
      );
    }
    return true;
  });

  return filteredItems;
};

const getLinks = async (
  key,
  {
    clientId,
    clientGroupId,
    themesIds,
    topicsIds,
    assessmentResultsIds,
    profileTags,
    requiredTagsOptions,
    insuranceTags,
    insuranceOptions,
    type,
    localeFilters,
  },
) => {
  const isRecent = type === 'recent';
  try {
    const clientIds = [clientId];
    if (clientGroupId) clientIds.push(clientGroupId);

    const excludedTagsFilter = {};
    if (!_isEmpty(profileTags)) {
      excludedTagsFilter['fields.excludedTags.sys.id[nin]'] = profileTags
        .map(tag => tag.id)
        .join(',');
    }

    const ctfPayload = {
      content_type: 'link',
      'fields.client.sys.id[in]': clientIds.join(','),
      'fields.reviewStatus': 'Accepted',
      ...excludedTagsFilter,
      ...(isRecent ? { order: '-sys.createdAt' } : {}),
      ...localeFilters,
    };
    if (_isEmpty(assessmentResultsIds)) {
      const response = await client.getEntries(ctfPayload);
      const items = filterBasedOnRequiredTags(
        filterBasedOnTopicAndThemes(
          _get(response, 'items', []),
          topicsIds,
          themesIds,
        ),
        insuranceTags,
        insuranceOptions,
        profileTags,
        requiredTagsOptions,
      );
      return items;
    }

    const response = await Promise.all([
      client.getEntries({
        ...ctfPayload,
        'fields.assessmentResults.sys.id[in]': assessmentResultsIds.join(','),
      }),
      client.getEntries({
        ...ctfPayload,
        'fields.assessmentResults.sys.id[exists]': false,
      }),
    ]);
    const filteredItems = filterBasedOnRequiredTags(
      filterBasedOnTopicAndThemes(
        _get(response, '0.items', []).concat(..._get(response, '1.items', [])),
        topicsIds,
        themesIds,
      ),
      insuranceTags,
      insuranceOptions,
      profileTags,
      requiredTagsOptions,
    );

    return filteredItems;
  } catch (error) {
    throw new Error(error);
  }
};

export default getLinks;
