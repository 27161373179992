import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _get from 'lodash/get';
import { makeSelectProfile } from 'containers/Auth/selectors';
import { logout } from 'containers/Auth/actions';
import { isEmbedded } from 'utils/embedded';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { getLocalData, setLocalData } from 'utils/localDataStore';

/**
 * This hook is used to check if enough time has passed between last and current
 * sessions and force logout if it has. This hook is enabled by
 * "applyInactivityTimeoutOnSession" field and the timeout threshold is set by
 * "loggedInInactivityTimeoutInSec" field.
 */
const useSessionTimeout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const checkOnLoad = useRef(false);
  const profile = useSelector(makeSelectProfile());
  const clientDetails = useSelector(makeSelectClientDetails());

  const profileIsLoaded = profile.isLoaded;
  const isAdmin =
    ['admin', 'contentAdmin'].includes(profile.role) && !isEmbedded();

  const shouldApplyInactivityTimeout = _get(
    clientDetails,
    'metadata.applyInactivityTimeoutOnSession',
    0,
  );
  const clientInactivityTimeout = shouldApplyInactivityTimeout
    ? _get(clientDetails, 'metadata.loggedInInactivityTimeoutInSec', 0)
    : 0;

  const doLogout = () =>
    dispatch(logout({ force: true, action: 'session-inactivity-timed-out' }));

  useEffect(() => {
    if (
      !checkOnLoad.current &&
      profileIsLoaded &&
      clientInactivityTimeout > 0 &&
      !isAdmin
    ) {
      checkOnLoad.current = true;

      const lastSessionEndTimestamp = getLocalData('lastSessionEndTimestamp');
      const now = Date.now();
      const diff = now - Number(lastSessionEndTimestamp);
      const sessionDiffInSeconds = moment
        .duration(diff, 'milliseconds')
        .asSeconds();

      if (sessionDiffInSeconds >= clientInactivityTimeout) {
        doLogout();
        history.replace('/');
      }
    }

    const cleanupFn = () => {
      if (profileIsLoaded) {
        setLocalData('lastSessionEndTimestamp', Date.now());
      }
    };
    window.addEventListener('beforeunload', cleanupFn);
    return () => {
      window.removeEventListener('beforeunload', cleanupFn);
    };
  }, [profileIsLoaded]);
};

export default useSessionTimeout;
