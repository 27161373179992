import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

export const ARTICLE_TYPES = {
  NEWS: 'News',
  INFORMATION_LINK: 'Informational Link',
};

export const getClientArticleSettings = clientDetails => {
  const clientArticleSettings = _get(
    clientDetails,
    'metadata.articlesSettings',
    {},
  );

  const clientGroupArticlesSettings = _get(
    clientDetails,
    'clientGroup.metadata.articlesSettings',
    {},
  );
  const articlesSettings = {
    ...clientGroupArticlesSettings,
    ...clientArticleSettings,
  };

  return {
    alwaysShowInformationalLink: _get(
      articlesSettings,
      'alwaysShowInformationalLink',
      false,
    ),
    articlesMinimumWordCount: _get(
      articlesSettings,
      'articlesMinimumWordCount',
      null,
    ),
  };
};

export const hasClientAccess = ({
  item,
  clientDetails,
  paths = {
    includedClientsPath: 'clientIncludeCollection.items',
    excludedClientsPath: 'clientExcludeCollection.items',
    itemClientShortNamePath: 'shortName',
    itemClientGroupShortNamePath: 'shortName',
  },
}) => {
  if (_isEmpty(item) || _isEmpty(clientDetails)) return false;

  const {
    includedClientsPath,
    excludedClientsPath,
    itemClientShortNamePath,
    itemClientGroupShortNamePath,
  } = paths;

  const clientShortName = _get(clientDetails, 'shortName');
  const clientGroupShortName = _get(clientDetails, 'clientGroup.shortName');

  const itemIncludedClients = _get(item, includedClientsPath);

  if (!_isEmpty(itemIncludedClients)) {
    return itemIncludedClients.some(
      client =>
        _get(client, itemClientShortNamePath) === clientShortName ||
        _get(client, itemClientGroupShortNamePath) === clientGroupShortName,
    );
  }

  const itemExcludedClients = _get(item, excludedClientsPath);
  if (!_isEmpty(itemExcludedClients)) {
    return !itemExcludedClients.some(
      client =>
        _get(client, itemClientShortNamePath) === clientShortName ||
        _get(client, itemClientGroupShortNamePath) === clientGroupShortName,
    );
  }

  return true;
};

export const filterItemsByClientAccess = ({ items, clientDetails, paths }) => {
  if (!Array.isArray(items) || !clientDetails) return [];

  return items.filter(item => hasClientAccess({ item, clientDetails, paths }));
};
