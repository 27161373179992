/**
 *
 * AudioCard
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Config from 'utils/getEnvConfig';
import _isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'components/ResourceItemSmall/components/Image';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import { useQuery } from 'react-query';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LoadingIndicator from 'components/LoadingIndicator';
import axios from 'axios';
import ClassNames from 'classnames';
import Title from 'components/ResourceItem/Title';
import ResourceLabel from 'components/ResourceLabel';
import Player from './Player';

const useStyles = makeStyles(theme => ({
  subTitle: {
    ...theme.typography.pxSmall,
    display: '-webkit-box',
    margin: '0 auto',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 40 /* Fallback for non-webkit */,
    padding: '3px 0',
  },
  titleAndAuthorWrapper: {
    height: 80,
    padding: '0 15px',
    marginTop: 5,
  },
  contentWrapper: {
    zIndex: 1,
    marginTop: 11,
    marginBottom: 11,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  topTime: {
    top: '-25px',
  },
  time: {
    position: 'absolute',
    bottom: '-25px',
    zIndex: 2,
    left: '50%',
    ...theme.typography.pxSmall,
    color: '#01619B',
  },
}));
const { API_URL, API_KEY } = Config.LISTEN_NOTES;

export const fetchListenNotesData = async (key, item) => {
  const { listenNotesId = '', finalType = '' } = item;
  if (listenNotesId && finalType) {
    const endpoint = finalType === 'Episode' ? 'episodes' : 'podcasts';
    const apiUrl = `${API_URL}/${endpoint}/${listenNotesId}`;
    const response = await axios({
      method: 'get',
      url: apiUrl,
      headers: { 'X-ListenAPI-Key': API_KEY },
    });
    return response;
  }
  return [];
};

function AudioCard(props) {
  const {
    file,
    resourceType,
    name,
    description,
    labels,
    section,
    onLoad,
    isSmallCard,
    expanded,
    grayscale,
    trackingPath,
    shouldABTest,
    isVariantB,
    listTitle,
  } = props;
  const classes = useStyles();
  const { listenNotesId = '', type = '', podcastType = '' } = props.data;
  const finalType = podcastType || type;
  const isBestResourcePage = section === 'best-resources';
  const { data, isFetching } = useQuery(
    ['listenotes', { finalType, listenNotesId }],
    fetchListenNotesData,
    {
      retry: 1,
    },
  );

  useEffect(() => {
    if (onLoad) {
      const finalData = !data
        ? []
        : !_isNil(_get(data, 'data.episodes'))
        ? data.data.episodes[0]
        : _get(data, 'data');
      const subtitle = _get(finalData, 'podcast.title');
      const minutes = Math.round(
        ((_get(finalData, 'audio_length_sec', 0) / 60) * 100) / 100,
      );
      if (subtitle || minutes)
        onLoad({
          subtitle,
          minutes:
            minutes > 0 && !expanded && isEpisode ? minutes : podcastType,
        });
    }
  }, [data]);
  let content = <Image file={file} type={resourceType} grayscale={grayscale} />;
  if (isFetching && !data) return <LoadingIndicator card={isSmallCard} />;
  const finalData = !data
    ? []
    : !_isNil(_get(data, 'data.episodes'))
    ? data.data.episodes[0]
    : _get(data, 'data');
  if (!_isEmpty(finalData)) {
    const { thumbnail, audio } = finalData;
    content = (
      <Player
        thumbnail={thumbnail}
        audio={audio}
        data={props?.data}
        isSmallCard={isSmallCard}
        grayscale={grayscale}
        trackingPath={trackingPath}
        shouldABTest={shouldABTest}
        isVariantB={isVariantB}
        listTitle={listTitle}
      />
    );
  }
  const subtitle =
    finalType === 'Episode'
      ? _get(finalData, 'podcast.title', '')
      : description;
  const audioTime = Math.round(
    ((_get(finalData, 'audio_length_sec', 0) / 60) * 100) / 100,
  );
  const isEpisode = finalType === 'Episode';
  return (
    <>
      <Grid item xs={12} className={classes.contentWrapper}>
        {content}
        {!isSmallCard && !isBestResourcePage && (
          <ResourceLabel labels={labels} type={resourceType} />
        )}
      </Grid>
      {!isSmallCard && (
        <>
          {!audioTime > 0 && !expanded && isEpisode ? (
            <Typography
              className={ClassNames(classes.time, {
                [classes.topTime]: !isBestResourcePage,
              })}
              variant="overline"
              align="center"
            >
              {audioTime}
              {audioTime < 2 ? ` min` : ` mins`}
            </Typography>
          ) : (
            <Typography
              className={ClassNames(classes.time, {
                [classes.topTime]: !isBestResourcePage,
              })}
              variant="overline"
              align="center"
            >
              {podcastType}
            </Typography>
          )}
        </>
      )}
      {!isSmallCard && !isBestResourcePage && (
        <Grid item xs={12}>
          <Grid container className={classes.titleAndAuthorWrapper}>
            <Title title={name} type={resourceType} />
            <Typography color="textPrimary" className={classes.subTitle}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

AudioCard.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  resourceType: PropTypes.string,
  data: PropTypes.object,
  name: PropTypes.string,
  description: PropTypes.string,
  labels: PropTypes.array,
  section: PropTypes.string,
  isSmallCard: PropTypes.bool,
  onLoad: PropTypes.func,
  expanded: PropTypes.bool,
  grayscale: PropTypes.bool,
};

AudioCard.defaultProps = {
  isSmallCard: false,
  expanded: false,
};

export default AudioCard;
