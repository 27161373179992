import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import useClientExcludedResourceType from 'components/Hooks/useClientExcludedResourceType';
import useModalFocusTrap from 'components/Hooks/useModalFocusTrap';
import NavBarContext from '../../navBarContext';
import AssessmentsSection from './AssessmentsSection';
import CoursesSeriesSection from './CoursesSeriesSection';
import FavoritesSection from './FavoritesSection';
import UserInformation from './UserInformation';
import ArrowRight from './icons/ArrowRight';

const useStyles = makeStyles(theme => ({
  panel: {
    width: 361,
    position: 'fixed',
    height: '100vh',
    backgroundColor: props => props.theme.backgroundColor,
    right: 0,
    top: 0,
    zIndex: 1301,
    boxShadow: '-16px 7px 26px 0px #0000001A',
    overflow: 'auto',
  },
  hideButton: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
  },
  hideButtonLabel: {
    color: props => props.theme.linkColor,
    ...theme.typography.pBold,
    fontSize: '0.75rem',
    lineHeight: 1,
  },
  caret: {
    height: 12,
    marginLeft: 2,
    display: 'inline-flex',
    maxWidth: 13,
  },
  container: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
  },
}));

const AccountSidebar = ({
  open,
  onHide,
  theme,
  inProgressSeries,
  inProgressCourses,
  inProgressResourcesCount,
  takenAssessments,
  retakeAssessmentsCount,
  favoriteResources,
}) => {
  const classes = useStyles({ theme });
  const { siteCopy } = useContext(NavBarContext);
  const { clientExcludedResourceTypes } = useClientExcludedResourceType();

  const handleClickAway = e => {
    e.preventDefault();
    e.stopPropagation();

    onHide();
  };

  const icon = ArrowRight(theme.linkColor);

  const isSeriesExcluded = clientExcludedResourceTypes.includes('Series');

  const { refCallback } = useModalFocusTrap(onHide);

  return (
    <Slide in={open} timeout={400} direction="left" mountOnEnter unmountOnExit>
      <aside className={classes.panel}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box ref={refCallback}>
            <ButtonBase
              onClick={onHide}
              className={classes.hideButton}
              disableRipple
            >
              <Typography className={classes.hideButtonLabel}>
                {_get(
                  siteCopy,
                  ['pageCopy', 'profile-sidebar', 'hideLabel'],
                  'HIDE',
                )}
              </Typography>
              <div className={classes.caret}>{icon}</div>
            </ButtonBase>
            <Box className={classes.container}>
              <UserInformation theme={theme} />
              {!isSeriesExcluded && (
                <CoursesSeriesSection
                  onHide={onHide}
                  theme={theme}
                  inProgressSeries={inProgressSeries}
                  inProgressCourses={inProgressCourses}
                  inProgressResourcesCount={inProgressResourcesCount}
                />
              )}
              <AssessmentsSection
                onHide={onHide}
                theme={theme}
                takenAssessments={takenAssessments}
                count={retakeAssessmentsCount}
              />
              <FavoritesSection
                onHide={onHide}
                theme={theme}
                favoriteResources={favoriteResources}
              />
            </Box>
          </Box>
        </ClickAwayListener>
      </aside>
    </Slide>
  );
};

AccountSidebar.propTypes = {
  open: PropTypes.bool,
  onHide: PropTypes.func,
  theme: PropTypes.object,
  inProgressSeries: PropTypes.array,
  inProgressCourses: PropTypes.array,
  inProgressResourcesCount: PropTypes.number,
  takenAssessments: PropTypes.array,
  retakeAssessmentsCount: PropTypes.number,
  favoriteResources: PropTypes.array,
};

export default AccountSidebar;
