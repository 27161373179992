import { createAction } from 'redux-actions';
import {
  GET_ONBOARDING_MODALS,
  GET_ONBOARDING_MODALS_SUCCESS,
  GET_ONBOARDING_MODALS_FAIL,
} from './constants';

export const getOnboardingModals = createAction(GET_ONBOARDING_MODALS);
export const getOnboardingModalsSuccess = createAction(
  GET_ONBOARDING_MODALS_SUCCESS,
);
export const getOnboardingModalsFail = createAction(GET_ONBOARDING_MODALS_FAIL);
