import Config from 'utils/getEnvConfig';

const {
  EMBEDLY: { API_KEY },
} = Config;

export const getEmbedlyImageUrl = file => {
  if (!file) return null;
  if (
    file.startsWith('https://images.ctfassets.net/') ||
    file.startsWith('https://i-cdn.embed.ly/1/')
  )
    return file;
  return `https://i.embed.ly/1/display?url=${encodeURIComponent(
    file,
  )}&key=${API_KEY}&dnt=1`;
};
