import React, { useState } from 'react';
import ClassNames from 'classnames';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import DownArrowIcon from 'components/LandingMenuSelector/down-arrow.svg';
import useWindowSize from 'components/useWindowSize';
import Mixpanel from 'utils/mixpanelService';
import useLanguageSelector from 'components/Hooks/useLanguageSelector';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { useSelector } from 'react-redux';
import { isEmbedded } from 'utils/embedded';

const useStyles = makeStyles(theme => ({
  languageInfo: {
    display: 'flex',
    marginRight: 20,
    ...theme.typography.overline,
    color: '#6C6C6C',
    '& :hover': {
      background: 'transparent',
    },
  },
  isEmbed: {
    paddingLeft: 14,
    paddingRight: 0,
    borderLeft: '1px solid #E0E0E0',
    marginLeft: 14,
  },
  img: {
    height: 12,
    marginRight: 7,
  },
  popper: {
    marginTop: 15,
    zIndex: 1400,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
    },
  },
  paper: {
    border: '1px solid #E3E3E3',
    width: 111,
    boxShadow: '3px 2px 94px 0 rgba(0,0,0,0.09)',
    maxHeight: '60vh',
    overflowY: 'auto',
  },
  list: {
    padding: 0,
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&:after': {
      bottom: '100%',
      left: 0,
      border: 'solid transparent',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'rgba(255, 255, 255, 0)',
      borderBottomColor: '#FFFFFF',
      borderWidth: 7,
      marginLeft: '-14px',
      top: '-6px',
    },
    '&:before': {
      bottom: '100%',
      left: 0,
      border: 'solid transparent',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'rgba(227, 227, 227, 0)',
      borderBottomColor: '#E3E3E3',
      borderWidth: 8,
      marginLeft: '-15px',
      top: '-9px',
    },
  },
  item: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  text: {
    '& span': {
      ...theme.typography.overline,
      color: '#6C6C6C',
    },
  },
  textActive: {
    '& span': {
      color: theme.palette.primary.main,
      fontFamily: 'MadaBold',
    },
  },
  icon: {
    marginLeft: 10,
  },
}));

const LanguageSelector = ({ hasMultipleLanguage }) => {
  const classes = useStyles();
  const { width } = useWindowSize();
  const isMobile = width <= 600;

  const {
    currentLanguage,
    languageData,
    isSuccess,
    setCurrentLanguage,
  } = useLanguageSelector(hasMultipleLanguage);
  const clientDetails = useSelector(makeSelectClientDetails());
  const clientLanguages = _get(clientDetails, 'languageCollection.items', []);
  const isEmbed = isEmbedded();
  const useAppEmbedStyle =
    isEmbed || _get(clientDetails, 'metadata.useAppEmbedStyle', false);
  const [arrowRef, setArrowRef] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

  const handleClick = event => {
    Mixpanel.track('Language Dropdown - Clicked');
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleListItemClick = (_, shortcode) => {
    setAnchorEl(null);
    setSelectedLanguage(shortcode);
    setCurrentLanguage(shortcode);
    Mixpanel.track('Language Item - Clicked', {
      language: shortcode,
    });
  };

  if (!languageData) return null;

  const { menuName } = languageData;
  const url = _get(languageData, 'image.url');
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  return isSuccess ? (
    <>
      <ButtonBase
        className={ClassNames(classes.languageInfo, {
          [classes.isEmbed]: useAppEmbedStyle,
        })}
        aria-describedby={id}
        onClick={handleClick}
        disableRipple
      >
        <Box component="div" className="notranslate">
          {url && (
            <img
              src={url}
              alt={`${menuName} Language`}
              className={classes.img}
            />
          )}
          {menuName}
          <img src={DownArrowIcon} className={classes.icon} alt="" />
        </Box>
      </ButtonBase>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        disablePortal
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: !isMobile,
            element: arrowRef,
          },
        }}
        className={classes.popper}
      >
        {() => (
          <>
            {!isMobile && <span className={classes.arrow} ref={setArrowRef} />}
            <ClickAwayListener onClickAway={handleClose}>
              <Paper className={classes.paper}>
                <List className={classes.list}>
                  {clientLanguages.map(({ shortcode, title }) => (
                    <ListItem
                      component="li"
                      key={shortcode}
                      className={classes.item}
                      selected={selectedLanguage === 0}
                      onClick={event => handleListItemClick(event, shortcode)}
                    >
                      <ListItemText
                        primary={title}
                        className={ClassNames(
                          classes.text,
                          'notranslate',
                          selectedLanguage === title.toLowerCase()
                            ? classes.textActive
                            : undefined,
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </>
        )}
      </Popper>
    </>
  ) : null;
};

export default LanguageSelector;
