import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactGA4 from 'react-ga4';
import Config from 'utils/getEnvConfig';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import {
  makeSelectClientDetails,
  makeSelectClientDetailsFetching,
} from 'containers/Main/selectors';
import freshworksUtils from 'utils/freshworksUtils';
import { isBot, getSubdomain } from 'utils/stringUtils';
import { isLocalhost } from 'utils/networkUtils';

const getCorporateViewPreference = search => {
  const params = new URLSearchParams(search);

  const validParamNames = ['showcorp', 'showCorp'];

  const isCorpView = validParamNames.some(
    param => params.get(param)?.toLowerCase() === '1',
  );

  return isCorpView;
};

const usePageTracking = () => {
  const clientDetails = useSelector(makeSelectClientDetails());
  const clientDetailsFetching = useSelector(makeSelectClientDetailsFetching());
  const { pathname, search } = useLocation();
  const enableTrack = !isBot() && !isLocalhost;

  useEffect(() => {
    if (!clientDetailsFetching && clientDetails !== null) {
      let GA4_MEASUREMENT_ID;
      const showCorp = getCorporateViewPreference(search);
      const subdomain = getSubdomain();
      if (!_isEmpty(clientDetails) && !showCorp && subdomain !== null) {
        GA4_MEASUREMENT_ID = _get(
          clientDetails,
          'context.GA4_MEASUREMENT_ID',
          false,
        );
      }

      ReactGA4.initialize(GA4_MEASUREMENT_ID || Config.GA_MEASUREMENT_ID);
      ReactGA4.send({ hitType: 'pageview', page: pathname + search });
    }
  }, [clientDetailsFetching, clientDetails, search]);

  useEffect(() => {
    const hasClientDetailsLoaded =
      !_isNull(clientDetails) && !clientDetailsFetching;
    const isClient = !_isEmpty(clientDetails);
    const GTMID = _get(clientDetails, 'context.GTMID', false);

    if (hasClientDetailsLoaded && enableTrack) {
      if (isClient) {
        // console.log({ CLIENTS_GTM_ID: Config.CLIENTS_GTM_ID });
        // init all clients gtm
        TagManager.initialize({
          gtmId: Config.CLIENTS_GTM_ID,
        });
      }
      if (GTMID) {
        // console.log({ GTMID });
        // init client own gtm
        TagManager.initialize({
          gtmId: GTMID,
        });
      }
      if (!GTMID && !isClient) {
        // console.log({ GTM_ID: Config.CLIENTS_GTM_ID });
        // gtm for public/not branded sites
        TagManager.initialize({
          gtmId: Config.GTM_ID,
        });
      }
      freshworksUtils.hideLauncher();
    }
  }, [clientDetails, clientDetailsFetching, enableTrack]);
};

export default usePageTracking;
