import { useEffect, useRef } from 'react';
import _flatten from 'lodash/flatten';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { processing as setProcessing } from 'containers/Auth/actions';
import {
  makeSelectProcessing,
  makeSelectProfile,
} from 'containers/Auth/selectors';
import { getIsClientAdmin } from 'containers/Auth/utils';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { post } from 'utils/api';
import Config from 'utils/getEnvConfig';
import { useFirebase } from 'react-redux-firebase';
import { getSubdomain } from 'utils/stringUtils';

const {
  LAMBDA: {
    CUSTOM_AUTHENTICATION_HELPER: { DEFAULT, UAT, PROD },
  },
} = Config;

const UAT_SUBDOMAINS = [
  'bcbsil-uat',
  'bcbsok-uat',
  'bcbsmt-uat',
  'bcbsnm-uat',
  'bcbstx-uat',
];
const subdomain = getSubdomain();
const isUat = UAT_SUBDOMAINS.includes(subdomain);

const { PROVIDERS_URL, API_KEY } = (isUat ? UAT : PROD) || DEFAULT;

function useEligibilityProvider() {
  const latestMids = useRef();
  const clientDetails = useSelector(makeSelectClientDetails());
  const processing = useSelector(makeSelectProcessing());
  const profile = useSelector(makeSelectProfile());
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const eligibilityConfig = _get(
    clientDetails,
    'customAuthentication.eligibility',
    {},
  );
  const isEnabled = _get(eligibilityConfig, 'enabled', false);
  const isSideDoorUser =
    _get(profile, 'providerData.providerId') === 'password';
  let mids = '';
  if (isEnabled) {
    if (isSideDoorUser) {
      mids = _get(profile, 'custom.mid', []).join(',');
    } else {
      mids = _get(profile, 'samlData.MID');
    }
  }

  useEffect(() => {
    if (mids && mids !== latestMids.current) {
      latestMids.current = mids;

      const getEligibleProviders = async () => {
        try {
          dispatch(setProcessing(true));

          const responses = await Promise.all(
            mids.split(',').map(mid =>
              post(
                PROVIDERS_URL,
                { mid },
                {
                  headers: {
                    'x-api-key': API_KEY,
                  },
                },
              ),
            ),
          );

          const customEligibility = {};
          _flatten(responses.map(response => response.data)).forEach(
            provider => {
              Object.keys(provider).forEach(key => {
                if (Array.isArray(provider[key])) {
                  if (customEligibility[key]) {
                    customEligibility[key].push(...provider[key]);
                  } else {
                    customEligibility[key] = [...provider[key]];
                  }
                } else if (typeof provider[key] === 'string') {
                  customEligibility[key] = provider[key];
                }
              });
            },
          );

          if (
            !_isEqual(_get(profile, 'custom.eligibility'), customEligibility)
          ) {
            await firebase.updateProfile({
              custom: {
                eligibility: customEligibility,
              },
            });
          }
        } finally {
          dispatch(setProcessing(false));
        }
      };

      getEligibleProviders();
    }
  }, [dispatch, firebase, mids, profile]);

  useEffect(() => {
    const isClientAdmin = getIsClientAdmin(profile, clientDetails);
    if (
      isEnabled &&
      !location.pathname.includes('/auth') &&
      location.pathname !== '/signup/data' &&
      location.pathname !== '/signup/email'
    ) {
      if (
        profile.isLoaded &&
        !profile.isEmpty &&
        !processing &&
        _get(profile, 'role') === 'user' &&
        !isClientAdmin &&
        _get(profile, 'providerData.providerId') === 'password'
      ) {
        const vendors = _get(profile, 'custom.eligibility.vendorCode', []);
        if (vendors.length === 0) {
          history.push('/auth/eligibility');
        }
      }
    }
  }, [clientDetails, history, isEnabled, location, processing, profile]);
}

export default useEligibilityProvider;
